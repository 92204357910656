export const DATAPOINTS = [
    {
        id: 'yields',
        dataType: 'string',
        align: 'left',
        width: 100,
        sortable: false
    },
    {
        id: 'oneMonth',
        dataType: 'number',
        align: 'right',
        param: '1 Month',
        sortable: false
    },
    {
        id: 'threeMonth',
        dataType: 'number',
        align: 'right',
        param: '3 Month',
        sortable: false
    },
    {
        id: 'sixMonth',
        dataType: 'number',
        align: 'right',
        param: '6 Month',
        sortable: false
    },
    {
        id: 'oneYear',
        dataType: 'number',
        align: 'right',
        param: '1 Year',
        sortable: false
    },
    {
        id: 'twoYear',
        dataType: 'number',
        align: 'right',
        param: '2 Year',
        sortable: false
    },
    {
        id: 'threeYear',
        dataType: 'number',
        align: 'right',
        param: '3 Year',
        sortable: false
    },
    {
        id: 'fiveYear',
        dataType: 'number',
        align: 'right',
        param: '5 Year',
        sortable: false
    },
    {
        id: 'sevenYear',
        dataType: 'number',
        align: 'right',
        param: '7 Year',
        sortable: false
    },
    {
        id: 'tenYear',
        dataType: 'number',
        align: 'right',
        param: '10 Year',
        sortable: false
    },
    {
        id: 'twentyYear',
        dataType: 'number',
        align: 'right',
        param: '20 Year',
        sortable: false
    },
    {
        id: 'thirtyYear',
        dataType: 'number',
        align: 'right',
        param: '30 Year',
        sortable: false
    }
];

export const YIELDS = [
    'lastYield',
    'yield1DayAgo',
    'yield1WeekAgo',
    'yield1MonthAgo',
    'yield3MonthAgo',
    'yield6MonthAgo',
    'yield1YearAgo',
    'yield2YearAgo',
    'yield3YearAgo',
    'yield5YearAgo',
    'yield7YearAgo',
    'yield10YearAgo'
];

export const PERIODS = DATAPOINTS.filter(item => item.param).map(
    item => item.id
);
