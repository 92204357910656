import Mixin from './mixin';
import d3 from 'd3';
import { Classes } from './constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;

export default class Axes extends Mixin {
    constructor(selection, option) {
        super();
        this._svg = selection;
        this._el = selection.select(`.${Classes.AXES}`);
        this._scales = null;
        this._ticksManager = null;
        this._axes = new Map();
        this._width = 0;
        this._height = 0;
        this._yPadding = 0;
        this.option(option);
        this.watch('skin', () => {
            this.__setStyles();
        });
    }
    option(option) {
        this._option = utils.extend(
            {
                xaxis: {},
                yaxis: {},
                y1axis: {}
            },
            option
        );
        this._skin = option.skin;
    }
    update() {
        if (this.get('el')[0][0]) {
            this.get('el')
                .selectAll('*')
                .remove();
            this.__drawAxis();
            this.__adjustXTickLabels();
            this.__adjustYTickLabels('y1');
            this.__adjustYTickLabels('y2');
            this.__appendYLabels('y1');
            this.__appendYLabels('y2');
            this.__setStyles();
        }
    }
    destroy() {
        this.unwatchAll();
        this.get('el')
            .selectAll('*')
            .remove();
    }
    __setStyles() {
        const option = this.get('option');
        const styles = option.styles;
        const el = this.get('el');
        // set X ticks line and text styles
        if (option.xaxis.show) {
            el.selectAll(`.${Classes.AXIS}-x .tick text`).style(
                utils.extend({}, styles.default.text, styles.x.text)
            );
            el.selectAll(`.${Classes.TICK_ABBREVIATION}`).style(
                utils.extend({}, styles.default.text_abbr, styles.x.text_abbr)
            );
        }
        // set Y ticks line and text styles
        ['y1', 'y2'].forEach(yaxisName => {
            const yaxisOpt = option[`${yaxisName}axis`];
            if (yaxisOpt.show) {
                const axis = el.selectAll(`.${Classes.AXIS}-${yaxisName}`);
                axis.selectAll('.tick text').style(
                    utils.extend(
                        {},
                        styles.default.text,
                        styles.y.text,
                        styles[yaxisName].text
                    )
                );
                axis.selectAll('.tick line').style(
                    utils.extend(
                        {
                            'shape-rendering': 'crispedges'
                        },
                        styles.default.line,
                        styles.y.line,
                        styles[yaxisName].line
                    )
                );
                if (yaxisOpt.abbreviation) {
                    axis.selectAll(`.${Classes.TICK_ABBREVIATION}`).style(
                        utils.extend(
                            {},
                            styles.default.text_abbr,
                            styles.y.text_abbr,
                            styles[yaxisName].text_abbr
                        )
                    );
                }
            }
        });

        // set y label styles
        el.selectAll(`.${Classes.AXIS}-label text`).style(
            utils.extend({}, styles.default.text, styles.y.text)
        );
    }
    /**
     * draw the x y1 y2 axis
     */
    __drawAxis() {
        const option = this.get('option');
        const axes = this.get('axes');
        const scales = this.get('scales');
        const ticksManager = this.get('ticksManager');

        const top = this._top ? this._top : 0;

        if (option.xaxis.show && !axes.has('x')) {
            axes.set('x', this.__createAxis(option.xaxis, scales.scale('x')));
        }
        if (option.y1axis.show) {
            axes.set(
                'y1',
                this.__createAxis(option.y1axis, scales.scale('y1'))
            );
        }
        if (option.y2axis.show) {
            axes.set(
                'y2',
                this.__createAxis(option.y2axis, scales.scale('y2'))
            );
        }
        const yLabelWidth =
            option.y1axis.label || option.y2axis.label
                ? option.yaxis.labelWidth
                : 0;
        const ySpacing = option.yaxis.spacing;
        const axesCount = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        };
        let [count, translate] = [];
        Array.from(axes.keys()).forEach(key => {
            const ticks = ticksManager.ticks(key);
            const axis = axes.get(key);
            if (ticks) {
                axis.tickValues(ticks.get('values')).tickSize(
                    ticks.get('size')
                );
                if (key === 'x' && axis.tickFormat) {
                    axis.tickFormat(d => {
                        return ticks.get('labels')[d];
                    });
                } else {
                    axis.tickFormat(ticks.get('format'));
                }
            }
            const axisWidth = axis.tickSize() + yLabelWidth + ySpacing;
            const orient = axis.orient();
            count = axesCount[orient];
            switch (orient) {
                case 'bottom':
                    translate = `translate(0, ${this.get('height')})`;
                    break;
                case 'right':
                    translate = `translate(${this.get('width') +
                        count * axisWidth +
                        this.get('yPadding')}, ${top})`;
                    break;
                case 'top':
                    translate = 'translate(0, 0)';
                    break;
                case 'left':
                    translate = `translate(${-(count * axisWidth)}, ${top})`;
                    break;
                default:
                    break;
            }
            axesCount[orient]++;
            const axisSelector = this.get('el')
                .append('g')
                .classed({
                    [Classes.AXIS]: true,
                    [`${Classes.AXIS}-${key}`]: true,
                    [`${Classes.AXIS}-${orient}`]: true
                })
                .attr('transform', translate)
                .call(axis);

            axisSelector.select('path').style('display', 'none');
        });
    }
    __createAxis(opt, scale) {
        const axis = d3.svg
            .axis()
            .scale(scale)
            .orient(opt.orient);
        [
            'ticks',
            'tickSize',
            'innerTickSize',
            'outerTickSize',
            'tickPadding',
            'tickValues',
            'tickFormat'
        ].forEach(attr => {
            if (typeof opt[attr] !== 'undefined' && axis[attr]) {
                axis[attr](opt[attr]);
            }
        });
        return axis;
    }
    __getSelectionByIndex(selection, index) {
        if (!selection[0]) {
            return d3.select();
        }
        return d3.select(selection[0][index]);
    }
    __getLastSelection(selection) {
        if (!selection[0]) {
            return selection;
        }
        const last = selection.size() - 1;
        return d3.select(selection[0][last]);
    }
    __adjustYTickLabels(yaxisName) {
        const option = this.get('option');
        if (this.get('axes').get(yaxisName)) {
            const cls = `${Classes.AXIS}-${yaxisName}`;
            const yTexts = this.get('el')
                .selectAll(`.${cls} .tick text`)
                .attr('x', 0)
                .attr('y', 12)
                .style('text-anchor', 'end');

            const yTicks = this.get('el').selectAll(`.${cls} .tick`);
            let firstTranslate;
            yTicks[0].forEach((tick, i) => {
                const d3Tick = d3.select(tick);
                if (i === 0) {
                    d3Tick
                        .classed(`${Classes.TICK_FIRST}`, true)
                        .select('text')
                        .attr('y', -10);
                    firstTranslate = d3.transform(
                        d3.select(tick).attr('transform')
                    ).translate[1];
                } else if (i === yTicks[0].length - 1) {
                    d3Tick.classed(`${Classes.TICK_LAST}`, true);
                } else if (i === 1) {
                    const translateY = d3.transform(
                        d3.select(tick).attr('transform')
                    ).translate[1];
                    const doubleTextHeight = 36;
                    if (firstTranslate - translateY < doubleTextHeight) {
                        d3Tick.select('text').attr('y', -10);
                    }
                }
            });

            const yaxisOpt = option[`${yaxisName}axis`];
            const ticksManager = this.get('ticksManager');
            const ticks = ticksManager.ticks(yaxisName);
            if (ticks) {
                if (
                    yaxisOpt.tickAlign === 'right' &&
                    yaxisOpt.orient === 'right'
                ) {
                    yTexts
                        .attr('x', ticks.get('size'))
                        .style('text-anchor', 'end');
                } else if (
                    yaxisOpt.tickAlign === 'left' &&
                    yaxisOpt.orient === 'left'
                ) {
                    yTexts
                        .attr('x', -1 * ticks.get('size'))
                        .style('text-anchor', 'start');
                }
            }

            if (yaxisOpt.abbreviation) {
                this.__appendYAbbreviation(
                    this.__getLastSelection(
                        this.get('el').selectAll(`.${cls} .tick`)
                    ),
                    yaxisOpt.abbreviation,
                    yaxisOpt.tickAlign
                );
            }
        }
    }
    __appendYAbbreviation(tick, abbrev, align) {
        const tickText = tick.select('text');
        tick.append('text')
            .attr('x', tickText.attr('x'))
            .attr('y', tickText.attr('y') * 2 + 4)
            .attr('dy', tickText.attr('dy'))
            .classed(`${Classes.TICK_ABBREVIATION}`, true)
            .text(abbrev)
            .style('text-anchor', align === 'right' ? 'end' : 'start');
    }
    __adjustXTickLabels() {
        const xTicks = this.get('ticksManager').ticks('x');
        if (xTicks && this.get('axes').get('x')) {
            let xTicksSelection = this.get('el').selectAll(
                `.${Classes.AXIS}-x .tick`
            );

            //adjust the tick text-align for tick label, if the first tick label is not start by 0,
            //set the text-align middle
            const xAxesRange = this.get('axes')
                .get('x')
                .scale()
                .range();
            let xTickSubLabels = xTicks.get('subLabels');
            const firstTick = this.__getSelectionByIndex(xTicksSelection, 0);
            if (!firstTick[0][0]) {
                // no xaxis ticks
                return;
            }
            const firstTickX = d3.transform(firstTick.attr('transform'))
                .translate[0];
            const secondTick = this.__getSelectionByIndex(xTicksSelection, 1);
            if (secondTick[0][0]) {
                const secondTickX = d3.transform(secondTick.attr('transform'))
                    .translate[0];
                // remove the first tick lable when it's too close to the second one
                if (
                    secondTickX - firstTickX <
                    firstTick.node().getBoundingClientRect().width + 2
                ) {
                    firstTick.remove();
                    xTickSubLabels.shift();
                }
            }

            let labelTextAlign;
            if (firstTickX > xAxesRange[0]) {
                labelTextAlign = 'middle';
            } else {
                labelTextAlign = 'start';
            }

            xTicksSelection
                .selectAll('text')
                .style('text-anchor', labelTextAlign);

            // check last one is over the width, if so, remove it
            const lastTick = this.__getLastSelection(xTicksSelection);
            const lastTickX = d3.transform(lastTick.attr('transform'))
                .translate[0];
            let lastTickW = lastTick.node().getBoundingClientRect().width;
            if (labelTextAlign === 'middle') {
                lastTickW = lastTickW / 2;
            }
            //remove the last tick lable when it outside the x axes
            if (lastTickW + lastTickX > xAxesRange[1]) {
                lastTick.remove();
                xTickSubLabels.pop();
            }

            xTicksSelection = this.get('el').selectAll(
                `.${Classes.AXIS}-x .tick`
            );
            if (xTicks.get('uniqueSubLabel') && xTickSubLabels) {
                let temp,
                    count = 0;
                xTickSubLabels = xTickSubLabels.map(v => {
                    if (v && v !== temp) {
                        count++;
                        temp = v;
                        return v;
                    }
                    return '';
                });
                if (count === 1 && xTicks.get('minSublabelCount') === 0) {
                    // if only one subLabels, just remove it
                    xTickSubLabels = xTickSubLabels.map(() => '');
                }
            }
            xTicksSelection[0].forEach((tick, idx) => {
                if (xTickSubLabels[idx]) {
                    d3.select(tick)
                        .append('text')
                        .text(xTickSubLabels[idx])
                        .attr(
                            'transform',
                            `translate(0, ${
                                this.get('axes')
                                    .get('x')
                                    .orient() === 'bottom'
                                    ? 28
                                    : -18
                            })`
                        )
                        .classed(`${Classes.TICK_ABBREVIATION}`, true)
                        .style('text-anchor', labelTextAlign);
                }
            });
        }
    }
    __appendYLabels(axisName) {
        const option = this.get('option');
        const yaxisOpt = option[`${axisName}axis`];
        if (yaxisOpt && yaxisOpt.show && yaxisOpt.label) {
            const label = yaxisOpt.label;
            const el = this.get('el');
            const orient = this.get('axes')
                .get(axisName)
                .orient();
            const isLeft = orient === 'left';
            const anchor = isLeft ? 'start' : 'end';
            const rotation = isLeft ? 270 : 90;
            const yLabelPadding = option.yaxis.labelPadding;
            const ticks = this.get('ticksManager').ticks(axisName);
            const tickSize = ticks ? ticks.get('size') : option.yaxis.tickSize;

            const translate = d3.transform(
                el.select(`.${Classes.AXIS}-${axisName}`).attr('transform')
            ).translate;
            const x = isLeft
                ? translate[0] - yLabelPadding - tickSize
                : translate[0] + yLabelPadding + tickSize;
            const y = this.get('height');
            const labelClass = `${Classes.AXIS}-label`;
            const svgText = el
                .append('g')
                .attr('class', `${labelClass} ${labelClass}-${axisName}`, true)
                .attr('transform', `translate(${x}, ${y})`)
                .append('text')
                .text(label)
                .attr('transform', `rotate(${rotation})`)
                .style('text-anchor', anchor);

            // dynamic abbreviate yAxisLabel if it is higher than container
            const labelText = svgText[0][0];
            const textHeight = Math.abs(labelText.clientWidth);
            if (textHeight > y) {
                const maxNum = Math.floor(y / (textHeight / label.length));
                labelText.innerHTML = label.substr(0, maxNum - 2) + '...';
            }
        }
    }
}
