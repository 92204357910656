(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("d3"), require("mwc-markets-core"));
	else if(typeof define === 'function' && define.amd)
		define(["d3", "mwc-markets-core"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsPlotter"] = factory(require("d3"), require("mwc-markets-core"));
	else
		root["mwcMarketsPlotter"] = factory(root["d3"], root["mwcMarketsCore"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 