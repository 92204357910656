import d3 from 'd3';
import { Classes } from '../constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;
export default function drawArea({
    selection,
    xScale,
    xValue,
    yValue,
    yScale,
    yScaleHeight,
    data,
    valid,
    className = '',
    styles
} = {}) {
    const width = xScale.range()[1];
    const height = yScaleHeight.range()[0];
    const area = d3.svg
        .area()
        .x(function(d) {
            return xScale(xValue(d));
        })
        .y0(height)
        .y1(function(d) {
            return yScale(yValue(d));
        })
        .defined(valid);

    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_AREA} ${className}`);
    const clipId = `${utils.guid()}#clip`;
    target
        .append('clipPath')
        .attr('id', clipId)
        .append('rect')
        .attr('width', width)
        .attr('height', height);

    target
        .append('path')
        .attr('clip-path', 'url(#' + clipId + ')')
        .attr('d', area(data))
        .style(styles);

    return target;
}
