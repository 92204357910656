import d3 from 'd3';
import { Classes } from '../constants';
import mwcMarketsCore from 'mwc-markets-core';
import { getHalfTickSizeAndXPoint } from '../common';
const { utils } = mwcMarketsCore;
export default function drawBar({
    selection,
    xScale,
    xValue,
    yScale,
    yValue,
    yScaleHeight,
    base,
    upDown = false,
    data,
    className = '',
    tickSize = 4,
    styles
} = {}) {
    const bar = data => {
        return data
            .map(d =>
                barPath({
                    tickSize,
                    data: d,
                    xScale,
                    xValue,
                    yScale,
                    yValue,
                    yScaleHeight,
                    base
                })
            )
            .join(' ');
    };

    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_BAR} ${className}`);

    if (!upDown) {
        target
            .append('path')
            .attr('d', bar(data))
            .style(styles.default);
    } else {
        const groupData = d3
            .nest()
            .key(function(d) {
                if (d.upDown > 0) {
                    return 'positive';
                } else if (d.upDown < 0) {
                    return 'negative';
                } else {
                    return 'neutral';
                }
            })
            .entries(data);

        groupData.forEach(g => {
            target
                .append('g')
                .attr('class', `${Classes.GRAPH_BAR}__${g.key}`)
                .append('path')
                .attr('d', bar(g.values))
                .style(styles[g.key]);
        });
    }
    return target;
}
export function barPath({
    tickSize = 4,
    data,
    xScale,
    xValue,
    yScale,
    yValue,
    yScaleHeight,
    base
}) {
    const { halfTickSize, xPoint, fullTickSize } = getHalfTickSizeAndXPoint(
        tickSize,
        xScale,
        xValue,
        data
    );
    const x = xPoint - halfTickSize;
    const baseY = utils.isNumeric(base)
        ? yScale(base)
        : yScaleHeight.range()[0];
    let height = yScale(yValue(data)) - baseY;
    if (isNaN(height)) {
        height = 0;
    }
    return [
        'M',
        x,
        baseY,
        'l',
        0,
        height,
        'l',
        fullTickSize,
        0,
        'l',
        0,
        -height
    ].join(' ');
}
