var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TREASURYUI", {
    ref: "UI",
    attrs: {
      showLoading: _vm.showLoading,
      skin: _vm.skin,
      dataModel: _vm.dataModel,
      errorCode: _vm.error.code,
      settings: _vm.settings,
      labels: _vm.mergeLabels(_vm.defaultConfig.labels),
      formatter: _vm.formatter
    },
    on: {
      "settings-changed": _vm.changeSettings,
      "settings-popover-hide": _vm.hideSettingsPopover
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }