import Mixin from './mixin';
import { Classes, Positions, MDS } from './constants';
import mwcMarketsCore from 'mwc-markets-core';
import d3 from 'd3';
import Tooltip from './tooltip';

const { utils } = mwcMarketsCore;
export default class Legend extends Mixin {
    /**
     * @param {D3 selection} container of the legend,  Required.
     */
    constructor(selection, option) {
        super();
        this._position = Positions.RIGHT;
        this._svg = selection;
        this._option = option;
        this._skin = option.skin;
        this._scales = null;
        this._el = selection
            .select(`.${Classes.LEGEND}`)
            .classed(`${Classes.LEGEND}__${this._position}`, true);
        this._data = null;
        this.update();
        this.watch('position', (val, oldVal) => {
            this._el
                .classed(`${Classes.LEGEND}__${oldVal}`, false)
                .classed(`${Classes.LEGEND}__${val}`, true);
        });
        this.__createTooltip(this._option.tooltip);
        this._width = this._option.width || 75;
        this.xScaleWidth = null; // save legend width when legend on the top
        this.watch('skin', () => {
            this.__setStyles();
        });
    }
    update() {
        this.__creatLegend();
        this.__setStyles();
    }
    __setStyles() {
        const option = this.get('option');
        const styles = option.styles;
        // set top and bottom lines styles
        const el = this.get('el');
        el.selectAll(`.${Classes.LEGEND}-line`).style(styles.default.line);

        // set item text styles
        el.selectAll(`.${Classes.LEGEND_ITEM} text`).style(styles.default.text);

        // set more button styles
        const moreButtonEl = el.select(`.${Classes.LEGEND_BUTTON}`);
        moreButtonEl
            .select('rect')
            .style(utils.extend({}, styles.default.line, styles.button.line));
        moreButtonEl
            .select('text')
            .style(utils.extend({}, styles.default.text, styles.button.text));
        moreButtonEl
            .select('polyline')
            .style(utils.extend({}, styles.default.line, styles.button.line));
        moreButtonEl
            .selectAll('circle')
            .style(utils.extend({}, styles.default.line, styles.button.line));
    }
    __creatLegend() {
        const dataManager = this.get('dataManager');
        this.xScaleWidth = 0;
        if (!dataManager) {
            return;
        }
        this._el.selectAll('*').remove();
        if (this._position !== Positions.TOP) {
            this.__createdTopBottomLines();
        }
        let gIdx = 0;
        this.data = [];
        ['y1', 'y2'].forEach(yaxisName => {
            const yDataSet = dataManager.series(yaxisName);
            if (yDataSet && utils.isArray(yDataSet) && yDataSet.length) {
                yDataSet.forEach(series => {
                    this.data.push(series);
                    this.__drawSeries(series, gIdx);
                    gIdx++;
                });
            }
        });
    }
    __createTooltip(option) {
        let toolTip = this.get('toolTip');
        if (!toolTip) {
            toolTip = new Tooltip(
                utils.extend({ skin: this._skin }, option.tooltip, {
                    fields: [],
                    caption: null
                }),
                this.get('el')
            );
            toolTip.set('skin', this.get('skin'));
        }
        this.set('toolTip', toolTip);
    }
    /**
     * draw short more button in the legend
     * @param {svg group element} svgGroup
     */
    __drawShortButton(svgGroup) {
        svgGroup.append('rect').attr({
            x: 0,
            y: 2,
            rx: 8,
            ry: 8,
            width: '16px',
            height: '16px',
            stroke: '#333',
            fill: '#fff'
        });
        svgGroup.append('circle').attr({
            cx: 8,
            cy: 10,
            r: 0.5,
            stroke: 'black'
        });

        svgGroup.append('circle').attr({
            cx: 4,
            cy: 10,
            r: 0.5,
            stroke: 'black'
        });
        svgGroup.append('circle').attr({
            cx: 12,
            cy: 10,
            r: 0.5,
            stroke: 'black'
        });
    }
    /**
     * draw more button in the legend
     * @param {svg group element} svgGroup
     * @param {svg button text} text
     */
    __drawButton(svgGroup, text) {
        svgGroup.append('rect').attr({
            x: 0,
            y: 0,
            rx: 4,
            ry: 4,
            width: '46px',
            height: '16px'
        });
        svgGroup
            .append('text')
            .attr({
                x: 23,
                y: 8
            })
            .text(text)
            .style('shape-rendering', 'crispedges');

        svgGroup
            .append('polyline')
            .attr({
                points: '38,4 42,8 38,12'
            })
            .style('shape-rendering', 'crispedges');
    }
    __drawSeries(series, index) {
        const option = this.get('option');
        const styles = option.styles;
        const colorIndex = (index % Object.keys(MDS['chart-color']).length) + 1;
        const color = series.get('color') || MDS['chart-color'][colorIndex];
        let yScale = 0,
            xScale = 0;
        let height = 15;
        if (this._position !== Positions.TOP) {
            // in firefox the with and heigh return by this._el.node().getBoundingClientRect() is 0
            //const clientRect = this._el.node().getBoundingClientRect();
            const scales = this.get('scales');
            const legendHeight = scales.scale('y1').range()[0];
            yScale = index * 22 + 15;
            height += yScale;
            if (height + 20 > legendHeight) {
                if (!this._el.select(`.${Classes.LEGEND_BUTTON}`).node()) {
                    const y1ScaleBtn = yScale - 5;
                    const legendEl = this._el
                        .append('g')
                        .classed(`${Classes.LEGEND_BUTTON}`, true)
                        .attr(
                            'transform',
                            `translate(${(this._width - 5) / 2 -
                                23},${y1ScaleBtn})`
                        )
                        .style('cursor', 'pointer')
                        .on('mouseover', () => {
                            this.__showTooltip(legendEl);
                        })
                        .on('click', () => {
                            d3.event.stopPropagation();
                        })
                        .on('touchstart', () => {
                            this.__toggleToolTip(legendEl);
                        })
                        .on('mouseout', () => {
                            this.get('toolTip').hide();
                        });
                    this.__drawButton(legendEl, 'more');
                }
                return;
            }
        } else {
            const legendWith = this._svg.node().getBoundingClientRect().width;
            const _legendPosition = this._el.node().getBoundingClientRect();
            if (index > 0) {
                const currentTextWidth = this.__getLegendTextWidth(series);
                if (
                    currentTextWidth + _legendPosition.width + 60 >
                    legendWith
                ) {
                    if (!this._el.select(`.${Classes.LEGEND_BUTTON}`).node()) {
                        const legendEl = this._el
                            .append('g')
                            .classed(`${Classes.LEGEND_BUTTON}`, true)
                            .classed(`${Classes.LEGEND_ITEM}`, true)
                            .attr(
                                'transform',
                                `translate(${_legendPosition.width + 10},-10)`
                            )
                            .on('mouseover', () => {
                                this.__showTooltip(legendEl);
                            })
                            .on('click', () => {
                                d3.event.stopPropagation();
                            })
                            .on('touchend', () => {
                                this.__toggleToolTip(legendEl);
                            })
                            .on('mouseout', () => {
                                this.get('toolTip').hide();
                            });
                        this.__drawShortButton(legendEl);
                    }
                    return;
                }
                xScale = _legendPosition.width + 10;
            }
        }
        const legendItem = this._el
            .append('g')
            .classed(`${Classes.LEGEND_ITEM}`, true);

        const lineWidth = 10; //line width 10
        legendItem
            .append('line')
            .attr('class', `${Classes.LEGEND_LINE}-${index}`, true)
            .attr('x1', xScale)
            .attr('x2', xScale + lineWidth)
            .attr('y1', yScale)
            .attr('y2', yScale)
            .attr('opacity', 1)
            .style(
                utils.extend(
                    {
                        stroke: color
                    },
                    styles.item.line
                )
            );

        const margin = 5;
        legendItem
            .append('text')
            .classed(`${Classes.LEGEND_TEXT}-${index}`, true)
            .attr('x', xScale + lineWidth + margin)
            .attr('y', yScale + 3)
            .text(series.get('displayName'));
    }
    __hideToopTip() {
        this.get('toolTip').hide();
    }
    __showTooltip(legendEl) {
        const toolTipData = [];
        this.data.forEach(item => {
            toolTipData.push({
                name: item.get('displayName')
            });
        });
        this.get('toolTip').show(toolTipData, { element: legendEl.node() });
    }
    __toggleToolTip(legendEl) {
        if (this.get('toolTip').get('visible')) {
            this.__hideToopTip();
        } else {
            this.__showTooltip(legendEl);
        }
    }
    __createdTopBottomLines() {
        const el = this.get('el');
        const scales = this.get('scales');
        const y1Scale = scales.scale('y1').range()[0];
        const width = this._width - 5;
        ['top', 'bottom'].forEach(key => {
            el.append('line')
                .attr(
                    'class',
                    `${Classes.LEGEND}-line ${Classes.LEGEND}-${key}`,
                    true
                )
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', key === 'bottom' ? y1Scale : 0)
                .attr('y2', key === 'bottom' ? y1Scale : 0)
                .attr('opacity', 1)
                .style('shape-rendering', 'crispedges');
        });
    }
    __getGreaterSVGTextWidth(svg, data) {
        let greatest = 0;
        data.forEach(item => {
            const width = this.__getSVGTextWidth(svg, item);
            greatest = width > greatest ? width : greatest;
        });
        return greatest;
    }
    __getLegendTextWidth(series) {
        const legendItem = this._el.append('g');
        legendItem.append('line').attr({
            x1: 0,
            x2: 10,
            y1: 0,
            y2: 0
        });
        legendItem
            .append('text')
            .attr('x', 15)
            .text(series._name);
        const width = legendItem.node().getBoundingClientRect().width;
        legendItem.remove();
        return width;
    }
    __getSVGTextWidth(svg, text, className) {
        const el = svg
            .append('text')
            .classed(className, true && className)
            .text(text);
        const width = el.node().getBoundingClientRect().width;
        el.remove();
        return width;
    }
    destroy() {
        this.unwatchAll();
        this.get('el')
            .selectAll('*')
            .remove();
        return this;
    }
}
