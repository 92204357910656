export default class Scales {
    constructor(scales) {
        this._scales = scales || {};
    }
    scale(scaleName, scale) {
        if (!scaleName) {
            return;
        }
        if (!scale) {
            return this._scales[scaleName];
        }
        this._scales[scaleName] = scale;
        return this;
    }
    domain(scaleName, domain) {
        if (!scaleName) {
            return;
        }

        if (!domain) {
            return this._scales[scaleName].domain();
        }
        this._scales[scaleName].domain(domain);
        return this;
    }
    range(scaleName, range) {
        if (!scaleName) {
            return;
        }
        if (!range) {
            return this._scales[scaleName].range();
        }
        this._scales[scaleName].range(range);
        return this;
    }
    remove(name) {
        this._scales[name] = null;
        delete this._scales[name];
        return this;
    }
}
