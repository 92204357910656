import d3 from 'd3';
import { Classes } from '../constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;
export default function drawSliderArea({
    selection,
    xScale,
    xValue,
    yValue,
    ySlider,
    dateRangeForSlider,
    yScale,
    yScaleHeight,
    data,
    valid,
    className = '',
    styles
} = {}) {
    const width = xScale.range()[1];
    const height = yScaleHeight.range()[0];
    const area = d3.svg
        .area()
        .x(function(d) {
            return xScale(xValue(d));
        })
        .y0(height)
        .y1(function(d) {
            return yScale(ySlider(d));
        })
        .defined(valid);
    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_AREA} ${className}`);
    const clipId = `${utils.guid()}#clip`;
    target
        .append('path')
        .attr('clip-path', 'url(#' + clipId + ')')
        .attr('d', area(data))
        .style(styles);

    var x = d3.time.scale().range([0, width]);
    var x2 = d3.time.scale().range([0, width]);

    x.domain(
        d3.extent(
            data.map(function(d) {
                return new Date(d.date);
            })
        )
    );
    x2.domain(x.domain());
    var brush = d3.svg
        .brush()
        .x(x2)
        .extent([
            new Date(dateRangeForSlider[0]),
            new Date(dateRangeForSlider[1])
        ])
        .on('brush', brushed);

    var context = selection
        .append('g')
        .attr('class', 'context')
        .attr('transform', 'translate(' + -5 + ',' + 10 + ')');
    context
        .append('g')
        .attr('class', 'range-slider-brush')
        .call(brush)
        .selectAll('rect')
        .attr('y', -9)
        .attr('height', height);

    function brushed() {
        x.domain(brush.empty() ? x2.domain() : brush.extent());
        launchEvent(brush.extent());
    }
    const launchEvent = data => {
        const event = new CustomEvent('sliderEvent', {
            detail: {
                date: data
            }
        });
        document.dispatchEvent(event);
    };
    return target;
}
