var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("markets-ui-grid", {
    class: _vm.classes,
    attrs: {
      skin: _vm.watchedSettings.skin,
      formatter: _vm.formatter,
      option: _vm.gridOption,
      columns: _vm.columnSet,
      rows: _vm.rowSet
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }