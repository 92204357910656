import { getSVGTextWidth } from '../common/';
import { Classes } from '../constants';
export default function drawTag({
    selection,
    options,
    xScale,
    xValue,
    yScale,
    yScaleHeight,
    data,
    yValue,
    valid,
    styles
} = {}) {
    const target = selection;
    const lastValidData = data[getMaxValidIndex(data, valid)];
    if (!lastValidData) {
        return;
    }
    let x = Math.floor(xScale(xValue(lastValidData)));
    const y = yScale(yValue(lastValidData));

    let text =
        typeof options.format === 'function'
            ? options.format(lastValidData.value)
            : lastValidData.value;
    if (typeof options.text === 'function') {
        text = options.text(text);
    }
    if (options.offset) {
        x += options.offset;
    }
    let textWidth = getSVGTextWidth(target, text, styles.text);
    if (options.showClose) {
        textWidth = textWidth + 20;
    }
    const textHeight = 17;
    let rectWidth = Math.max(options.width, textWidth + options.padding * 2);
    const rectHeight = options.height;
    const padding = (rectWidth - textWidth) / 2;
    const maxX = xScale.range()[1],
        minX = xScale.range()[0],
        minY = yScaleHeight.range()[1],
        maxY = yScaleHeight.range()[0];
    const arrowWidth = options.arrowWidth;

    let position = options.position;
    let tagCount = options.tagCount;
    if (!position) {
        if (options.yaxisOrient === 'right') {
            position = 'right-center';
        } else {
            if (x + rectWidth + arrowWidth < maxX) {
                position = 'right-bottom';
            } else {
                position = 'left-bottom';
            }
        }
    }

    position = getBestPosition({
        position,
        x,
        y,
        height: rectHeight,
        width: rectWidth,
        arrowWidth: arrowWidth,
        minY,
        minX,
        maxX,
        maxY,
        margin: options.margin,
        tagCount
    });

    const positionInfo = getTagPosition({
        x,
        y,
        width: rectWidth,
        height: rectHeight,
        arrowWidth,
        radius: options.radius,
        position,
        margin: options.margin
    });
    target
        .append('path')
        .attr('d', positionInfo.path)
        .style(styles.rect);

    if (options.showClose) {
        target
            .append('svg')
            .attr({
                focusable: 'false',
                xmlns: 'http://www.w3.org/2000/svg',
                viewBox: '0 0 15 15',
                width: 15,
                height: 15,
                x: positionInfo.position.x + padding,
                y: positionInfo.position.y + 5,
                class: Classes.CLOSE
            })
            .append('path')
            .attr({
                d: 'M3 3l9 9m0-9l-9 9',
                fill: '#fff',
                stroke: '#fff'
            });
    }
    let textX = positionInfo.position.x + padding;
    if (options.showClose) {
        textX += 18;
    }
    target
        .append('text')
        .text(text)
        .style(styles.text)
        .attr('x', textX)
        .attr('y', positionInfo.position.y + textHeight);

    return target;
}

function getMaxValidIndex(data, valid) {
    let maxValidIndex = -1;
    if (!valid) {
        valid = () => {
            return true;
        };
    }
    for (let i = data.length - 1; i >= 0; i--) {
        if (valid(data[i])) {
            maxValidIndex = i;
            break;
        }
    }
    return maxValidIndex;
}

function getBestPosition({
    x,
    y,
    height,
    width,
    arrowWidth,
    position,
    minY,
    minX,
    maxX,
    maxY,
    margin
}) {
    let [firstPosition, secondPosition] = position.split('-');

    switch (firstPosition) {
        case 'left':
        case 'right': {
            if (y + height > maxY) {
                position = `${firstPosition}-top`;
            } else if (y - height < minY) {
                position = `${firstPosition}-bottom`;
            }
            break;
        }
        case 'bottom':
        case 'top': {
            if (y + height + arrowWidth + margin > maxY) {
                firstPosition = 'top';
            } else if (y - height - arrowWidth - margin < minY) {
                firstPosition = 'bottom';
            }

            if (x - width < minX) {
                secondPosition = 'right';
            } else if (x + width > maxX) {
                secondPosition = 'left';
            }
            position = `${firstPosition}-${secondPosition}`;
            break;
        }
    }
    return position;
}

/* eslint max-statements: 0 */
function getTagPosition({
    x,
    y,
    width,
    height,
    radius,
    arrowWidth,
    position,
    margin
}) {
    const tag = {};
    switch (position) {
        case 'left-bottom': {
            x = x - margin;
            tag.path = `M${x},${y}h${-(
                width +
                arrowWidth -
                radius
            )} 0a${radius} ${radius} 0 0 0 -${radius} ${radius} v${height -
                2 *
                    radius} a${radius} ${radius} 0 0 0 ${radius} ${radius} h${width -
                2 * radius}a${radius} ${radius} 0 0 0 ${radius} ${-radius}v${(0,
            -(height - radius - arrowWidth))}l${arrowWidth},${-arrowWidth}`;
            tag.position = {
                x: x - arrowWidth - width,
                y: y
            };
            break;
        }
        case 'left-top': {
            x = x - margin;
            tag.path = `M${x},${y}h${-(
                width +
                arrowWidth -
                radius
            )} 0a${radius} ${radius} 0 0 1 -${radius} ${-radius} v${-(
                height -
                2 * radius
            )} a${radius} ${radius} 0 0 1 ${radius} ${-radius} h${width -
                2 * radius}a${radius} ${radius} 0 0 1 ${radius} ${radius}v${(0,
            height - radius - arrowWidth)}l${arrowWidth},${arrowWidth}`;
            tag.position = {
                x: x - arrowWidth - width,
                y: y - height
            };
            break;
        }
        case 'top-left': {
            y = y - margin;
            tag.path = `M${x},${y}v${-(
                height +
                arrowWidth -
                radius
            )}a${radius} ${radius} 0 0 0 ${-radius} ${-radius} h${-(
                width -
                2 * radius
            )} a${radius} ${radius} 0 0 0 ${-radius} ${radius} v${height -
                2 * radius}a${radius} ${radius} 0 0 0 ${radius} ${radius}h${(0,
            width - radius - arrowWidth)}l${arrowWidth},${arrowWidth}`;
            tag.position = {
                x: x - width,
                y: y - height - arrowWidth
            };
            break;
        }

        case 'top-right': {
            y = y - margin;
            tag.path = `M${x},${y}v${-(
                height +
                arrowWidth -
                radius
            )}a${radius} ${radius} 0 0 1 ${radius} ${-radius} h${width -
                radius} a${radius} ${radius} 0 0 1 ${radius} ${radius} v${height -
                radius}a${radius} ${radius} 0 0 1 ${-radius} ${radius}h-${width -
                arrowWidth}l${-arrowWidth},${arrowWidth}`;
            tag.position = {
                x: x,
                y: y - height - arrowWidth
            };
            break;
        }
        case 'right-center': {
            x = x + margin;
            tag.path = `M${x},${y}l${arrowWidth},${-arrowWidth}v${-(
                (height - 2 * arrowWidth) / 2 -
                radius
            )}a${radius} ${radius} 0 0 1 ${radius} ${-radius} h${width -
                2 *
                    radius} a${radius} ${radius} 0 0 1 ${radius} ${radius} v${height -
                2 *
                    radius} a${radius} ${radius} 0 0 1 ${-radius} ${radius} h${-(
                width -
                2 * radius
            )}a${radius} ${radius} 0 0 1 ${-radius} ${-radius}v${-(
                (height - 2 * arrowWidth) / 2 -
                radius
            )} l${arrowWidth},${arrowWidth}`;
            tag.position = {
                x: x + arrowWidth,
                y: y - height / 2
            };
            break;
        }

        case 'right-top': {
            x = x + margin;
            tag.path = `M${x},${y}h${width +
                arrowWidth -
                radius}a${radius} ${radius} 0 0 0 ${radius} ${-radius} v${-(
                height -
                2 * radius
            )} a${radius} ${radius} 0 0 0 ${-radius} ${-radius} h${-(
                width -
                2 * radius
            )}a${radius} ${radius} 0 0 0 ${-radius} ${radius}v${height -
                radius -
                arrowWidth}l${-arrowWidth},${arrowWidth}`;
            tag.position = {
                x: x + arrowWidth,
                y: y - height
            };
            break;
        }
        case 'right-bottom': {
            x = x + margin;
            tag.path = `M${x},${y}h${width +
                arrowWidth -
                radius}a${radius} ${radius} 0 0 1 ${radius} ${radius} v${height -
                2 *
                    radius} a${radius} ${radius} 0 0 1 ${-radius} ${radius} h${-(
                width -
                2 * radius
            )}a${radius} ${radius} 0 0 1 ${-radius} ${-radius}v-${height -
                radius -
                arrowWidth}l${-arrowWidth},${-arrowWidth}`;
            tag.position = {
                x: x + arrowWidth,
                y: y
            };
            break;
        }

        case 'bottom-left': {
            y = y + margin;
            tag.path = `M${x},${y}v${height +
                arrowWidth -
                radius}a${radius} ${radius} 0 0 1 ${-radius} ${radius} h-${width -
                2 *
                    radius} a${radius} ${radius} 0 0 1 ${-radius} ${-radius} v${-(
                height -
                2 * radius
            )}a${radius} ${radius} 0 0 1 ${radius} ${-radius}h${width -
                radius -
                arrowWidth}l${arrowWidth},${-arrowWidth}`;
            tag.position = {
                x: x - width,
                y: y + arrowWidth
            };
            break;
        }

        case 'bottom-right': {
            y = y + margin;
            tag.path = `M${x},${y}v${height +
                arrowWidth -
                radius}a${radius} ${radius} 0 0 0 ${radius} ${radius} h${width -
                radius} a${radius} ${radius} 0 0 0 ${radius} ${-radius} v${-(
                height - radius
            )}a${radius} ${radius} 0 0 0 ${-radius} ${-radius}h-${width -
                arrowWidth}l${-arrowWidth},${-arrowWidth}`;
            tag.position = {
                x: x,
                y: y + arrowWidth
            };
            break;
        }
    }

    return tag;
}
