import Mixin from './mixin';
import d3 from 'd3';
import DataManager from './data';
import Canvas from './canvas';
import { Classes, Positions, EVENTS } from './constants';
import getD3Locale from './locale';
import mwcMarketsCore from 'mwc-markets-core';
import styles from './styles';

const { utils } = mwcMarketsCore;

export default class Plotter extends Mixin {
    constructor(container, option) {
        super();
        this._dataManager = new DataManager();
        const d3Container = d3.select(container);
        this._el = d3Container.append('div');
        this.optionHandler(option);
        this.resizeCallback = utils.throttle(() => {
            this.resize();
        }, 10);
        utils.resizeObserver.observe(
            this.get('el').node(),
            this.resizeCallback
        );
    }
    optionHandler(option) {
        const skin = utils.getSkinSuffix(option.skin);
        this._styles = utils.extend(true, {}, styles);
        Object.keys(styles).forEach(key => {
            this._styles[key] = utils.extend(
                true,
                {},
                styles[key],
                option.styles
            );
        });
        this._option = utils.extend(
            true,
            {
                locale: 'en-US',
                /* breakpoint */
                screenMinHeight: 100,
                breakpoint: 500,
                margin: {
                    top: 5,
                    left: 0,
                    bottom: 0,
                    right: 0
                },
                // smallBreakpoint: 400,
                /* legend */
                legend: {
                    show: true,
                    width: 75,
                    height: 20
                },
                /* x axis */
                xaxis: {
                    show: true,
                    orient: 'bottom'
                },
                yaxis: {
                    paddingBig: 12,
                    paddingSmall: 6,
                    spacing: 5,
                    tickSize: 35, // need re-calculate with the text
                    tickMargin: 6,
                    extraTickMargin: 0,
                    labelWidth: 20,
                    labelPadding: 6,
                    labelSpacing: 5,
                    decimal: 2,
                    maxTickCount: 5
                },
                /* y1 axis */
                y1axis: {
                    show: true,
                    orient: 'left',
                    tickAlign: 'right',
                    scaleType: 'linear' // log
                },
                /* y2 axis */
                y2axis: {
                    show: false,
                    orient: 'right',
                    tickAlign: 'right',
                    scaleType: 'linear' // log
                },
                gridX: {
                    showLine: true,
                    showRect: false
                },
                gridY: {
                    showLine: false,
                    showRect: true
                },
                graph: {
                    bar: {
                        width: 'auto'
                    },
                    dot: {
                        radius: 4
                    },
                    ohlc: {
                        width: 'auto'
                    },
                    candlestick: {
                        width: 'auto'
                    },
                    volumeByPrice: {
                        volumeByPriceScale: 0.4
                    },
                    event: {
                        radius: 8,
                        margin: 5
                    },
                    // graph align left or center
                    align: 'left'
                },
                highlight: {
                    showTrackBall: false,
                    alignCrossAndTrackBall: false,
                    showXLine: false,
                    showYLine: true
                },
                tooltip: {
                    show: true,
                    width: 200,
                    fields: item => {
                        const value = utils.getFormatValue({
                            dataType: 'number',
                            value: item.value,
                            languageId: this._option.locale
                        });
                        return [value];
                    }
                },
                isStartPoint: true,
                styles: this._styles[skin],
                ariaLabel: '',
                highLightHoveredLine: false,
                mouseMoveOnValidValue: true, // trigger mousemove event only if hover on a valid data point
                tag: {
                    width: 48,
                    height: 24,
                    margin: 1,
                    padding: 4,
                    arrowWidth: 8,
                    radius: 2
                },
                zoom: {
                    enableZoom: false,
                    enablePan: false
                },
                drawings: {
                    tagFormat: null
                }
            },
            option
        );

        const skinClass =
            skin === 'dark-gray' ? Classes.SKIN_DARK : Classes.SKIN_WHITE;
        this._el.attr('class', `${Classes.NAMESPACE} ${skinClass}`);
        if (this._option.ariaLabel) {
            this._el
                .attr('role', 'img')
                .attr('aria-hidden', true)
                .attr('aria-label', this._option.ariaLabel);
        }
        this._d3Locale = getD3Locale(this._option.locale);
        ['open', 'high', 'low', 'close', 'value'].forEach(attr => {
            this._dataManager.parser(attr, this._option.yaxis[attr]);
        });
    }
    changeSkin(skin) {
        const option = this.get('option');
        if (option.skin !== skin) {
            const skinSuffix = utils.getSkinSuffix(skin);
            option.skin = skin;
            option.styles = this._styles[skinSuffix] || this._styles.default;
            if (skinSuffix === 'dark-gray') {
                this._el
                    .classed(Classes.SKIN_WHITE, false)
                    .classed(Classes.SKIN_DARK, true);
            } else {
                this._el
                    .classed(Classes.SKIN_WHITE, true)
                    .classed(Classes.SKIN_DARK, false);
            }
            const canvas = this.get('canvas');
            if (canvas) {
                canvas.set('option', option).set('skin', skin);
            }
        }
    }
    clearDrawings() {
        const canvas = this.get('canvas');
        if (canvas) {
            canvas
                .get('front')
                .get('drawTool')
                .clearDrawings();
        }
    }

    data(data) {
        if (utils.isFunction(data.then)) {
            // is a promise
            data.then(result => {
                this.__applyData(result);
            });
        } else {
            this.__applyData(data);
        }
        return this;
    }
    render() {
        this.update();
    }
    option(option) {
        this.optionHandler(option);
        if (this.get('canvas')) {
            this.get('canvas').option(this._option);
        }
        this.update();
    }
    setDrawings(drawings) {
        if (this.get('canvas')) {
            this.get('canvas')
                .get('front')
                .get('drawTool')
                .setDrawings(drawings);
        } else {
            this.get('dataManager').drawings(drawings);
        }
    }
    getVisibleDrawings() {
        const drawTool =
            this.get('canvas') &&
            this.get('canvas').get('front') &&
            this.get('canvas')
                .get('front')
                .get('drawTool');
        if (drawTool) {
            return drawTool.getVisibleDrawings();
        }
        return [];
    }
    triggerMousemove(position) {
        const canvas = this.get('canvas');
        if (canvas) {
            canvas.triggerMousemove(position);
        }
    }
    triggerMouseleave() {
        const canvas = this.get('canvas');
        if (canvas) {
            canvas.triggerMouseleave();
        }
    }
    resize(w, h) {
        if (!w || !h) {
            const el = this.get('el').node();
            const { width, height } = utils.innerSizes(el);
            w = width;
            h = height;
        }
        if (!w || !h || w <= 0 || h <= 0) {
            return;
            // throw new Error(`Invalid dimensions for chart, width=${w}, height=${h}`);
        }
        this.trigger(EVENTS.Resize, { width: w, height: h });
        this.__createCanvas();
        this.set('width', w);
        this.set('height', h);
        const option = this.get('option');
        if (this.get('canvas')) {
            this.get('canvas').calculateSize();
        }
        if (option.legend.show) {
            this.__setPosition(
                w <= option.breakpoint ? Positions.TOP : Positions.RIGHT
            );
        }
        this.update();
    }
    update() {
        if (this.get('canvas')) {
            this.get('canvas')
                .calculateExtraXAxisInfo()
                .calculateExtraYAxisInfo('y1')
                .calculateExtraYAxisInfo('y2')
                .update();
        }
    }
    destroy() {
        if (this.get('canvas')) {
            this.get('canvas').destroy();
        }
        this.unwatchAll();
        utils.resizeObserver.unobserve(
            this.get('el').node(),
            this.resizeCallback
        );
        this.get('el').remove();
        return this;
    }

    zoom(type) {
        if (this.get('canvas')) {
            this.get('canvas').zoom(type);
        }
    }
    disablePanAndZoom() {
        if (this.get('canvas')) {
            this.get('canvas').disablePanAndZoom();
        }
    }
    enablePanAndZoom() {
        if (this.get('canvas')) {
            this.get('canvas').enablePanAndZoom();
        }
    }

    changeDrawingsType(id) {
        if (this.get('canvas')) {
            this.get('canvas').changeDrawingsType(id);
        }
    }
    __createCanvas() {
        let canvas = this.get('canvas');
        if (!canvas) {
            canvas = new Canvas(this.get('el'), this.get('option'));
            canvas
                .set('d3Locale', this.get('d3Locale'))
                .set('dataManager', this.get('dataManager'));
            canvas.on(EVENTS.YaxisWidthChanged, param => {
                this.trigger(EVENTS.YaxisWidthChanged, param);
            });
            canvas.on(EVENTS.Mousemove, param => {
                this.trigger(EVENTS.Mousemove, param);
            });
            canvas.on(EVENTS.Mouseleave, param => {
                this.trigger(EVENTS.Mouseleave, param);
            });
            canvas.on(EVENTS.HideTips, param => {
                this.trigger(EVENTS.HideTips, param);
            });
            canvas.on(EVENTS.ShowTips, param => {
                this.trigger(EVENTS.ShowTips, param);
            });
            canvas.on(EVENTS.DomainChanged, param => {
                this.trigger(EVENTS.DomainChanged, param);
            });
            canvas.on(EVENTS.DrawEnd, drawings => {
                this.trigger(EVENTS.DrawEnd, drawings);
            });
            this.set('canvas', canvas);
        }
    }
    __setPosition(position) {
        const canvas = this.get('canvas');
        if (canvas) {
            canvas.set('position', position);
        }
    }

    __applyData(data) {
        if (!utils.isPlainObject(data)) {
            return;
        }
        const dataManager = this.get('dataManager').emptyDataSet();
        ['y1', 'y2'].forEach(key => {
            if (data[key] && utils.isArray(data[key]) && data[key].length > 0) {
                data[key].forEach(series => {
                    dataManager.series(key, series);
                });
            }
        });
        const canvas = this.get('canvas');
        if (canvas) {
            canvas
                .set('timelineLevel', data.timelineLevel)
                .calculateExtraXAxisInfo()
                .calculateExtraYAxisInfo('y1')
                .calculateExtraYAxisInfo('y2')
                .update();
        }
    }
}
