<template>
    <div ref="chart" :class="classes"></div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import mwcMarketsPlotter from 'mwc-markets-plotter';
import { PERIODS, YIELDS } from '../metadata/datapoint';

const { utils, mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-treasury-ui-chart',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        yields: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    components: {},
    watch: {
        yields: {
            handler() {
                this.chart.data({
                    y1: this.chartData
                });
            },
            deep: true
        },
        'watchedSettings.skin': {
            handler(value) {
                this.chart.changeSkin(value);
            }
        }
    },
    computed: {
        classes() {
            const cls = [this.namespace()];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            } else {
                cls.push(this.namespace('-white'));
            }
            return cls;
        },
        chartData() {
            const dataSet = [];
            const displayYields = YIELDS.filter(
                element => utils.inArray(this.yields, element) > -1
            );
            this.dataModel
                .filter(
                    element => utils.inArray(displayYields, element.id) > -1
                )
                .forEach(element => {
                    const data = [];
                    const propertySet = Object.keys(element);
                    PERIODS.forEach(period => {
                        const property = utils.find(
                            propertySet,
                            p => p === period
                        );
                        let last;
                        if (property) {
                            last = element[property];
                        } else {
                            last = NaN;
                        }
                        data.push({
                            value: last,
                            xLabel: this.initedLabels[period],
                            id: period
                        });
                    });

                    dataSet.push({
                        id: element.id,
                        name: this.initedLabels[element.id],
                        displayName: this.initedLabels[element.id],
                        data,
                        graphType: 'dotline',
                        baseValue: null
                    });
                });
            return dataSet;
        }
    },
    data() {
        return {};
    },
    created() {
        this.namespace = utils.namespace('treasury-ui-chart', true);
        this.initedLabels = this.labels;

        const tickSubLabels = ['Maturity'];
        this.option = {
            legend: {
                show: true,
                width: 90
            },
            y1axis: {
                show: true,
                label: 'Yield %'
            },
            xaxis: {
                tickSubLabels
            },
            graph: {
                align: 'center'
            },
            tooltip: {
                namespace: 'mwc-markets-treasury-ui-chart',
                caption: d =>
                    `${this.initedLabels[`${d[0].id}Name`]} ${
                        this.initedLabels['maturityYieldsAsOf']
                    }`
            },
            skin: this.watchedSettings.skin,
            ariaLabel: this.initedLabels['treasuryFigureCaption']
        };
    },
    mounted() {
        this.chart = new mwcMarketsPlotter(this.$refs.chart, this.option);
        this.chart.data({
            y1: this.chartData
        });
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-treasury-ui-chart';
.#{$namespace} {
    margin: 0 5px;
    border: 0px solid #ccc;
    height: 100%;
    min-height: 200px;
}
</style>
