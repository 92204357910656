import d3 from 'd3';
import { Classes } from '../constants';

export default function drawVolumeByPrice({
    selection,
    xScale,
    yScale,
    data,
    className = '',
    volumeByPriceScale = 0.4,
    styles
} = {}) {
    const sXScale = xScale.range()[0];
    const width = xScale.range()[1] - sXScale;
    const totalVolume = data[2];
    const volXScale = d3.scale
        .linear()
        .range([0, width * volumeByPriceScale])
        .domain([0, totalVolume]);
    const margeValue = 1;
    const drawRectangle = barGraph => {
        barGraph
            .append('rect')
            .style(styles['positive'])
            .attr('class', `${Classes.GRAPH_VOLUME_BY_PRICE}__positive`)
            .attr('x', sXScale)
            .attr('y', function(d) {
                return yScale(d.high) - margeValue;
            })
            .attr('width', function(d) {
                return volXScale(d.upVolume);
            })
            .attr('height', function(d) {
                return yScale(d.close) - yScale(d.high) - 2 * margeValue;
            });

        barGraph
            .append('rect')
            .style(styles['negative'])
            .attr('class', `${Classes.GRAPH_VOLUME_BY_PRICE}__negative`)
            .attr('x', function(d) {
                return volXScale(d.upVolume) + sXScale;
            })
            .attr('y', function(d) {
                return yScale(d.high) - margeValue;
            })
            .attr('width', function(d) {
                return volXScale(d.downVolume);
            })
            .attr('height', function(d) {
                return yScale(d.close) - yScale(d.high) - 2 * margeValue;
            });
    };

    const target = selection.datum(data[0]);
    selection.each(function(dataArr) {
        const outerElem = d3
            .select(this)
            .append('g')
            .attr('class', `${Classes.GRAPH_VOLUME_BY_PRICE} ${className}`);
        dataArr.forEach(function(dataItem, index) {
            const barGraph = outerElem
                .append('g')
                .data([dataItem])
                .attr('class', `${Classes.GRAPH_VOLUME_BY_PRICE}-${index}`);
            drawRectangle(barGraph);
        });
    });

    return target;
}
