function toInteger(num) {
    if (isInteger(num)) {
        return {
            times: 1,
            intNum: num
        };
    }
    const numString = num + '';
    const precision = numString.replace(/^-?\d+.(\d)/, '$1').length;
    const times = Math.pow(10, precision);
    return {
        times: times,
        intNum: parseInt(numString.replace('.', ''), 10)
    };
}
function isInteger(num) {
    return Math.floor(num) === num;
}

function operation(a, b, op) {
    const integerA = toInteger(a),
        integerB = toInteger(b);
    let ret,
        intNumA = integerA.intNum,
        intNumB = integerB.intNum;
    const maxTimes = Math.max(integerA.times, integerB.times);

    switch (op) {
        case '+':
            if (integerA.times > integerB.times) {
                intNumB = (intNumB * integerA.times) / integerB.times;
            } else if (integerA.times < integerB.times) {
                intNumA = (intNumA * integerB.times) / integerA.times;
            }
            ret = (intNumA + intNumB) / maxTimes;
            break;
        case '*':
            ret = (intNumA * intNumB) / (integerA.times * integerB.times);
            break;
        case '/':
            ret = (intNumA / intNumB) * (integerB.times / integerA.times);
            break;
        case '%':
            if (integerA.times > integerB.times) {
                intNumB = (intNumB * integerA.times) / integerB.times;
            } else if (integerA.times < integerB.times) {
                intNumA = (intNumA * integerB.times) / integerA.times;
            }
            ret = (intNumA % intNumB) / maxTimes;
            break;
    }
    return ret;
}
function add(a, b) {
    if (isInteger(a) || isInteger(b)) {
        return a + b;
    }
    return operation(a, b, '+');
}

function subtract(a, b) {
    if (isInteger(b)) {
        return a - b;
    }
    return operation(a, -b, '+');
}

function multiply(a, b) {
    if (isInteger(a) && isInteger(b)) {
        return a * b;
    }
    return operation(a, b, '*');
}

function divide(a, b) {
    if (isInteger(a) || isInteger(b)) {
        return a / b;
    }
    return operation(a, b, '/');
}

function mod(a, b) {
    return operation(a, b, '%');
}

export default {
    subtract,
    mod,
    divide,
    multiply,
    add
};
