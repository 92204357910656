<template>
    <markets-ui-grid
        :class="classes"
        :skin="watchedSettings.skin"
        :formatter="formatter"
        :option="gridOption"
        :columns="columnSet"
        :rows="rowSet"
    >
    </markets-ui-grid>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import { PERIODS, YIELDS, DATAPOINTS } from '../metadata/datapoint';
const { utils, mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-treasury-ui-table',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        timePeriods: {
            type: Array,
            default() {
                return PERIODS;
            }
        }
    },

    computed: {
        classes() {
            const cls = [this.namespace()];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            } else {
                cls.push(this.namespace('-white'));
            }
            return cls;
        },
        gridOption() {
            const option = {};
            [
                'scrollbarSize',
                'removable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'showRowNumber',
                'layout',
                'rowsPerPage',
                'stickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            utils.extend(true, option, this.watchedSettings.table);
            return option;
        },
        columnSet() {
            const columns = DATAPOINTS.filter(item => {
                return (
                    utils.inArray(this.timePeriods, item.id) > -1 || !item.param
                );
            });
            return columns.map(item => {
                item.name = this.labels[item.id];
                return item;
            });
        },
        rowSet() {
            const rows = [];
            YIELDS.forEach(id => {
                const item = utils.find(this.dataModel, data => data.id === id);
                if (item) {
                    const yields = this.labels[item.id] || item.id;
                    const row = utils.extend(
                        true,
                        {
                            yields
                        },
                        item
                    );
                    rows.push(row);
                }
            });
            return rows;
        }
    },
    data() {
        return {};
    },
    created() {
        this.namespace = utils.namespace('treasury-ui-table', true);
        this.initedLabels = this.labels;
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-treasury-ui-table';
.mwc-markets-treasury.markets-ui-container__fixed-height {
    .#{$namespace} {
        height: 100%;
    }
}
</style>
