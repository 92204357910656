/**
 * Get half tick size and the x position of data.
 * @param tickSize - The tick size.
 * @param xScale - The x scale.
 * @param xValue - The x value calculate function.
 * @param data - The x data.
 * @returns {{xPoint: number, halfTickSize: number}}
 */
export function getHalfTickSizeAndXPoint(tickSize, xScale, xValue, data) {
    const xDomain = xScale.domain();
    let fullTickSize = tickSize;
    let halfTickSize = tickSize / 2;
    let xPoint = xScale(xValue(data));
    if (data.index === xDomain[0]) {
        fullTickSize = halfTickSize;
        halfTickSize = fullTickSize / 2;
        xPoint = xScale(xDomain[0]) + halfTickSize;
    } else if (data.index === xDomain[1]) {
        fullTickSize = halfTickSize;
        halfTickSize = fullTickSize / 2;
        xPoint = xScale(xDomain[1]) - halfTickSize;
    }
    return {
        halfTickSize,
        xPoint,
        fullTickSize
    };
}

export function getGreaterSVGTextWidth(svg, data, styles) {
    let greatest = 0;
    data.forEach(item => {
        const width = getSVGTextWidth(svg, item.text, styles);
        greatest = width > greatest ? width : greatest;
    });
    return greatest;
}

export function getSVGTextWidth(svg, text, styles = {}) {
    const el = svg.append('text').style(styles);
    el[0][0].innerHTML = text.replace(/\s/g, '&nbsp;');
    const width = el.node().getBoundingClientRect().width;
    el.remove();
    return width;
}

export function getNumberDecimal(num) {
    num = Number(num).toString();
    if (num.indexOf('.') > -1) {
        return num.split('.')[1] && num.split('.')[1].length;
    } else {
        return 0;
    }
}

export function calculateTickSize({ size, data, width, sizeRange = [2, 30] }) {
    let tickSize = size;
    if (tickSize === 'auto') {
        tickSize = width / data.length - 2;
        tickSize = Math.min(tickSize, sizeRange[1]);
        tickSize = Math.max(tickSize, sizeRange[0]);
    }
    return tickSize;
}
