import Mixin from './mixin';
import d3 from 'd3';
import { GraphTypes } from './constants';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;

class Series extends Mixin {
    constructor() {
        super();
        this._name = null;
        this._displayName = null;
        this._color = null;
        this._graphType = GraphTypes.LINE;
        this._baseValue = null;
        this._isUpdown = false;
        this._series = [];
        this._parsers = {};
        this._negativeColor = null;
        this._positiveColor = null;
        this._eventDateMap = {};
        this._highlightOnHover = false;
        this._tag = {};
    }
    getValueExtent() {
        const series = this.get('series');
        let graphType = this.get('graphType');
        graphType = /hlc$/.test(graphType) ? GraphTypes.OHLC : graphType;
        const isOHLC =
            graphType === GraphTypes.OHLC ||
            graphType === GraphTypes.CANDLESTICK;
        const isSlider = graphType === GraphTypes.RANGE_SLIDER;
        const values = [];
        series.forEach(d => {
            if (isOHLC) {
                values.push(this._parsers.open(d));
                values.push(this._parsers.high(d));
                values.push(this._parsers.low(d));
                values.push(this._parsers.close(d));
            } else if (isSlider) {
                values.push(this._parsers.valueForSlider(d));
            } else {
                values.push(this._parsers.value(d));
            }
        });
        return d3.extent(values);
    }

    getDateExtent() {
        return d3.extent(this.get('series'), d => {
            return new Date(d.date).getTime();
        });
    }
    parser(attribute) {
        return this._parsers[attribute] || this._parsers.value;
    }
}

export default class DataManager {
    constructor() {
        this._parsers = {
            open: d => {
                return +d.open;
            },
            high: d => {
                return +d.high;
            },
            low: d => {
                return +d.low;
            },
            close: d => {
                return +d.close;
            },
            value: d => {
                return +d.value;
            },
            valueForSlider: d => {
                return +d.originalClose;
            },
            previousClose: d => {
                return +d.previousClose;
            }
        };
        this._dataSet = {};
        this._drawings = [];
    }
    parser(attribute, parser) {
        if (!attribute) {
            return;
        }
        if (this._parsers[attribute] && utils.isFunction(parser)) {
            this._parsers[attribute] = parser;
            return this;
        }
        return this._parsers[attribute];
    }
    drawings(drawings) {
        if (drawings) {
            this._drawings = utils.extend(true, [], drawings);
        }
        return this._drawings;
    }
    /**
     *
     * @param {string} yaxisName y1 or y2
     * @param {object} value {displayName: '', name: '', data: []}
     *
     */
    series(yaxisName, value) {
        if (yaxisName !== 'y1' && yaxisName !== 'y2') {
            return;
        }
        if (!value) {
            return this._dataSet[yaxisName];
        }
        if (!this._dataSet[yaxisName]) {
            this._dataSet[yaxisName] = [];
        }

        const series = new Series();
        let seriesData;

        if (value.graphType === GraphTypes.VOLUME_BY_PRICE) {
            seriesData = value.data;
        } else if (value.graphType === GraphTypes.EVENT) {
            value.data.forEach((d, index) => {
                if (isNaN(this._eventDateMap[index]) && !isNaN(d.value)) {
                    this._eventDateMap[index] = 0;
                } else if (!isNaN(d.value)) {
                    this._eventDateMap[index]++;
                }
            });
            seriesData = value.data.map((d, i) => {
                d.yIndex = this._eventDateMap[i];
                if (value.isUpdownEvent) {
                    d.color =
                        d.upDown > 0
                            ? value.positiveColor
                            : value.negativeColor;
                } else {
                    d.color = value.color;
                }
                d.index = i;
                return d;
            });
        } else {
            seriesData = value.data.map((d, i) => {
                d.index = i;
                return d;
            });
        }
        series
            .set('color', value.color)
            .set('displayName', value.displayName || value.name)
            .set('name', value.name)
            .set('graphType', value.graphType || GraphTypes.LINE)
            .set('footer', value.footer)
            .set('parsers', this._parsers)
            .set(
                'isUpDown',
                value.graphType === GraphTypes.BAR_UPDOWM ||
                    value.graphType === GraphTypes.BAR_UPDOWN_ABOVEBELOW
            )
            .set('baseValue', value.baseValue)
            .set('series', seriesData)
            .set('tag', value.tag)
            .set('highlightOnHover', value.highlightOnHover)
            .set(
                'dataRangeForSlider',
                value && value.dateRange ? value.dateRange : ''
            );

        this._dataSet[yaxisName].push(series);
        return this;
    }

    getValueExtent(yaxisName) {
        let extent = [];
        this._dataSet[yaxisName].forEach(series => {
            if (series.get('graphType') !== GraphTypes.EVENT) {
                extent.push(series.getValueExtent());
            }
        });
        extent = d3.extent(d3.merge(extent));
        return extent;
    }
    getMaxIndex() {
        let maxIndex = 0;
        for (const yaxisName in this._dataSet) {
            this._dataSet[yaxisName].forEach(series => {
                maxIndex = Math.max(maxIndex, series.get('series').length - 1);
            });
        }
        return maxIndex;
    }
    getDateExtent() {
        const extent = [];
        for (const yaxisName in this._dataSet) {
            this._dataSet[yaxisName].forEach(series => {
                extent.push(series.getDateExtent());
            });
        }
        return d3.extent(d3.merge(extent));
    }
    emptyDataSet() {
        this._dataSet = [];
        this._eventDateMap = {};
        return this;
    }
}
