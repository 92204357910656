import Mixin from './mixin';

export class Ticks extends Mixin {
    constructor() {
        super();
        // this._abbreviation = null;
        this._labels = null;
        this._subLabels = null;
        this._size = null;
        this._values = null;
        this._format = null;
        this._subFormat = null;
        this._uniqueSubLabel = true; // control if sublabels is unique, only for xaxis
        this._minSublabelCount = 0; // control how many sublabels need show at least, only for xaxis
    }
}
export class TicksManager extends Mixin {
    constructor() {
        super();
        this._ticks = {};
    }
    ticks(name, ticks) {
        if (!name) {
            return;
        }
        if (!ticks) {
            return this._ticks[name];
        }
        if (ticks instanceof Ticks) {
            this._ticks[name] = ticks;
        }
        return this;
    }
    remove(name) {
        this._ticks[name] = null;
        delete this._ticks[name];
    }
}
