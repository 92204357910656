var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.cls,
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "div",
        {
          class: [_vm.namespace("container")],
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.showWebLayout && !_vm.errorMessage
            ? _c("div", { class: _vm.namespace("navigation-wrapper") }, [
                _c(
                  "div",
                  { class: _vm.namespace("navigation") },
                  [
                    _c("markets-ui-button-group", {
                      attrs: {
                        dataModel: _vm.settingViewType,
                        skin: _vm.watchedSettings.skin
                      },
                      on: { change: _vm.changeSettingsViewType }
                    }),
                    _vm._v(" "),
                    _vm.showMenubutton
                      ? _c("markets-ui-button", {
                          class: _vm.namespace("yields-button"),
                          attrs: {
                            "icon-right": "caret-down",
                            skin: _vm.watchedSettings.skin,
                            text: _vm.menuButtonlabel,
                            "aria-expanded": _vm.menuExpanded
                          },
                          on: { click: _vm.clickMenuButton }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.data.length > 0
            ? _c(
                "keep-alive",
                [
                  _c(_vm.viewType, {
                    tag: "component",
                    class: _vm.namespace("sub-component"),
                    style: _vm.subComponentStyle,
                    attrs: {
                      skin: _vm.watchedSettings.skin,
                      labels: _vm.initedLabels,
                      dataModel: _vm.dataModel.data,
                      yields: _vm.watchedSettings.yields,
                      timePeriods: _vm.watchedSettings.maturities,
                      settings: _vm.watchedSettings
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { class: _vm.namespace("as-of-date") }, [
            _vm._v(_vm._s(_vm.asOfDate))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "treasuryUiSettings",
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            showBack:
              !!_vm.settingsPopoverNavSelected && !_vm.showWebLayoutSettings,
            showDone: _vm.showDoneButton,
            "triggered-by": _vm.settingsPopoverTriggerId,
            visible: _vm.settingsPopoverVisible,
            title: _vm.settingsPopoverTitle,
            doneDisable: !!_vm.settingsErrorMessage
          },
          on: {
            hide: _vm.hideSettingsPopover,
            done: _vm.changeSetting,
            back: _vm.changeSettingsNav
          },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showTimePeriodsSetting
            ? _c("markets-ui-checkbox-group", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingTimePeriods
                },
                on: { change: _vm.changeSettingTimePeriods },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showYieldsSetting
            ? _c("markets-ui-checkbox-group", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingYields
                },
                on: { change: _vm.changeSettingYields },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showViewTypeSetting
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  variation: "selection",
                  dataModel: _vm.settingViewType
                },
                on: { change: _vm.changeSettingsViewType },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "footer",
            {
              class: _vm.namespace("setting-footer"),
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.showYieldsSetting || _vm.showTimePeriodsSetting
                ? _c("div", [
                    _vm.settingsErrorMessage
                      ? _c(
                          "div",
                          {
                            class: [
                              _vm.namespace("setting-footer-message__error")
                            ]
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.settingsErrorMessage) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.namespace("setting-footer-content-panel") },
                      [
                        _c(
                          "span",
                          {
                            class: [
                              _vm.namespace("setting-footer-content-count")
                            ]
                          },
                          [_vm._v(_vm._s(_vm.selectedCount))]
                        ),
                        _vm._v(" "),
                        _c("markets-ui-button", {
                          class: _vm.namespace("setting-footer-content-reset"),
                          attrs: {
                            variation: "flat",
                            skin: _vm.watchedSettings.skin,
                            text: _vm.initedLabels.deselect
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.resetDefault($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }