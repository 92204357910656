const CommonXLabelDateTimeFormat = {
    YearMonthDay: '%b %d, %Y',
    Year: '%Y',
    YearMonth: '%Y %b',
    Month: '%b',
    MonthDay: '%b %d',
    HourMinutes: '%I:%M %p'
};
const EUXLabelDateTimeFormat = {
    YearMonthDay: '%d %b, %Y',
    Year: '%Y',
    YearMonth: '%Y %b',
    Month: '%b',
    MonthDay: '%d %b',
    HourMinutes: '%I:%M %p' // 24 hours: '%H:%M'
};

const ChinaXLabelDateTimeFormat = {
    YearMonthDay: '%Y %d %b',
    Year: '%Y',
    YearMonth: '%Y %b',
    Month: '%b',
    MonthDay: '%b%d日',
    HourMinutes: '%I:%M %p' // 24 hours: '%H:%M'
};

export const locales = {
    en: {
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%m/%d/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ],
        shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        shortMonths: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        XLabelDateTimeFormat: CommonXLabelDateTimeFormat
    },
    fr: {
        decimal: ',',
        thousands: ' ',
        grouping: [3],
        dateTime: '%A, le %e %B %Y, %X',
        date: '%d/%m/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            'dimanche',
            'lundi',
            'mardi',
            'mercredi',
            'jeudi',
            'vendredi',
            'samedi'
        ],
        shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        months: [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre'
        ],
        shortMonths: [
            'janv.',
            'févr.',
            'mars',
            'avr.',
            'mai',
            'juin',
            'juil.',
            'août',
            'sept.',
            'oct.',
            'nov.',
            'déc.'
        ],
        XLabelDateTimeFormat: EUXLabelDateTimeFormat
    },
    'zh-Hans': {
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%m/%d/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            '星期日',
            '星期一',
            '星期二',
            '星期三',
            '星期四',
            '星期五',
            '星期六'
        ],
        shortDays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        months: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        shortMonths: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        XLabelDateTimeFormat: ChinaXLabelDateTimeFormat
    },
    'zh-Hant': {
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%m/%d/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            '星期日',
            '星期一',
            '星期二',
            '星期三',
            '星期四',
            '星期五',
            '星期六'
        ],
        shortDays: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        months: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        shortMonths: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        XLabelDateTimeFormat: ChinaXLabelDateTimeFormat
    },
    ja: {
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%m/%d/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            '日曜日',
            '月曜日',
            '火曜日',
            '水曜日',
            '木曜日',
            '金曜日',
            '土曜日'
        ],
        shortDays: [
            '日曜日',
            '月曜日',
            '火曜日',
            '水曜日',
            '木曜日',
            '金曜日',
            '土曜日'
        ],
        months: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        shortMonths: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        XLabelDateTimeFormat: ChinaXLabelDateTimeFormat
    },
    it: {
        decimal: ',',
        thousands: ' ',
        grouping: [3],
        dateTime: '%A, le %e %B %Y, %X',
        date: '%d/%m/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: [
            'Domenica',
            'Lunedi',
            'Martedì',
            'Mercoledì',
            'Giovedì',
            'Venerdì',
            'Sabato'
        ],
        shortDays: ['Do.', 'Lun.', 'Mar.', 'Mer.', 'Gio.', 'Ven.', 'Sab.'],
        months: [
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre'
        ],
        shortMonths: [
            'Genn.',
            'Febbr.',
            'Mar.',
            'Apr.',
            'Magg.',
            'Giugno',
            'Luglio',
            'Ag.',
            'Sett.',
            'Ott.',
            'Nov.',
            'Dic.'
        ],
        XLabelDateTimeFormat: EUXLabelDateTimeFormat
    }
};
