import d3 from 'd3';
import { Classes } from '../constants';
import { getHalfTickSizeAndXPoint } from '../common';
export default function drawOHLC({
    selection,
    xScale,
    xValue,
    yValue,
    yOValue,
    yHValue,
    yLValue,
    yCValue,
    yScale,
    data,
    className = '',
    tickSize = 4,
    styles
} = {}) {
    const ohlcPath = data => {
        const { halfTickSize, xPoint } = getHalfTickSizeAndXPoint(
            tickSize,
            xScale,
            xValue,
            data
        );
        return [
            'M',
            xPoint - halfTickSize,
            yScale(yOValue(data)),
            'l',
            halfTickSize,
            0,
            'M',
            xPoint,
            yScale(yHValue(data)),
            'L',
            xPoint,
            yScale(yLValue(data)),
            'M',
            xPoint,
            yScale(yCValue(data)),
            'l',
            halfTickSize,
            0
        ].join(' ');
    };

    const ohlc = data => {
        return data.map(d => ohlcPath(d)).join(' ');
    };

    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_OHLC} ${className}`);
    const groupdData = d3
        .nest()
        .key(function(d) {
            const close = yCValue(d);
            const open = yOValue(d);
            if (!close || !open) {
                return 'invalid';
            } else if (d.isPullData && d.status === 'Pre-Open') {
                return 'dot';
            } else if (close > open) {
                return 'positive';
            } else if (close < open) {
                return 'negative';
            } else {
                return 'neutral';
            }
        })
        .entries(data);

    groupdData.forEach(g => {
        if (g.key === 'invalid') {
            return;
        } else if (g.key === 'dot') {
            const gValue = g.values[0];
            target
                .append('circle')
                .attr('r', 4)
                .attr('cx', xScale(xValue(gValue)))
                .attr('cy', yScale(yValue(gValue)))
                .style(styles);
        } else {
            target
                .append('g')
                .attr('class', `${Classes.GRAPH_OHLC}__${g.key}`)
                .append('path')
                .attr('d', ohlc(g.values))
                .style(styles[g.key]);
        }
    });

    return target;
}
