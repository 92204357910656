import Mixin from './mixin';
import d3 from 'd3';
import { Ticks, TicksManager } from './ticks';
import ScaleManager from './scale';
import Axes from './axes';
import Grid from './grid';
import drawLine from './graph/line';
import drawArea from './graph/area';
import Legend from './legend';
import drawDot from './graph/dot';
import drawOHLC from './graph/ohlc';
import drawCandlestick from './graph/candlestick';
import drawBar from './graph/bar';
import drawAboveBelow from './graph/above-below';
import drawVolumeByPrice from './graph/volume-by-price';
import drawEvents from './graph/event';
import drawTag from './graph/tag';
import drawSliderArea from './graph/range-slider';
import {
    Classes,
    Positions,
    GraphTypes,
    MDS,
    OFFSET,
    EVENTS
} from './constants';
import mwcMarketsCore from 'mwc-markets-core';
import Front from './front';
import {
    getGreaterSVGTextWidth,
    getNumberDecimal,
    calculateTickSize
} from './common';
import MATH from './math';

const { utils } = mwcMarketsCore;

export default class Canvas extends Mixin {
    /**
     * @param {D3 selection} container of the canvas,  Required.
     */
    constructor(selection, option) {
        super();
        this._position = option.legend.show ? Positions.RIGHT : Positions.NONE;
        this._el = selection.classed(Classes.CANVAS_WRAPPER, true);
        if (this._position) {
            this._el.classed(
                `${Classes.CANVAS_WRAPPER}__${this._position}`,
                true
            );
        }
        this._option = option;
        this._d3Locale = null;
        this._svg = null;
        this._dataManager = null;
        this._timelineLevel = null;
        this._ticks = new TicksManager();
        this._scales = new ScaleManager();
        this._axes = null;
        this._grid = null;
        this._axesLayer = null;
        this._gridLayer = null;
        this._frontLayer = null;
        this._graphLayer = null;
        this.get('el')
            .selectAll('*')
            .remove();
        this.watch('position', (val, oldVal) => {
            this._el
                .classed(`${Classes.CANVAS_WRAPPER}__${oldVal}`, false)
                .classed(`${Classes.CANVAS_WRAPPER}__${val}`, true);

            let top = 0;
            let width = this.get('width');
            if (val === Positions.TOP) {
                top = this._option.legend.height;
            } else if (val === Positions.RIGHT) {
                width = width - this._option.legend.width;
            }
            ['grid', 'axes', 'front'].forEach(key => {
                const sub = this.get(key);
                if (sub) {
                    sub.set('top', top);
                    if (key === 'axes') {
                        sub.set('width', width);
                    }
                }
            });
        });
        // skin change handler
        this.watch('skin', value => {
            const svg = this.get('svg');
            if (svg) {
                svg.style(this._option.styles.svg);
            }
            ['grid', 'axes', 'legend'].forEach(key => {
                const sub = this.get(key);
                if (sub) {
                    const subOption = sub.get('option');
                    if (subOption) {
                        subOption.styles = this._option.styles[key];
                    }
                    sub.set('skin', value);
                }
            });
            this.get('front').get('option').styles = this._option.styles;
            this.get('front').set('skin', value);
        });
        this.update();
    }
    option(option) {
        this._option = utils.extend(true, {}, option);
    }
    update() {
        this.calculateSize();
        this.__createSVG();
        this.__createScales();
        this.__createGrid();
        this.__createAxes();
        this.__createFront();
        this.__draw();
    }
    destroy() {
        this.unwatchAll();
        this.get('el')
            .selectAll('*')
            .remove();
        this.get('front').destroy();
        return this;
    }
    triggerMousemove(position) {
        this.get('front').triggerMousemove({
            position,
            external: true
        });
    }
    triggerMouseleave(x) {
        this.get('front').triggerMouseleave();
    }
    layer(layerName, groupName, avoidCreate) {
        groupName = `${groupName || 'chart'}Group`;
        const group = this.get(groupName);
        let layer = group.select(`.${layerName}`);
        if (!avoidCreate && layer.empty()) {
            layer = group.append('g').classed(layerName, true);
        }
        return layer;
    }
    removeLayer(layerName, groupName) {
        groupName = `${groupName || 'chart'}Group`;
        const group = this.get(groupName);
        group.select(`.${layerName}`).remove();
        return this;
    }
    calculateExtraYAxisInfo(yaxisName) {
        const option = this.get('option');
        const dataManager = this.get('dataManager');
        const dataset = dataManager.series('y1');
        if (!dataset || !dataset.length) {
            return this;
        }
        if (!dataManager.series(yaxisName)) {
            return this;
        }
        let domain = dataManager.getValueExtent(
            yaxisName,
            option.yaxis.autoScale
        );

        const yaxisOpt = option[`${yaxisName}axis`];
        let yTickValues = yaxisOpt.tickValues;

        let decimal = option.yaxis.decimal;

        if (!yaxisOpt.tickValues) {
            const extraYAxisInfo = this.__calculateYAxisInfoByAlgorithm(
                domain,
                yaxisName
            );
            domain = extraYAxisInfo.domain;
            yTickValues = extraYAxisInfo.tickValues;
            decimal = extraYAxisInfo.decimal;
        } else {
            domain = [
                yaxisOpt.tickValues[0],
                yaxisOpt.tickValues[yaxisOpt.tickValues.length - 1]
            ];
        }
        let tickFormat = yaxisOpt.tickFormat;
        if (!tickFormat) {
            tickFormat = new Intl.NumberFormat(this._option.locale || 'en-US', {
                style: 'decimal',
                currency: this._option.yaxis.currency || 'USD',
                currencySymbol: this._option.yaxis.currencySymbol || '$',
                minimumFractionDigits: decimal || 2,
                maximumFractionDigits: decimal || 2
            }).format;
        }

        this.get('scales')
            .scale(yaxisName)
            .domain(domain);
        let yTicks = this.get('ticks').ticks(yaxisName);
        if (!yTicks) {
            yTicks = new Ticks();
        }
        const styles = option.styles.axes;
        let yTickSize =
            getGreaterSVGTextWidth(
                this.get('svg'),
                yTickValues.map(v => {
                    return { text: tickFormat(v) };
                }),
                utils.extend(
                    {},
                    styles.default.text,
                    styles.y.text,
                    styles[yaxisName].text
                )
            ) +
            option.yaxis.tickMargin +
            option.yaxis.extraTickMargin;
        yTickSize = Math.max(yTickSize, option.yaxis.tickSize);
        yTicks
            .set('values', yTickValues)
            .set('size', yTickSize)
            .set('format', tickFormat);
        this.trigger(EVENTS.YaxisWidthChanged, {
            yaxisName: yaxisName,
            width: yTickSize,
            yPadding: this.get('yPadding')
        });
        this.get('ticks').ticks(yaxisName, yTicks);
        return this;
    }
    calculateExtraXAxisInfo() {
        const dataset = this.get('dataManager').series('y1');
        if (!dataset || !dataset.length) {
            return this;
        }
        // when the graph align is center, extend the scale by add 0.5 point at begin and end
        let offset = 0;
        if (this._option.graph.align && this._option.graph.align === 'center') {
            offset = OFFSET;
        }

        this.get('scales')
            .scale('x')
            .domain([
                0 - offset,
                this.get('dataManager').getMaxIndex() + offset
            ]);
        let xTicks = this.get('ticks').ticks('x');
        if (!xTicks) {
            xTicks = new Ticks();
        }

        const extraXAxisInfo = this.__calculateXAxisInfoByAlgorithm();

        const xaxis = this._option.xaxis;
        if (xaxis.tickValues) {
            xTicks.set('values', xaxis.tickValues);
        } else {
            xTicks.set('values', extraXAxisInfo.tickValues);
        }
        if (xaxis.tickLabels) {
            xTicks.set('labels', xaxis.tickLabels);
        } else {
            xTicks.set('labels', extraXAxisInfo.tickLabels);
        }
        if (xaxis.tickSubLabels) {
            xTicks.set('subLabels', xaxis.tickSubLabels);
        } else {
            xTicks.set('subLabels', extraXAxisInfo.tickSubLabels);
        }

        if (
            Object.prototype.hasOwnProperty.call(
                extraXAxisInfo,
                'uniqueSubLabel'
            )
        ) {
            xTicks.set('uniqueSubLabel', extraXAxisInfo.uniqueSubLabel);
        }
        if (
            Object.prototype.hasOwnProperty.call(
                extraXAxisInfo,
                'minSublabelCount'
            )
        ) {
            xTicks.set('minSublabelCount', extraXAxisInfo.minSublabelCount);
        }
        this.get('ticks').ticks('x', xTicks);
        return this;
    }
    calculateSize() {
        const option = this.get('option');
        const element = this.get('el')[0][0];
        if (utils.outerSizes(element, true).width <= option.breakpoint) {
            this.set('yPadding', option.yaxis.paddingSmall);
        } else {
            this.set('yPadding', option.yaxis.paddingBig);
        }
        this.__setMargins();
        const margin = this.get('margin');
        const { width, height } = utils.innerSizes(element);
        this.set('width', width - margin.left - margin.right);
        this.set('height', height - margin.top - margin.bottom);
        return this;
    }
    __setMargins() {
        const option = this.get('option');
        const ticks = this.get('ticks');
        const y1AxisOrient = option.y1axis.orient;
        const y1Ticks = ticks.ticks('y1');
        const y2Ticks = ticks.ticks('y1');
        const y1TickSize = y1Ticks
            ? y1Ticks.get('size')
            : option.yaxis.tickSize;
        const y2TickSize = y2Ticks
            ? y2Ticks.get('size')
            : option.yaxis.tickSize;
        const yLabelWidth =
            option.y1axis.label || option.y2axis.label
                ? option.yaxis.labelWidth
                : 0;
        const ySpacing = option.yaxis.spacing;
        const y1AxisWidth = y1TickSize + yLabelWidth;
        const y2AxisWidth = y2TickSize + yLabelWidth;
        const margin = {
            top: option.margin.top,
            right: option.margin.right,
            bottom: option.margin.bottom,
            left: option.margin.left
        };
        const yPadding = this.get('yPadding');
        if (option.y2axis.show) {
            const y2AxisOrient = option.y2axis.orient;
            if (y1AxisOrient === 'left') {
                if (y2AxisOrient === 'left') {
                    margin.left =
                        y1AxisWidth + y2AxisWidth + ySpacing + yPadding;
                } else if (y2AxisOrient === 'right') {
                    margin.left = y1AxisWidth + ySpacing + yPadding;
                    margin.right = y2AxisWidth + ySpacing + yPadding;
                }
            } else {
                if (y2AxisOrient === 'left') {
                    margin.left = y2AxisWidth + ySpacing + yPadding;
                    margin.right = y1AxisWidth + ySpacing + yPadding;
                } else if (y2AxisOrient === 'right') {
                    margin.right =
                        y1AxisWidth + y2AxisWidth + ySpacing + yPadding;
                }
            }
        } else if (y1AxisOrient === 'left') {
            margin.left = y1AxisWidth + yPadding;
            margin.right = 0;
        } else {
            margin.left = 0;
            margin.right = y1AxisWidth + yPadding;
        }
        if (option.xaxis.show) {
            if (option.xaxis.orient === 'top') {
                margin.top = 30;
                margin.bottom += 0;
            } else {
                margin.bottom += 35; // this.get('tickLabels') ? 45 : 35;
            }
        }
        this.set('margin', margin);
    }

    __createSVG() {
        let svg = this.get('svg');
        const option = this.get('option');
        const margin = this.get('margin');
        if (!svg) {
            svg = this.get('el')
                .append('svg')
                .attr('aria-hidden', true)
                .attr('class', Classes.CANVAS)
                .style(option.styles.svg);
            this.set('svg', svg);
        }
        this.__createContainerGroups();
        const width = this.get('width');
        const height = this.get('height');
        svg.attr('width', width + margin.left + margin.right).attr(
            'height',
            height + margin.top + margin.bottom
        );
    }
    __createContainerGroups() {
        const svg = this.get('svg');
        const margin = this.get('margin');
        let groups = svg.select(`.${Classes.LAYERS}`);
        if (groups.empty()) {
            groups = svg.append('g').classed(Classes.LAYERS, true);
            this.set(
                'axesGroup',
                groups.append('g').classed(Classes.AXES, true)
            )
                .set(
                    'backGroup',
                    groups.append('g').classed(Classes.BACK, true)
                )
                .set(
                    'graphGroup',
                    groups.append('g').classed(Classes.GRAPH, true)
                )
                .set('tagGroup', groups.append('g').classed(Classes.TAG, true))
                .set(
                    'frontGroup',
                    groups.append('g').classed(Classes.FRONT, true)
                )
                .set(
                    'eventGraphGroup',
                    groups.append('g').classed(Classes.EVENTS_GRAPH, true)
                );
        }
        const translateX = this.__hasLeftAxis()
            ? margin.left - this.get('yPadding')
            : margin.left;
        groups.attr('transform', `translate( ${translateX},${margin.top})`);
    }
    __createLegendGroups() {
        const svg = this.get('svg');
        const option = this.get('option');
        const svgWidth = svg.attr('width');
        let groups = svg.select(`.${Classes.LEGEND}`);
        let legendX = svgWidth - option.legend.width;
        let legendY = this.get('margin').top;
        if (this._position === Positions.TOP) {
            legendX = 20;
            legendY = 10;
        }
        if (groups.empty()) {
            groups = svg.append('g').classed(Classes.LEGEND, true);
        }
        groups.attr('transform', `translate(${legendX}, ${legendY})`);
        this.__createLegend();
    }
    __hasLeftAxis() {
        const option = this.get('option');
        return (
            (option.y1axis.show && option.y1axis.orient === 'left') ||
            (option.y2axis.show && option.y2axis.orient === 'left')
        );
    }
    __createScales() {
        const option = this.get('option');
        const yPadding = this.get('yPadding');
        const scales = this.get('scales');
        let [xScale, y1Scale, y2Scale] = [
            scales.scale('x'),
            scales.scale('y1'),
            scales.scale('y2')
        ];
        if (!xScale) {
            xScale = d3.scale.linear();
            scales.scale('x', xScale);
        }

        // showLegend
        let scaleWidth = this.get('width'),
            scaleHeight = this.get('height');
        if (option.legend.show) {
            if (this._position !== Positions.TOP) {
                scaleWidth = scaleWidth - option.legend.width;
            } else {
                scaleHeight = scaleHeight - option.legend.height;
            }
        }
        xScale.rangeRound([
            this.__hasLeftAxis() ? yPadding : 0,
            this.__hasLeftAxis() ? scaleWidth + yPadding : scaleWidth
        ]);
        let originalDomain;
        if (y1Scale) {
            originalDomain = y1Scale.domain();
        }
        if (option.y1axis.scaleType === 'log') {
            y1Scale = d3.scale.log();
            if (originalDomain) {
                y1Scale.domain(originalDomain);
            }
        } else {
            y1Scale = d3.scale.linear();
            if (originalDomain) {
                y1Scale.domain(originalDomain);
            }
        }
        scales.scale('y1', y1Scale);
        y1Scale.rangeRound([scaleHeight, 0]);
        if (option.y2axis) {
            if (option.y2axis.scaleType === 'log') {
                y2Scale = d3.scale.log();
            } else {
                y2Scale = d3.scale.linear();
            }
            scales.scale('y2', y2Scale);
        }
        if (y2Scale) {
            y2Scale.rangeRound([scaleHeight, 0]);
        }
        if (option.legend.show) {
            this.__createLegendGroups();
        }
    }

    __createGrid() {
        const option = this.get('option');
        let grid = this.get('grid');
        const gridOption = {
            styles: option.styles.grid,
            skin: option.skin,
            gridX: option.gridX,
            gridY: option.gridY
        };
        if (!grid) {
            grid = new Grid(this.get('backGroup'), gridOption);
            grid.set('screenMinHeight', option.screenMinHeight)
                .set('scales', this.get('scales'))
                .set('ticks', this.get('ticks'));
        } else {
            grid.set('option', gridOption);
        }
        // when the graph align is center, extend the scale by add 0.5 data point at begin and end
        if (option.graph && option.graph.align === 'center') {
            grid.set('xOffset', OFFSET);
        }
        grid.update();
        this.set('grid', grid);
    }
    __createAxes() {
        let axes = this.get('axes');
        const option = this.get('option');
        const axesOption = {
            xaxis: option.xaxis,
            yaxis: option.yaxis,
            y1axis: option.y1axis,
            y2axis: option.y2axis,
            styles: option.styles.axes,
            skin: option.skin
        };
        let width = this.get('width');
        width =
            this.get('position') === Positions.RIGHT
                ? width - option.legend.width
                : width;
        if (!axes) {
            axes = new Axes(this._el, axesOption);
            axes.set('ticksManager', this.get('ticks'))
                .set('width', width)
                .set('yPadding', this.get('yPadding'));
        } else {
            axes.option(axesOption);
        }
        axes.set('width', this.get('width'))
            .set('height', this.get('height'))
            .set('yPadding', this.get('yPadding'))
            .set('scales', this.get('scales'))
            .update();
        this.set('axes', axes);
    }
    zoom(type) {
        const front = this.get('front');
        front.zoom(type);
    }
    disablePanAndZoom() {
        const front = this.get('front');
        front.disablePanAndZoom();
    }
    enablePanAndZoom() {
        const front = this.get('front');
        front.enablePanAndZoom();
    }
    changeDrawingsType(id) {
        const front = this.get('front');
        front.changeDrawingsType(id);
    }
    __createFront() {
        const option = this.get('option');
        let front = this.get('front');
        const frontOption = {
            highlight: option.highlight,
            tooltip: option.tooltip,
            styles: option.styles,
            skin: option.skin,
            graph: option.graph,
            mouseMoveOnValidValue: option.mouseMoveOnValidValue,
            highLightHoveredLine: option.highLightHoveredLine,
            zoom: option.zoom,
            drawings: option.drawings,
            tag: option.tag,
            y1axis: option.y1axis
        };
        if (!front) {
            front = new Front(this.get('frontGroup'), frontOption);
            front.on(EVENTS.Mousemove, param => {
                this.trigger(EVENTS.Mousemove, param);
            });
            front.on(EVENTS.Mouseleave, () => {
                this.trigger(EVENTS.Mouseleave);
            });
            front.on(EVENTS.ShowTips, param => {
                this.trigger(EVENTS.ShowTips, param);
            });
            front.on(EVENTS.HideTips, param => {
                this.trigger(EVENTS.HideTips, param);
            });
            front.on(EVENTS.HighlightHoveredLine, param => {
                this.__highlightHoveredLine(param);
            });
            front.on(EVENTS.DomainChanged, param => {
                this.trigger(EVENTS.DomainChanged, param);
            });
            front.on(EVENTS.DrawEnd, drawings => {
                this.trigger(EVENTS.DrawEnd, drawings);
            });
        } else {
            front.option(frontOption);
        }
        front
            .set('width', this.get('width'))
            .set('height', this.get('height'))
            .set('scales', this.get('scales'))
            .set('margin', this.get('margin'));

        const dataManager = this.get('dataManager');
        if (dataManager) {
            front.set('dataManager', dataManager);
        }
        front.update();
        this.set('front', front);
    }
    __highlightHoveredLine({ index }) {
        this.get('graphGroup')
            .selectAll(`.${Classes.HIGHLIGHT_LINE}`)
            .classed(Classes.HIGHLIGHT_LINE, false);

        this.get('tagGroup')
            .selectAll(`.${Classes.HIGHLIGHT_TAG}`)
            .classed(Classes.HIGHLIGHT_TAG, false);
        if (index > -1) {
            const tagEl = this.get('tagGroup').select(
                `.${Classes.TAG}-${index}`
            );
            const graphElement = this.get('graphGroup').select(
                `.${Classes.GRAPH}-${index}`
            );
            d3.select(
                this.get('tagGroup')[0][0].appendChild(tagEl[0][0])
            ).classed(Classes.HIGHLIGHT_TAG, true);
            if (index !== 0) {
                d3.select(
                    this.get('graphGroup')[0][0].appendChild(graphElement[0][0])
                ).classed(Classes.HIGHLIGHT_LINE, true);
            } else {
                this.get('graphGroup')
                    .select(`.${Classes.GRAPH}-${index}`)
                    .classed(Classes.HIGHLIGHT_LINE, true);
            }
        }
    }
    __createLegend() {
        const option = this.get('option');
        let legend = this.get('legend');
        if (!legend) {
            legend = new Legend(
                this.get('el'),
                utils.extend(true, option.legend, {
                    tooltip: option.tooltip,
                    styles: option.styles.legend,
                    skin: option.skin
                })
            );
            legend.set('scales', this.get('scales'));
        }
        legend
            .set('dataManager', this.get('dataManager'))
            .set('position', this._position)
            .update();
        this.set('legend', legend);
    }
    __draw() {
        const dataManager = this.get('dataManager');
        if (!dataManager) {
            return;
        }
        this.get('graphGroup')
            .selectAll('*')
            .remove();
        this.get('eventGraphGroup')
            .selectAll('*')
            .remove();
        this.get('tagGroup')
            .selectAll('*')
            .remove();
        let gIdx = 0;
        ['y1', 'y2'].forEach(yaxisName => {
            const yDataSet = dataManager.series(yaxisName);
            if (yDataSet && utils.isArray(yDataSet) && yDataSet.length) {
                yDataSet.forEach(series => {
                    this.__drawSeries(yaxisName, series, gIdx);
                    gIdx++;
                });
            }
        });
    }
    __drawSeries(yaxisName, series, index) {
        const option = this.get('option');
        const scales = this.get('scales');
        const styles = option.styles;
        const scaleY =
            this._position !== Positions.TOP ? 0 : option.legend.height;
        const colorIndex = (index % Object.keys(MDS['chart-color']).length) + 1;
        let graphType = series.get('graphType');

        const selection =
            graphType === GraphTypes.EVENT
                ? this.get('eventGraphGroup')
                      .append('g')
                      .classed(`${Classes.EVENTS_GRAPH}-${index}`, true)
                : this.get('graphGroup')
                      .append('g')
                      .classed(`${Classes.GRAPH}-${index}`, true);

        const args = {
            selection,
            xScale: scales.scale('x'),
            yScaleHeight: scales.scale(yaxisName),
            yScale: d => {
                return scales.scale(yaxisName)(d) + scaleY;
            },
            xValue: d => {
                return +d.index;
            },
            yValue: series.parser('value'),
            yOValue: series.parser('open'),
            yHValue: series.parser('high'),
            yLValue: series.parser('low'),
            yCValue: series.parser('close'),
            yPValue: series.parser('previousClose'),
            ySlider: series.parser('valueForSlider'),
            dateRangeForSlider: series._dataRangeForSlider,
            valid: d => {
                return utils.isFunction(option.valid)
                    ? option.valid(d)
                    : !isNaN(series.parser('value')(d));
            },
            data: this.__processData(
                series.get('series'),
                graphType,
                yaxisName
            ),
            type: graphType
        };
        const color = series.get('color') || MDS['chart-color'][colorIndex];
        series.set('color', color);
        const lineStyle = utils.extend(
            {
                stroke: color
            },
            styles.graph.line
        );

        const validDataLength = args.data.filter(d => !isNaN(d.value)).length;
        if (
            validDataLength === 1 &&
            (graphType === GraphTypes.LINE || graphType === GraphTypes.MOUNTAIN)
        ) {
            graphType = GraphTypes.DOT;
        }
        switch (graphType) {
            case GraphTypes.LINE:
                args.styles = lineStyle;
                drawLine(args);
                break;
            case GraphTypes.DASH_LINE:
                args.styles = utils.extend(lineStyle, {
                    'stroke-dasharray': 3
                });
                drawLine(args);
                break;
            case GraphTypes.MOUNTAIN:
                args.styles = lineStyle;
                drawLine(args);
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.area
                );
                drawArea(args);
                break;
            case GraphTypes.RANGE_SLIDER:
                args.styles = lineStyle;
                drawLine(args);
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.area
                );
                drawSliderArea(args);
                break;
            case GraphTypes.DOT:
                args.radius = option.graph.dot.radius;
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.dot
                );
                drawDot(args);
                break;
            case GraphTypes.EVENT:
                args.options = {
                    radius: option.graph.event.radius,
                    margin: option.graph.event.margin,
                    text: series.get('name'),
                    textStyle: styles.graph.event.text
                };
                drawEvents(args);
                selection.on('mousemove', () => {
                    this.get('front').triggerMousemove({
                        position: d3.mouse(selection[0][0]),
                        external: false,
                        fromEventGraph: true
                    });
                });
                break;
            case GraphTypes.DOT_LINE:
                args.styles = lineStyle;
                drawLine(args);
                args.radius = option.graph.dot.radius;
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.dot
                );
                drawDot(args);
                break;
            case GraphTypes.OHLC:
                args.tickSize = calculateTickSize({
                    size: option.graph.ohlc.width,
                    width: this.get('width'),
                    data: series.get('series')
                });
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.dot,
                    styles.graph.ohlc
                );
                drawOHLC(args);
                break;
            case GraphTypes.CANDLESTICK:
                args.tickSize = calculateTickSize({
                    size: option.graph.candlestick.width,
                    width: this.get('width'),
                    data: series.get('series')
                });
                args.styles = utils.extend(
                    {
                        fill: color
                    },
                    styles.graph.dot,
                    styles.graph.candlestick
                );
                drawCandlestick(args);
                break;
            case GraphTypes.VOLUME_BY_PRICE:
                args.styles = styles.graph.volumeByPrice;
                args.volumeByPriceScale =
                    option.graph.volumeByPrice.volumeByPriceScale;
                drawVolumeByPrice(args);
                break;
            case GraphTypes.BAR:
            case GraphTypes.BAR_UPDOWM:
            case GraphTypes.BAR_ABOVEBELOW:
            case GraphTypes.BAR_UPDOWN_ABOVEBELOW:
                args.upDown = series.get('isUpDown');
                args.base = series.get('baseValue');
                args.tickSize = calculateTickSize({
                    size: option.graph.bar.width,
                    width: this.get('width'),
                    data: series.get('series')
                });
                if (args.upDown) {
                    args.styles = styles.graph.bar;
                } else {
                    args.styles = utils.extend(
                        {
                            default: {
                                fill: color
                            }
                        },
                        styles.graph.bar
                    );
                }
                drawBar(args);
                break;
            case GraphTypes.ABOVEBELOW:
                args.base = series.get('baseValue');
                args.styles = styles.graph.aboveBelow;
                drawAboveBelow(args);
                break;
        }
        const tagCfg = series.get('tag') || {};
        if (tagCfg.display) {
            const tagArgs = utils.extend(true, {}, args);
            tagArgs.styles = utils.extend(true, {}, styles.tag, {
                rect: {
                    fill: color
                }
            });

            tagArgs.options = utils.extend(true, {}, option.tag, tagCfg, {
                yaxisOrient: option[`${yaxisName}axis`].orient
            });
            tagArgs.selection = this.get('tagGroup')
                .append('g')
                .classed(`${Classes.TAG}-${index}`, true);
            drawTag(tagArgs);
        }
    }
    __processData(data, graphType) {
        if (graphType === GraphTypes.EVENT) {
            return data;
        }
        const ret = [];
        let preValidItem;
        let maxValidIndex = data.length - 1;
        for (let i = data.length - 1; i >= 0; i--) {
            if (!isNaN(data[i].value)) {
                maxValidIndex = i;
                break;
            }
        }
        data.forEach((d, index) => {
            if (!isNaN(d.value)) {
                preValidItem = d;
                ret.push(d);
            } else if (preValidItem && index <= maxValidIndex) {
                ret.push(utils.extend(true, {}, preValidItem));
            } else {
                ret.push(d);
            }
        });
        return ret;
    }
    __getTickInfo(factor, [min, max]) {
        const getOffset = function(num) {
            return MATH.mod(num, factor) === 0
                ? 0
                : MATH.subtract(factor, Math.abs(MATH.mod(num, factor)));
        };
        const offsetTop = getOffset(max);
        const offsetBottom = getOffset(min);
        let adjustedMax, adjustedMin, tickCount;
        if (offsetBottom < offsetTop) {
            tickCount = 1;
            adjustedMax = adjustedMin = min;
            if (offsetBottom !== 0) {
                adjustedMin = MATH.subtract(min, offsetBottom);
            }
            while (adjustedMax < max) {
                adjustedMax = MATH.add(adjustedMin, factor * tickCount);
                tickCount++;
            }
        } else {
            adjustedMax = MATH.add(max, offsetTop);
            tickCount = 1;
            adjustedMin = adjustedMax;
            while (adjustedMin > min) {
                adjustedMin = MATH.subtract(adjustedMax, factor * tickCount);
                tickCount++;
            }
        }
        return {
            tickCount,
            adjustedMin,
            adjustedMax
        };
    }

    __getAverageDivideInfo([min, max], maxTickCount) {
        const offset = MATH.multiply(MATH.subtract(max, min), 0.01);
        const adjustedMin = MATH.subtract(min, offset);
        const adjustedMax = MATH.add(max, offset);
        const factor = MATH.divide(
            MATH.subtract(adjustedMax, adjustedMin),
            maxTickCount - 1
        );
        let decimal;
        if (factor > 0.25) {
            decimal = 2;
        } else if (factor > 0.001) {
            decimal = 3;
        } else if (factor > 0.0001) {
            decimal = 4;
        } else if (factor > 0.00001) {
            decimal = 5;
        } else {
            decimal = 6;
        }
        return {
            factor,
            decimal,
            adjustedMin,
            adjustedMax
        };
    }

    __generateTickValues([min, max], step, count) {
        const _tickValues = [];
        while (min < max) {
            _tickValues.push(min);
            min = MATH.add(min, step);
        }
        if (_tickValues.length < count) {
            _tickValues.push(max);
        }
        return _tickValues;
    }
    __caculateMaxTickCount() {
        const height = this.get('height');
        const option = this.get('option');
        const textHeight = 20;
        const tickMinHeight = option.yaxis.tickMargin * 2 + textHeight * 2;
        let maxTickCount = parseInt(height / tickMinHeight, 10) + 1;
        if (maxTickCount < 2) {
            maxTickCount = 2; //at least 2 ticks
        }
        if (
            option.yaxis.maxTickCount &&
            option.yaxis.maxTickCount < maxTickCount
        ) {
            maxTickCount = option.yaxis.maxTickCount;
        }
        return maxTickCount;
    }
    /* eslint complexity: 0 */
    /* eslint max-depth: 0 */
    __calculateYAxisInfoByAlgorithm(domain, yaxisName) {
        const _factors = [0.01, 0.015, 0.02, 0.025, 0.03, 0.04, 0.05, 0.075],
            option = this.get('option'),
            range = MATH.subtract(domain[1], domain[0]);

        const fLenth = _factors.length,
            scaleType = option[`${yaxisName}axis`].scaleType;
        let maxExponent = 20;
        let decimal = option.yaxis.decimal;
        let adjustedMax, adjustedMin, tickCount, factor, f;
        const maxTickCount = this.__caculateMaxTickCount();
        if (range === 0) {
            // max=min=factor
            factor = domain[1] === 0 ? 1 : Math.abs(domain[1]);

            let tickValues;
            if (maxTickCount < 3) {
                adjustedMax = MATH.add(domain[1], factor);
                adjustedMin = domain[0];
                tickValues = [adjustedMin, adjustedMin + factor];
            } else {
                adjustedMax = MATH.add(domain[1], factor);
                adjustedMin = MATH.subtract(domain[0], factor);
                tickValues = [adjustedMin, adjustedMin + factor, adjustedMax];
            }
            return {
                domain: [adjustedMin, adjustedMax],
                decimal,
                tickValues
            };
        } else if (range <= 0.1) {
            maxExponent = 3;
            for (let i = maxExponent; i >= 0; i--) {
                for (f = 0; f < fLenth; f++) {
                    factor = MATH.multiply(_factors[f], Math.pow(10, -i));
                    const isLastTry = !!(i === 0 && f === fLenth - 1);
                    if (
                        MATH.multiply(maxTickCount - 1, factor) >= range ||
                        isLastTry
                    ) {
                        ({
                            tickCount,
                            adjustedMin,
                            adjustedMax
                        } = this.__getTickInfo(factor, domain));
                        decimal = getNumberDecimal(factor);
                        if (tickCount <= maxTickCount || isLastTry) {
                            if (isLastTry) {
                                ({
                                    factor,
                                    adjustedMin,
                                    adjustedMax,
                                    decimal
                                } = this.__getAverageDivideInfo(
                                    domain,
                                    maxTickCount
                                ));
                                tickCount = maxTickCount;
                            }
                            return {
                                domain: [adjustedMin, adjustedMax],
                                decimal: Math.max(
                                    option.yaxis.decimal,
                                    decimal
                                ),
                                tickValues: this.__generateTickValues(
                                    [adjustedMin, adjustedMax],
                                    factor,
                                    tickCount
                                )
                            };
                        }
                    }
                }
            }
        } else {
            for (let j = 0; j <= maxExponent; j++) {
                for (f = 0; f < fLenth; f++) {
                    factor = MATH.multiply(_factors[f], Math.pow(10, j));
                    const isLastTry = !!(j === maxExponent && f === fLenth - 1);
                    if (
                        MATH.multiply(maxTickCount - 1, factor) >= range ||
                        isLastTry
                    ) {
                        ({
                            tickCount,
                            adjustedMin,
                            adjustedMax
                        } = this.__getTickInfo(factor, domain));
                        decimal = getNumberDecimal(factor);
                        if (tickCount <= maxTickCount || isLastTry) {
                            if (isLastTry) {
                                ({
                                    adjustedMin,
                                    adjustedMax,
                                    factor,
                                    decimal
                                } = this.__getAverageDivideInfo(
                                    domain,
                                    maxTickCount
                                ));
                                tickCount = maxTickCount;
                            }
                            const tickValues = this.__generateTickValues(
                                [adjustedMin, adjustedMax],
                                factor,
                                tickCount
                            );
                            if (scaleType === 'log') {
                                const isLargeSpace = function(_min, _max, x) {
                                    return (
                                        Math.log10(_max) - Math.log10(x) <
                                        Math.log10(x) - Math.log10(_min)
                                    );
                                };
                                if (
                                    adjustedMin <= 0 &&
                                    !isLargeSpace(1, factor, domain[0]) &&
                                    domain[0] > 1
                                ) {
                                    adjustedMin = 1;
                                    tickValues.splice(0, 1, 1);
                                } else if (
                                    isLargeSpace(
                                        adjustedMin,
                                        adjustedMin + factor,
                                        domain[0]
                                    ) ||
                                    adjustedMin <= 0
                                ) {
                                    adjustedMin =
                                        domain[0] <= 0
                                            ? 1
                                            : Math.max(
                                                  domain[0] * (1 - 0.01),
                                                  adjustedMin
                                              );
                                    tickValues.splice(0, 1, adjustedMin);
                                }
                            }
                            return {
                                domain: [adjustedMin, adjustedMax],
                                decimal: Math.max(
                                    option.yaxis.decimal,
                                    decimal
                                ),
                                tickValues: tickValues
                            };
                        }
                    }
                }
            }
        }
    }

    __calculateXAxisInfoByAlgorithm() {
        const dataManager = this.get('dataManager');
        const series = dataManager.series('y1');
        let xData = [];
        series.forEach(s => {
            if (s.get('series').length > xData.length) {
                xData = s.get('series');
            }
        });
        const isTimeScale = xData.filter(d => d.date).length > 0;

        if (isTimeScale) {
            return this.__calculateXAxisInfoByTimeAlgorithm(xData);
        } else {
            return this.__calculateXAxisInfoByXLabelAlgorithm(xData);
        }
    }
    /**
     * calcualte the x tick label
     * by the data
     * @param {data} xData
     */
    __calculateXAxisInfoByXLabelAlgorithm(xData) {
        const tickValues = [];
        const tickLabels = {};
        const tickSubLabels = [];

        xData.forEach((element, index) => {
            if (element.xLabel) {
                tickValues.push(element.index);
                tickLabels[index] = element.xLabel;
            }
        });
        return {
            tickValues,
            tickLabels,
            tickSubLabels,
            uniqueSubLabel: false
        };
    }
    /* eslint max-statements: 0 */
    /* eslint complexity: 0 */
    __getStepsInfo(interval, daysDiff, isMobile) {
        const d3Locale = this.get('d3Locale');
        const formatMD = d3Locale.XLabelDateTimeFormat.MonthDay,
            formatM = d3Locale.XLabelDateTimeFormat.Month,
            formatY = d3Locale.XLabelDateTimeFormat.Year,
            formatHM = d3Locale.XLabelDateTimeFormat.HourMinutes;

        const daySpanFunc = (pre, d) => {
            return (
                d.getDate() !== pre.getDate() ||
                d.getMonth() !== pre.getMonth() ||
                pre.getFullYear() !== d.getFullYear()
            );
        };
        const monthSpanFunc = function(pre, d) {
            return d.getMonth() !== pre.getMonth();
        };
        const quaterSpanFunc = function(pre, d) {
            return (
                (d.getMonth() + 1) % 3 === 1 && pre.getMonth() !== d.getMonth()
            );
        };
        const yearSpanFunc = function(pre, d) {
            return pre.getFullYear() !== d.getFullYear();
        };
        let steps = []; // all acceptable steps
        let labelFormat,
            subLabelFormat,
            validFunc,
            uniqueSubLabel = true,
            minSublabelCount = 0;
        if (interval < 1440 && daysDiff <= 3) {
            steps = [1, 2, 3, 4];
            if (daysDiff <= 1) {
                validFunc = (pre, current, index) => {
                    return index % (60 / interval) === 0;
                };
                if (isMobile && formatHM.indexOf('%p') > -1) {
                    labelFormat = formatHM.replace(/\s*%p/, '');
                    subLabelFormat = '%p';
                    uniqueSubLabel = false;
                } else {
                    labelFormat = formatHM;
                    subLabelFormat = '';
                }
            } else if (daysDiff <= 3) {
                if (isMobile && daysDiff > 2) {
                    validFunc = daySpanFunc;
                    labelFormat = formatMD;
                    subLabelFormat = formatY;
                } else {
                    validFunc = (pre, d) => {
                        const differentDay =
                            d.getDate() !== pre.getDate() ||
                            d.getMonth() !== pre.getMonth() ||
                            pre.getFullYear() !== d.getFullYear();
                        const isMidNoon =
                            !differentDay &&
                            d.getHours() === 12 &&
                            d.getMinutes() === 0;
                        return differentDay || isMidNoon;
                    };
                    labelFormat = formatHM;
                    subLabelFormat = formatMD;
                }
            }
        } else {
            interval = interval / 1440;
            validFunc = daySpanFunc;
            if (daysDiff <= 15) {
                steps = [1, 2, 3, 4, 5];
                labelFormat = formatMD;
                subLabelFormat = formatY;
            } else if (daysDiff <= 31) {
                steps = [2, 4, 5, 8];
                labelFormat = formatMD;
                if (interval > 1) {
                    steps.unshift(1);
                }
                subLabelFormat = formatY;
            } else if (daysDiff <= 61) {
                steps = [4, 7, 10, 15, 20];
                labelFormat = formatMD;
                if (interval > 1) {
                    steps.unshift(1);
                }
                subLabelFormat = formatY;
            } else if (daysDiff < 92) {
                steps = [7, 10, 15, 20];
                if (interval > 1) {
                    steps.unshift(1);
                }
                labelFormat = formatMD;
                subLabelFormat = formatY;
            } else if (daysDiff <= 240) {
                steps = [1, 2];
                validFunc = monthSpanFunc;
                labelFormat = formatM;
                subLabelFormat = formatY;
            } else if (daysDiff <= 730) {
                steps = [1, 2, 3, 4];
                validFunc = monthSpanFunc;
                labelFormat = formatM;
                subLabelFormat = formatY;
            } else if (daysDiff <= 1100) {
                steps = [1, 2, 3];
                validFunc = quaterSpanFunc;
                labelFormat = formatM;
                subLabelFormat = formatY;
            } else if (daysDiff <= 3650) {
                steps = [1, 2, 3];
                validFunc = yearSpanFunc;
                labelFormat = formatY;
                subLabelFormat = '';
            } else if (daysDiff <= 7300) {
                steps = [2, 4, 5];
                validFunc = yearSpanFunc;
                labelFormat = formatY;
                subLabelFormat = '';
            } else {
                steps = [5, 8, 10];
                validFunc = yearSpanFunc;
                labelFormat = formatY;
                subLabelFormat = '';
            }
            if (labelFormat === formatM) {
                minSublabelCount = 1;
            }
        }
        return {
            validFunc,
            labelFormat,
            steps,
            subLabelFormat,
            uniqueSubLabel,
            minSublabelCount
        };
    }

    __getDaysDiff(xData, interval) {
        if (interval > 60) {
            return (
                (new Date(xData[xData.length - 1].date).getTime() -
                    new Date(xData[0].date).getTime()) /
                (60 * 24 * 60 * 1000)
            );
        }
        let count = 0;
        let preDay = '';
        xData.forEach(item => {
            const date = new Date(item.date);
            const day = `${date.getFullYear()}-${date.getMonth() +
                1}-${date.getDate()}`;
            if (preDay !== day) {
                preDay = day;
                count++;
            }
        });
        return count;
    }
    __getValidDate(date) {
        if (
            typeof date === 'string' &&
            /^(\d{4})-?(\d{2})-?(\d{2})$/.test(date)
        ) {
            date = date.replace(
                /^(\d{4})-?(\d{2})-?(\d{2})$/,
                '$1-$2-$3T00:00:00'
            );
        }
        return new Date(date);
    }
    __calculateXAxisInfoByTimeAlgorithm(xData) {
        const option = this.get('option');
        const d3Locale = this.get('d3Locale');
        const width = this.get('width');
        const isMobile = width <= option.breakpoint;
        let minWidth = 60;
        if (isMobile) {
            minWidth = 40;
        }
        const size = xData.length;
        if (xData.length === 1) {
            // only one data point
            return {
                tickValues: [0],
                tickLabels: {
                    '0': d3Locale.timeFormat(
                        d3Locale.XLabelDateTimeFormat.MonthDay
                    )(new Date(xData[0].date))
                },
                tickSubLabels: [
                    d3Locale.timeFormat(d3Locale.XLabelDateTimeFormat.Year)(
                        new Date(xData[0].date)
                    )
                ]
            };
        }
        // time interval beteen two data points in minutes

        const interval =
            (new Date(xData[1].date).getTime() -
                new Date(xData[0].date).getTime()) /
            (60 * 1000);
        const daysDiff = this.__getDaysDiff(xData, interval);
        const {
            steps,
            labelFormat,
            subLabelFormat,
            validFunc,
            uniqueSubLabel,
            minSublabelCount
        } = this.__getStepsInfo(interval, daysDiff, isMobile);
        const indexArray = [];
        let pre = this.__getValidDate(xData[0].date),
            label;
        for (let i = 0; i < size; i++) {
            const current = this.__getValidDate(xData[i].date);
            if (validFunc(pre, current, i) || i === 0) {
                pre = current;
                label = d3Locale.timeFormat(labelFormat)(current);
                if (/^%I:%M/.test(labelFormat)) {
                    // need to remove the first 0
                    label = label.replace(/(^0)(.+)/gi, '$2');
                }
                indexArray.push({
                    d: xData[i],
                    text: label,
                    subText: subLabelFormat
                        ? d3Locale.timeFormat(subLabelFormat)(current)
                        : ''
                });
            }
        }

        const styles = option.styles.axes;
        const tickSize = getGreaterSVGTextWidth(
            this.get('svg'),
            indexArray,
            utils.extend({}, styles.default.text, styles.x.text)
        );
        const maxCount = Math.floor(
            this.get('width') / Math.max(tickSize + 5, minWidth)
        );
        const step = this.__generateStep(steps, indexArray.length, maxCount);
        const tickValues = [];
        const tickLabels = {};
        const tickSubLabels = [];
        for (let i = 0; i < indexArray.length; i += step) {
            const item = indexArray[i];
            const idx = item.d.index;
            tickValues.push(idx);
            tickLabels[idx] = item.text;
            tickSubLabels.push(item.subText);
        }
        return {
            tickSize,
            tickValues,
            tickLabels,
            tickSubLabels,
            uniqueSubLabel,
            minSublabelCount
        };
    }

    __generateStep(steps, validDatesLenth, maxTickCount) {
        for (let i = 0; i < steps.length; i++) {
            if (validDatesLenth / steps[i] <= maxTickCount) {
                return steps[i];
            }
        }
        return validDatesLenth;
    }
}
