import { EVENTS } from './constants';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export default class Mixin {
    constructor() {
        this._watchers = {};
        this._eventMap = {};
    }
    get(name) {
        return this[`_${name}`];
    }
    set(name, value) {
        this[`_${name}`] = value;
        this.__checkWatcher(name, value);
        return this;
    }
    watch(attr, handler) {
        const watcher = this._watchers[attr];
        if (watcher) {
            const handlers = watcher.handlers;
            const length = handlers.filter(fn => {
                return `${fn}` === `${handler}`;
            }).length;
            if (!length) {
                handlers.push(handler);
            }
        } else {
            const value = this.get(attr);
            this._watchers[attr] = {
                oldValue: value,
                handlers: [handler]
            };
        }
        return this;
    }
    unwatch(attr, handler) {
        const watcher = this._watchers[attr];
        if (watcher) {
            if (!handler) {
                delete this._watchers[attr];
            } else {
                const handlers = watcher.handlers;
                handlers.splice(handlers.indexOf(handler), 1);
            }
        }
        return this;
    }
    unwatchAll() {
        this._watchers = {};
        return this;
    }
    on(event, callback) {
        if (utils.inArray(Object.values(EVENTS), event) > -1) {
            this._eventMap[event] = this._eventMap[event] || [];
            this._eventMap[event].push(callback);
        }
    }
    trigger(event, ...params) {
        if (this._eventMap[event]) {
            for (let i = 0; i < this._eventMap[event].length; i++) {
                if (utils.isFunction(this._eventMap[event][i])) {
                    this._eventMap[event][i](...params);
                }
            }
        }
    }
    __checkWatcher(attr, newValue) {
        const watcher = this._watchers[attr];
        if (watcher && newValue !== watcher.oldValue) {
            watcher.handlers.forEach(function(handler) {
                handler(newValue, watcher.oldValue);
                watcher.oldValue = newValue;
            });
        }
    }
}
