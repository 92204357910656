import Mixin from './mixin';
import d3 from 'd3';
import { Classes, MDS, Positions } from './constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;

export default class Tooltip extends Mixin {
    constructor(options, svgWrap) {
        super();
        const defaultConfig = {
            template: d => this.__defaultTemplateRender(d),
            delay: 250,
            transitionDuration: 20,
            opacity: 0.95,
            transition: false,
            width: 200,
            skin: 'default',
            arrowHeight: 8,
            allowPositions: ['right', 'left', 'bottom', 'top'],
            priority: ['bottom', 'right', 'top', 'left'],
            positionPriority: {
                bottom: ['right', 'left', 'center'],
                right: ['center', 'bottom', 'top'],
                top: ['right', 'left'],
                left: ['center', 'bottom', 'top']
            },
            directionPriority: {
                bottom: ['right', 'left', 'center'],
                right: ['bottom', 'top', 'center'],
                top: ['left', 'center', 'right'],
                left: ['top', 'center', 'bottom']
            }
        };
        options = options || {};
        this._skin = options.skin;
        this._options = utils.extend(true, {}, defaultConfig, options);
        this._template = this._options.template;
        this.positionInfo = null;
        this.targetRect = {};
        this._tooltipClass = Classes.TOOLTIP_CONTAINER;
        this.rect = {};
        this._svgWrap = svgWrap;
        this.namespace = utils.namespace('plotter-tooltip', true);
        this.createTooltip();
        this.__setStyles(this._skin);
        this._visible = false;
        //add skin change handler
        this.watch('skin', (value, oldValue) => {
            this.__setStyles(value, oldValue);
        });
    }
    /**
     * Template getter/setter.
     * @param template the template to set, if string it is considered
     * static template, if function it is considered dynamic template
     * and will be invoked with the associated data as parameter.
     */
    template(template) {
        if (template) {
            this._template = template;
        }
        return this._template;
    }
    /**
     * Hide the tooltip.
     */
    hide() {
        if (!this._tooltipEl && this._visible === false) {
            return;
        }
        this._visible = false;
        this._tooltipEl.classed(Classes.POPOVER_VISIBLE, false);
    }
    /**
     * Create a tooltip.
     */
    createTooltip() {
        if (!this._tooltipEl) {
            this._tooltipEl = this.__initTooltip();
        }
        const options = this._options;
        if (options.transition) {
            this._tooltipEl
                .style({
                    opacity: 0,
                    display: 'inline-block',
                    left: '-10000px',
                    top: '-10000px'
                })
                .transition()
                .duration(options.transitionDuration)
                .delay(options.delay)
                .style({
                    opacity: options.opacity
                });
        } else {
            this._tooltipEl.style({
                opacity: options.opacity,
                display: 'inline-block',
                left: '-10000px',
                top: '-10000px'
            });
        }
    }
    /**
     * Show the tooltip at mouse position.
     */
    show(data, { element, position }) {
        if (!this._tooltipEl) {
            return;
        }
        this._visible = true;
        const html = this._template(data);
        this._tooltipEl.html(html);
        this.containerRect = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            left: 0,
            top: 0
        };
        this.targetRect = {};
        this.rect = utils.outerSizes(this._tooltipEl.node(), true);
        if (element) {
            this.targetRect = this.__getTargetPosition(element);
            const isElement =
                element instanceof HTMLElement || element instanceof SVGElement;
            if (isElement) {
                this.targetRect = utils.outerSizes(element, true);
            }
        }

        if (position) {
            const _svgEl = utils.outerSizes(this._svgWrap.node(), true);
            this.targetRect.height = 8;
            this.targetRect.width = 8;
            this.targetRect.top =
                position[1] + _svgEl.top - this.targetRect.height / 2;
            this.targetRect.left =
                position[0] + _svgEl.left + this.targetRect.width / 2;
        }

        this.positionInfo = this.getPositionInfo();
        const formatPx = function(value) {
            return `${value}px`;
        };
        const classes = {};
        [
            'bottom-left',
            'bottom-right',
            'top-left',
            'top-right',
            'left-top',
            'bottom-center',
            'top-center',
            'left-center',
            'right-center'
        ].forEach(key => {
            classes[utils.moduleClassName(`mds-popover--${key}`)] = false;
        });

        this._tooltipEl
            .style({
                position: 'absolute',
                left: formatPx(this.positionInfo.left),
                top: formatPx(this.positionInfo.top)
            })
            .classed(classes)
            .classed(
                utils.moduleClassName(
                    `mds-popover--${this.positionInfo.type}-${this.positionInfo.direction}`
                ),
                true
            )
            .classed(Classes.POPOVER_VISIBLE, true);
    }

    /**
     * Get position info.
     * @returns {*} the best position info.
     */
    getPositionInfo() {
        const positionsList = this._options.allowPositions.map(position => {
            const { targetRect, containerRect } = this;
            const info = {
                type: position,
                targetRect,
                containerRect,
                width: this.rect.width,
                height: this.rect.height,
                top: 0,
                left: 0,
                blank: 0
            };
            this[`${position}Handler`](info);
            info.inner =
                info.left >= info.containerRect.left &&
                info.top >= info.containerRect.top &&
                info.left + info.width <=
                    info.containerRect.left + info.containerRect.width &&
                info.top + info.height <=
                    info.containerRect.top + info.containerRect.height;
            return info;
        });
        const prePosition = this.positionInfo ? this.positionInfo.type : '';
        return this.calculateBestPositionInfo(
            positionsList,
            this.priority,
            prePosition
        );
    }

    /**
     * Calculate best position info.
     * @param positions - The positions.
     * @param positionPriority - The position priority.
     * @param prePosition - The previous position.
     * @param directionPriority - The direction priority.
     * @returns {*} the best position info.
     */
    calculateBestPositionInfo(
        positions,
        positionPriority,
        prePosition,
        directionPriority
    ) {
        if (positionPriority && positionPriority.length) {
            this.orderByPriority(positions, positionPriority);
        }
        const innerPositions = positions.filter(position => position.inner);
        if (innerPositions.length) {
            return this.getBestPosition(innerPositions, prePosition);
        } else {
            return this.getBestPositionByBlank(positions, directionPriority);
        }
    }

    /**
     * Get the best direction.
     * @param directions - The directions.
     * @param info
     * @returns {*} the best position info.
     */
    getBestDirection(directions, info) {
        const positionPriority = this._options.positionPriority[info.type];
        const directionPriority = this._options.directionPriority[info.type];
        const prePosition =
            this.positionInfo && this.positionInfo.type === info.type
                ? this.positionInfo.direction
                : '';
        return this.calculateBestPositionInfo(
            directions,
            positionPriority,
            prePosition,
            directionPriority
        );
    }

    /**
     * Order the positions by position priority.
     * @param positions - Need sort positions.
     * @param positionPriority - The position priority.
     * @returns {*} sorted positions.
     */
    orderByPriority(positions, positionPriority) {
        positions.sort((a, b) => {
            return (
                this.getPriorityIndex(positionPriority, a.type) -
                this.getPriorityIndex(positionPriority, b.type)
            );
        });
        return positions;
    }

    /**
     * Get the priority index of position type.
     * @param positionPriority - The position priority.
     * @param positionType - The type of position.
     * @returns {number}  the priority index.
     */
    getPriorityIndex(positionPriority, positionType) {
        const priorityIndex = 0;
        for (let i = priorityIndex; i < positionPriority.length; i++) {
            if (positionPriority[i] === positionType) {
                return i;
            }
        }
        return priorityIndex;
    }

    /**
     * Get the best position.
     * @param positions - The positions.
     * @param prePosition - The prePosition.
     * @returns {*}  best position.
     */
    getBestPosition(positions, prePosition) {
        if (positions.length <= 1 || !prePosition) {
            return positions[0];
        }
        // If  we can find a position same as previous position.
        const bestPosition = positions.filter(
            position => position.type === prePosition
        )[0];
        if (bestPosition) {
            return bestPosition;
        } else {
            return positions[0];
        }
    }

    /**
     * Get the best position by blank.
     * @param positions - The positions.
     * @param priority - The priority of position.
     * @returns {*} best position.
     */
    getBestPositionByBlank(positions, priority) {
        if (priority && priority.length) {
            this.orderByPriority(positions, priority);
        }
        const bestDirections = positions.filter(
            position => position.blank >= 0
        );
        if (bestDirections.length) {
            return bestDirections[0];
        } else {
            return this.getBestPositionByDistance(positions);
        }
    }

    /**
     * Get the best position by distance.
     * @param positions - The positions.
     * @returns {*} best position.
     */
    getBestPositionByDistance(positions) {
        positions.sort((a, b) => {
            return b.blank - a.blank;
        });
        return positions[0];
    }

    /**
     * Get vertical arrow position.
     * @param info
     */
    getVerticalArrowPosition(info) {
        const directions = ['top', 'center', 'bottom'];
        const directionCalculator = this.__directionsCalculateGenerator(
            directions
        );
        const directionList = this.__getDirectionList(
            info,
            directions,
            directionCalculator,
            'top'
        );
        const bestDirection = this.getBestDirection(directionList, info);
        info.top = bestDirection.top;
        info.direction = bestDirection.type;
    }
    /**
     * Get horizontal arrow position.
     * @param info
     */
    getHorizontalArrowPosition(info) {
        const directions = ['left', 'center', 'right'];
        const directionCalculator = this.__directionsCalculateGenerator(
            directions
        );
        const directionList = this.__getDirectionList(
            info,
            directions,
            directionCalculator,
            'left'
        );
        const bestDirection = this.getBestDirection(directionList, info);
        info.left = bestDirection.left;
        info.direction = bestDirection.type;
    }

    /**
     * Destroy the tooltip.
     * @returns {Tooltip}
     */
    destroy() {
        this.unwatchAll();
        this._tooltipEl.classed(Classes.POPOVER_VISIBLE, false);
        return this;
    }
    leftHandler(info) {
        info.width += this._options.arrowHeight;
        info.left = info.targetRect.left - info.width;
        info.blank = info.left - info.containerRect.left;
        this.getVerticalArrowPosition(info);
    }
    rightHandler(info) {
        info.width += this._options.arrowHeight;
        info.left = info.targetRect.left + info.targetRect.width;
        info.blank =
            info.containerRect.left +
            info.containerRect.width -
            (info.left + info.width);
        this.getVerticalArrowPosition(info);
    }
    bottomHandler(info) {
        info.height += this._options.arrowHeight;
        info.top = info.targetRect.top + info.targetRect.height;
        info.blank =
            info.containerRect.top +
            info.containerRect.height -
            (info.top + info.height);
        this.getHorizontalArrowPosition(info);
    }
    topHandler(info) {
        info.height += this._options.arrowHeight;
        info.top = info.targetRect.top - info.height;
        info.blank = info.top - info.containerRect.top;
        this.getHorizontalArrowPosition(info);
    }

    /**
     * Default template render.
     * @param data - The chart data.
     * @returns {string} - The tooltip html.
     * @private
     */
    __defaultTemplateRender(data) {
        const tableClass = `${utils.moduleClassName(
            'mds-data-table'
        )} ${this.namespace('table')}`;
        const tbodyClass = `${utils.moduleClassName(
            'mds-data-table__body'
        )} ${this.namespace('table__body')}`;

        const trClass = `${utils.moduleClassName(
            'mds-data-table__row'
        )} ${this.namespace('__row')}`;

        const tdClass = `${utils.moduleClassName(
            'mds-data-table__cell'
        )} ${this.namespace('__cell')}`;
        const tdNameClass = `${tdClass} ${this.namespace('__cell-name')}`;
        const tdIconClass = `${tdClass} ${this.namespace('__cell-line')}`;
        const tdDataClass = `${utils.moduleClassName(
            'mds-data-table__cell table__cell--right'
        )} ${this.namespace('__cell')} ${this.namespace('__cell-data')}`;
        const tdLineInnerClassPrefix = `${this.namespace(
            '__cell-line--inner'
        )} ${this.namespace('__cell-line')}-`;

        let templateHtml = '';
        data.forEach((item, index) => {
            const colorIndex =
                (index % Object.keys(MDS['chart-color']).length) + 1;
            const color = item.color || MDS['chart-color'][colorIndex];

            const tdLineInnerClass = tdLineInnerClassPrefix + index;
            templateHtml += `<tr class="${trClass}">
                        <td class="${tdIconClass}">
                            <div class="${tdLineInnerClass}" style="background-color:${color}"></div>
                        </td>`;
            if (item.name) {
                templateHtml += `<td class="${tdNameClass}">${item.name}</td>`;
            }
            templateHtml += this.__getFieldsHtml(item, tdDataClass);
            templateHtml += '</tr>';
        });
        return `<div class="${this.__getTooltipClass()}">
                            ${this.__getCaptionHtml(data)}
                            <table class="${tableClass}">
                                <tbody class="${tbodyClass}" >
                                    ${templateHtml}
                                </tbody>
                            </table>
                        </div>`;
    }
    __getFieldsHtml(data, dataClass) {
        let fieldsHtml = '';
        let fields = [];
        if (this._options && this._options.fields) {
            fields = utils.isFunction(this._options.fields)
                ? this._options.fields(data)
                : this._options.fields;
        }
        fields.forEach(field => {
            fieldsHtml += `<td class="${dataClass}">
                            ${field}
                        </td>`;
        });
        return fieldsHtml;
    }
    __getCaptionHtml(data) {
        let captionHtml = '';
        const captionClass = `${this.namespace('__caption')}`;
        let caption = '';
        if (this._options && this._options.caption) {
            caption = utils.isFunction(this._options.caption)
                ? this._options.caption(data)
                : this._options.caption;
        }
        if (caption) {
            captionHtml = `<div class="${captionClass}">${caption}</div>`;
        }
        return captionHtml;
    }
    __getTooltipClass() {
        let cls = this.namespace();
        if (
            this._options &&
            this._options.namespace &&
            typeof this._options.namespace === 'string'
        ) {
            cls = `${cls} ${this._options.namespace}-tooltip`;
        }
        return cls;
    }
    __initTooltip() {
        let tooltipEl = d3.select(`.${this._tooltipClass}`);
        if (tooltipEl.empty()) {
            tooltipEl = this.__createTooltip();
        }
        return tooltipEl;
    }
    __setStyles(value, oldValue = '') {
        const oldSkinSuffix = utils.getSkinSuffix(oldValue);
        const skinSuffix = utils.getSkinSuffix(value);
        this._tooltipEl
            .classed(`${this._tooltipClass}--${oldSkinSuffix}`, false)
            .classed(`${this._tooltipClass}--${skinSuffix}`, true);
    }
    __createTooltip() {
        return d3
            .select('body')
            .append('div')
            .attr('class', this._tooltipClass)
            .classed(`${utils.moduleClassName('mds-popover')}`, true)
            .classed(
                utils.moduleClassName(
                    `mds-popover--width-${this._options.width}px`
                ),
                true
            )
            .classed(Classes.POPOVER_VISIBLE, true)
            .style('display', 'none');
    }
    __getTargetPosition(position) {
        const svgEl = this._svgWrap.node();
        const _svgEl = utils.outerSizes(svgEl, true);
        const d3MousePosition = d3.mouse(svgEl);

        const margin = position !== Positions.TOP ? 0 : 20;
        const left = d3MousePosition[0] + _svgEl.left + 8;
        const top = d3MousePosition[1] + _svgEl.top - margin;
        return {
            left,
            top,
            width: 3,
            height: 5
        };
    }
    __directionsCalculateGenerator(directions) {
        const calculator = {};
        let attr = '';
        directions.map(d => {
            switch (d) {
                case 'center':
                    calculator[d] = info => {
                        return (
                            info.targetRect.top +
                            info.targetRect.height * 0.5 -
                            info.height * 0.5
                        );
                    };
                    break;
                case 'bottom':
                    calculator[d] = info => {
                        return (
                            info.targetRect.top + info.targetRect.height * 0.5
                        );
                    };
                    break;
                case 'right':
                    calculator[d] = info => {
                        return (
                            info.targetRect.left + info.targetRect.width * 0.5
                        );
                    };
                    break;
                case 'top':
                case 'left':
                    calculator[d] = info => {
                        attr = d === 'left' ? 'width' : 'height';
                        return (
                            info.targetRect[d] +
                            info.targetRect[attr] * 0.5 -
                            info[attr]
                        );
                    };
                    break;
            }
        });
        return calculator;
    }
    __getDirectionList(info, directions, calculator, alignDirection) {
        return directions.map(direction => {
            const d = {
                type: direction,
                [alignDirection]: calculator[direction](info)
            };
            d.blank = d[alignDirection] - info.containerRect[alignDirection];
            d.inner =
                d[alignDirection] >= info.containerRect[alignDirection] &&
                d[alignDirection] + info.height <=
                    info.containerRect[alignDirection] +
                        info.containerRect.height;
            return d;
        });
    }
}
