import { locales } from './locale';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
import d3 from 'd3';

export default function getD3Locale(locale) {
    const localeObject = {};
    let specifier;
    if (locales[locale]) {
        specifier = locales[locale];
    } else {
        const lang = locale.split('-')[0];
        specifier = locales[lang] || locales['en'];
    }
    const d3Locale = d3.locale(specifier);

    localeObject.timeFormat = template => {
        if (typeof template === 'function') {
            return template;
        } else {
            template = specifier[template] || template;
            return d3Locale.timeFormat(template);
        }
    };
    localeObject.numberFormat = d3Locale.numberFormat;
    localeObject.XLabelDateTimeFormat = utils.extend(
        true,
        {},
        specifier.XLabelDateTimeFormat
    );
    return localeObject;
}
