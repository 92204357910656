import Mixin from './mixin';
import { Classes } from './constants';
import d3 from 'd3';
export default class Grid extends Mixin {
    /**
     * @param {D3 selection} container of the legend,  Required.
     */
    constructor(selection, option) {
        super();
        this._el = selection;
        this._screenMinHeight = 100;
        this._scales = null;
        this._ticks = null;
        this._option = option;
        this._skin = option.skin;
        this.watch('skin', (value, oldValue) => {
            const el = this.get('el');
            const rects = el.selectAll('rect')[0];
            this.__setXRectStyle(rects);
            this.__setLineStyles(el.selectAll('line')[0]);
        });
    }
    update() {
        this.destroy();
        if (this._option.gridY.showRect) {
            this.__drawYRects();
        }
        if (this._option.gridY.showLine) {
            this.__drawYLines();
        }
        if (this._option.gridX.showLine) {
            this.__drawXLines();
        }
    }
    destroy() {
        this.get('el')
            .selectAll('*')
            .remove();
    }
    __getXLinePositions() {
        const scales = this.get('scales');
        const ticksManager = this.get('ticks');
        const xScale = scales.scale('x');
        const xTicks = ticksManager.ticks('x');
        if (!xTicks) {
            return null;
        }
        const xTickValues = xTicks.get('values');
        const cWidth = xScale.range()[1];
        const xArray = [];
        let xOffset = this.get('xOffset');
        xOffset = xOffset > 0 ? xOffset : 0;
        xTickValues.forEach((d, i) => {
            const x = xScale(d - xOffset);
            if (x < cWidth) {
                xArray.push(x);
            }
        });
        xArray.push(cWidth);
        return xArray;
    }
    __drawYRects() {
        const scales = this.get('scales');
        const y1Scale = scales.scale('y1');
        const xArray = this.__getXLinePositions();
        if (!xArray) {
            return;
        }
        const rects = [];
        const scaleY = this._top ? this._top : 0;
        for (let i = 0; i < xArray.length - 1; i++) {
            const x = xArray[i];
            rects.push(
                this.get('el')
                    .append('rect')
                    .attr({
                        class: Classes.GRID_BAR,
                        x: x,
                        y: scaleY,
                        height: y1Scale.range()[0]
                    })
            );
        }
        rects.forEach((rect, i) => {
            rect.attr('width', xArray[i + 1] - xArray[i]);
        });
        this.__setXRectStyle(rects);
    }
    __drawYLines() {
        const scales = this.get('scales');
        const y1Scale = scales.scale('y1');
        const xArray = this.__getXLinePositions();
        if (!xArray) {
            return;
        }
        xArray.pop();
        const xFn = d => {
            return d;
        };
        const yRange = y1Scale.range();
        const lines = this.get('el')
            .selectAll(`.${Classes.GRID_LINE_Y}`)
            .data(xArray)
            .enter()
            .append('line')
            .attr('class', `${Classes.GRID_LINE} ${Classes.GRID_LINE_Y}`)
            .attr('x1', xFn)
            .attr('x2', xFn)
            .attr('y1', yRange[0])
            .attr('y2', yRange[1]);
        this.__setLineStyles(lines[0], 'X');
    }
    __drawXLines() {
        const scales = this.get('scales');
        const ticksManager = this.get('ticks');
        const xScale = scales.scale('x');
        const y1Scale = scales.scale('y1');
        const y1Ticks = ticksManager.ticks('y1');

        if (!y1Ticks) {
            return;
        }
        const scaleY = this._top ? this._top : 0;

        const y1TickValues = y1Ticks.get('values');
        const cHeight = y1Scale.range()[0];
        const isSmallHeight = cHeight <= this.get('screenMinHeight');

        const lineClass = (d, i) => {
            let extraClass = '';
            if (i === 0) {
                extraClass = Classes.GRID_LINE_FIRST;
            } else if (i === y1TickValues.length - 1) {
                extraClass = Classes.GRID_LINE_LAST;
            }
            return `${Classes.GRID_LINE} ${Classes.GRID_LINE_X} ${extraClass}`;
        };
        const yFn = d => {
            return y1Scale(d) + scaleY;
        };
        const opacity = (d, i) => {
            return !isSmallHeight || i === 0 || i === y1TickValues.length - 1
                ? 1
                : 0;
        };

        const lines = this.get('el')
            .selectAll(`.${Classes.GRID_LINE_X}`)
            .data(y1TickValues)
            .enter()
            .append('line')
            .attr('class', lineClass)
            .attr('x1', xScale.range()[0])
            .attr('x2', xScale.range()[1])
            .attr('y1', yFn)
            .attr('y2', yFn)
            .attr('opacity', opacity);

        this.__setLineStyles(lines[0], 'Y');
    }
    __setLineStyles(lines, type) {
        // set grid line inline style
        const option = this.get('option');
        const lineStyles = option.styles.line;
        lines.forEach((line, i) => {
            const d3_line = d3.select(line);
            if (type === 'Y' && i === 0) {
                d3_line.style(lineStyles.firstY);
            } else if (type === 'Y' && i === lines.length - 1) {
                d3_line.style(lineStyles.lastY);
            } else {
                d3_line.style(lineStyles.default);
            }
        });
    }
    __setXRectStyle(rects) {
        const option = this.get('option');
        rects.forEach((rect, i) => {
            const attrName = i % 2 === 0 ? 'odd' : 'even';
            const styles = option.styles.rect[attrName];
            if (typeof rect.style !== 'function') {
                d3.select(rect).style(styles);
            } else {
                rect.style(styles);
            }
        });
    }
}
