import MDSConstants from '@mds/constants/dist/constants.json';

const namespace = 'mwc-markets-plotter';
const namespaceGrid = `${namespace}-grid`;
const namespaceGraph = `${namespace}-graph`;
const namespaceFront = `${namespace}-front`;
const namespaceLegend = `${namespace}-legend`;
const namespaceGridLine = `${namespaceGrid}-line`;
const namespaceTick = `${namespace}-tick`;

export const MDS = MDSConstants.mds_constants;
export const OFFSET = 0.5;
export const FIRST_CHILD = ':first-child';
export const Classes = {
    AXIS: `${namespace}-axis`,
    AXES: `${namespace}-axes`,
    BACK: `${namespace}-back`,
    CANVAS: `${namespace}-canvas`,
    CANVAS_WRAPPER: `${namespace}-canvas-wrapper`,
    FRONT: namespaceFront,
    FRONT_HIGHLIGHT: `${namespaceFront}-highlight`,
    FRONT_TRACKBALL: `${namespaceFront}-trackball`,
    GRAPH: namespaceGraph,
    GRAPH_ABOVEBELOW: `${namespaceGraph}-abovebelow`,
    GRAPH_VOLUME_BY_PRICE: `${namespaceGraph}-volumeByPrice`,
    GRAPH_AREA: `${namespaceGraph}-area`,
    GRAPH_BAR: `${namespaceGraph}-bar`,
    GRAPH_COLOR: `${namespaceGraph}-color`,
    GRAPH_CANDLESTICK: `${namespaceGraph}-candlestick`,
    GRAPH_DOT: `${namespaceGraph}-dot`,
    GRAPH_LINE: `${namespaceGraph}-line`,
    GRAPH_MOUNTAIN: `${namespaceGraph}-mountain`,
    GRAPH_OHLC: `${namespaceGraph}-ohlc`,
    GRID_BAR: `${namespaceGrid}-bar`,
    GRID_LINE: `${namespaceGrid}-line`,
    GRID_LINE_X: `${namespaceGrid}-line-x`,
    GRID_LINE_Y: `${namespaceGrid}-line-y`,
    GRID_LINE_FIRST: `${namespaceGridLine}-first`,
    GRID_LINE_LAST: `${namespaceGridLine}-last`,
    LAYERS: `${namespace}-layers`,
    LEGEND: `${namespaceLegend}`,
    LEGEND_ITEM: `${namespaceLegend}-item`,
    LEGEND_LINE: `${namespaceLegend}-line`,
    LEGEND_TEXT: `${namespaceLegend}-text`,
    LEGEND_BUTTON: `${namespaceLegend}-button`,
    NAMESPACE: namespace,
    TICK_ABBREVIATION: `${namespaceTick}-abbreviation`,
    TICK_FIRST: `${namespaceTick}-first`,
    TICK_LAST: `${namespaceTick}-last`,
    TOOLTIP_CONTAINER: `${namespace}-tooltip-container`,
    POPOVER_VISIBLE: `${namespace}-popover__visible`,
    EVENTS: `${namespace}-events`,
    EVENTS_GRAPH: `${namespace}-events-graph`,
    HIGHLIGHT_LINE: `${namespace}-highlight-line`,
    TAG: `${namespace}-tag`,
    CLOSE: `${namespace}-close`,
    HIGHLIGHT_TAG: `${namespace}-highlight-tag`,
    ZOOM: `${namespace}-zoom`,
    GRAB: `${namespace}-grab`,
    ZOOM_IN: `${namespace}-zoom-in`,
    ZOOM_OUT: `${namespace}-zoom-out`,
    ZOOM_BRUSH: `${namespace}-zoom-brush`,
    SKIN_WHITE: `${namespace}--white`,
    SKIN_DARK: `${namespace}--dark-gray`,
    TREND_LINE: `${namespace}-trend-line`,
    LEVEL_LINE: `${namespace}-level-line`,
    RECTANGLE: `${namespace}-rectangle`,
    ELLIPSE: `${namespace}-ellipse`,
    FIB_TIMEZONE: `${namespace}-fib-timezone`,
    FIB_TIMEZONE_LINES: `${namespace}-fib-timezone-lines`,
    FIB_FANS: `${namespace}-fib-fans`,
    FIB_LEVEL: `${namespace}-fib-level`,
    FIB_ARCS: `${namespace}-fib-arcs`,
    DRAWING: `${namespace}-drawing`,
    DRAWING_TEXT: `${namespace}-drawing-text`,
    DRAWING_CALL_OUT: `${namespace}-call-out`,
    HIDDEN: `${namespace}--hidden`,
    CIRCLE_HIGHLIGHT: `${namespace}-circle-highlight`,
    DRAWING_HIGHLIGHT: `${namespace}-drawing-highlight`,
    DRAWING_MASK: `${namespace}-drawing-mask`,
    DRAWING_CONTAINER: `${namespace}-drawing-container`,
    DRAWING_POINT: `${namespace}-drawing-point`,
    DRAWING_POINT_START: `${namespace}-drawing-point--start`,
    DRAWING_POINT_END: `${namespace}-drawing-point--end`,
    DRAWING_TAG: `${namespace}-drawing-tag`,
    DRAWING_RECT_LINE: `${namespace}-drawing-rect-line`,
    RANGE_SLIDER: `${namespace}-range-slider`
};

export const Positions = {
    NONE: '',
    TOP: 'top',
    RIGHT: 'right'
};

export const GraphTypes = {
    ABOVEBELOW: 'aboveBelow',
    AREA: 'area',
    BAR: 'bar',
    BAR_UPDOWM: 'barUpDown',
    BAR_ABOVEBELOW: 'barAboveBelow',
    BAR_UPDOWN_ABOVEBELOW: 'barUpDownAbove',
    CANDLESTICK: 'candlestick',
    DOT: 'dot',
    DOT_LINE: 'dotline',
    HLC: 'hlc',
    LINE: 'line',
    MOUNTAIN: 'mountain',
    OHLC: 'ohlc',
    DASH_LINE: 'dashline',
    VOLUME_BY_PRICE: 'volumeByPrice',
    EVENT: 'event',
    RANGE_SLIDER: 'rangeSlider'
};

export const EVENTS = {
    YaxisWidthChanged: 'yaxisWidthChanged',
    Mousemove: 'mousemove',
    Mouseleave: 'mouseleave',
    ShowTips: 'showTips',
    HideTips: 'hideTips',
    HighlightHoveredLine: 'highlightHoveredLine',
    DomainChanged: 'domainChanged',
    DrawEnd: 'drawEnd',
    Resize: 'resize'
};

export const ZOOM_EVENT = {
    ZOOM_IN: 'zoomIn',
    ZOOM_OUT: 'zoomOut',
    PAN: 'pan'
};

export const DRAWINGS = {
    TREND_LINE: 'trendLine',
    LEVEL_LINE: 'levelLine',
    TEXT: 'text',
    CALL_OUT: 'callOut',
    RECTANGLE: 'rectangle',
    ELLIPSE: 'ellipse',
    FIB_TIMEZONE: 'fibTimezone',
    FIB_LEVEL: 'fibRetracementLevels',
    FIB_FANS: 'fibFans',
    FIB_ARCS: 'fibArcs'
};
