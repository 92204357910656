import d3 from 'd3';
import { Classes } from '../constants';

export default function drawLine({
    selection,
    xScale,
    xValue,
    yValue,
    ySlider,
    yScale,
    data,
    valid,
    className = '',
    styles,
    type
} = {}) {
    const line = d3.svg
        .line()
        .x(function(d) {
            return xScale(xValue(d));
        })
        .y(function(d) {
            return type === 'rangeSlider'
                ? yScale(ySlider(d))
                : yScale(yValue(d));
        })
        .defined(valid);

    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_LINE} ${className}`);
    target
        .append('path')
        .attr('d', line(data.filter(d => valid(d))))
        .style(styles);

    return target;
}
