import d3 from 'd3';
import { Classes } from '../constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;
export default function drawAboveBelow({
    selection,
    xScale,
    xValue,
    yValue,
    yScale,
    yScaleHeight,
    data,
    base,
    valid,
    className = '',
    styles
} = {}) {
    const width = xScale.range()[1];
    const height = yScaleHeight.range()[0];
    const baseY = yScale(base);
    const abArea = d3.svg
        .area()
        .x(function(d) {
            return xScale(xValue(d));
        })
        .y1(function(d) {
            return yScale(yValue(d));
        })
        .defined(valid);

    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_ABOVEBELOW} ${className}`);

    const aboveClipId = `clip-above-${utils.guid()}`;
    const belowClipId = `clip-below-${utils.guid()}`;

    target.datum(data);

    const defs = target.append('defs');

    defs.append('clipPath')
        .attr('id', aboveClipId)
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', baseY);

    defs.append('clipPath')
        .attr('id', belowClipId)
        .append('rect')
        .attr('x', 0)
        .attr('y', baseY)
        .attr('width', width)
        .attr('height', height - baseY);

    target
        .append('g')
        .attr('class', `${Classes.GRAPH_ABOVEBELOW}__above`)
        .append('path')
        .attr('clip-path', `url(#${aboveClipId})`)
        .attr('d', abArea.y0(height))
        .style(styles.positive);

    target
        .append('g')
        .attr('class', `${Classes.GRAPH_ABOVEBELOW}__below`)
        .append('path')
        .attr('clip-path', `url(#${belowClipId})`)
        .attr('d', abArea.y0(0))
        .style(styles.negative);

    return target;
}
