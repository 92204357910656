<template>
    <markets-ui-container
        :class="cls"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <div slot="content" :class="[namespace('container')]">
            <div
                :class="namespace('navigation-wrapper')"
                v-if="showWebLayout && !errorMessage"
            >
                <div :class="namespace('navigation')">
                    <markets-ui-button-group
                        :dataModel="settingViewType"
                        :skin="watchedSettings.skin"
                        @change="changeSettingsViewType"
                    ></markets-ui-button-group>
                    <markets-ui-button
                        :class="namespace('yields-button')"
                        v-if="showMenubutton"
                        icon-right="caret-down"
                        :skin="watchedSettings.skin"
                        :text="menuButtonlabel"
                        :aria-expanded="menuExpanded"
                        @click="clickMenuButton"
                    />
                </div>
            </div>

            <keep-alive v-if="dataModel.data.length > 0">
                <component
                    v-bind:is="viewType"
                    :skin="watchedSettings.skin"
                    :labels="initedLabels"
                    :dataModel="dataModel.data"
                    :yields="watchedSettings.yields"
                    :timePeriods="watchedSettings.maturities"
                    :settings="watchedSettings"
                    :class="namespace('sub-component')"
                    :style="subComponentStyle"
                ></component>
            </keep-alive>
            <div :class="namespace('as-of-date')">{{ asOfDate }}</div>
        </div>
        <markets-ui-settings
            slot="settings"
            ref="treasuryUiSettings"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected && !showWebLayoutSettings"
            :showDone="showDoneButton"
            :triggered-by="settingsPopoverTriggerId"
            :visible="settingsPopoverVisible"
            :title="settingsPopoverTitle"
            :doneDisable="!!settingsErrorMessage"
            @hide="hideSettingsPopover"
            @done="changeSetting"
            @back="changeSettingsNav"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            ></markets-ui-settings-navigation>

            <markets-ui-checkbox-group
                v-if="showTimePeriodsSetting"
                size="small"
                :skin="watchedSettings.skin"
                :dataModel="settingTimePeriods"
                @change="changeSettingTimePeriods"
                slot="content"
            ></markets-ui-checkbox-group>

            <markets-ui-checkbox-group
                v-if="showYieldsSetting"
                size="small"
                :skin="watchedSettings.skin"
                :dataModel="settingYields"
                @change="changeSettingYields"
                slot="content"
            ></markets-ui-checkbox-group>

            <markets-ui-list
                v-if="showViewTypeSetting"
                :skin="watchedSettings.skin"
                variation="selection"
                :dataModel="settingViewType"
                @change="changeSettingsViewType"
                slot="content"
            ></markets-ui-list>
            <footer slot="footer" :class="namespace('setting-footer')">
                <div v-if="showYieldsSetting || showTimePeriodsSetting">
                    <div
                        :class="[namespace('setting-footer-message__error')]"
                        v-if="settingsErrorMessage"
                    >
                        {{ settingsErrorMessage }}
                    </div>
                    <div :class="namespace('setting-footer-content-panel')">
                        <span
                            :class="[namespace('setting-footer-content-count')]"
                            >{{ selectedCount }}</span
                        >
                        <markets-ui-button
                            variation="flat"
                            :class="namespace('setting-footer-content-reset')"
                            :skin="watchedSettings.skin"
                            :text="initedLabels.deselect"
                            @click.stop="resetDefault"
                        />
                    </div>
                </div>
            </footer>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import { YIELDS, PERIODS } from './metadata/datapoint';

import labels from './assets/labels.json';
const { utils, mixins } = mwcMarketsCore;
import { VIEW_TYPES } from './metadata/view-types';
import TABLE from './components/table-view';
import CHART from './components/chart-view';

export default {
    name: 'mwc-markets-treasury-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Object,
            default() {
                return {
                    asOfDate: '',
                    data: []
                };
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TABLE,
        CHART
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            settingsPopoverTitle: undefined,
            settingMaturitiesSelected: undefined,
            settingYieldsSelected: undefined,
            showWebLayoutSettings: false
        };
    },
    watch: {
        settingsPopoverVisible(val) {
            if (!val) {
                this.settingsPopoverNavSelected = null;
            }
        }
    },
    computed: {
        cls() {
            const cls = [this.namespace()];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            }
            if (this.showWebLayout) {
                cls.push(this.namespace('web-layout'));
            }
            return cls;
        },
        asOfDate() {
            return `${this.initedLabels.dataAsOf} ${utils.getFormatValue({
                formatter: this.formatter,
                dataType: 'date',
                value: this.dataModel.asOfDate
            })}`;
        },
        settingsPopoverNav() {
            const popoverNav = [];
            if (
                this.watchedSettings.showSettingMaturities &&
                this.showTableView
            ) {
                popoverNav.push({
                    id: 'maturity',
                    title: this.initedLabels['maturity'],
                    name: `${
                        this.settingTimePeriods.filter(d => d.selected).length
                    } ${this.initedLabels['selected']}`
                });
            }
            if (this.watchedSettings.viewType === VIEW_TYPES.CHART) {
                popoverNav.push({
                    id: 'yields',
                    title: this.initedLabels['yields'],
                    name: `${this.watchedSettings.yields.length} ${this.initedLabels['selected']}`
                });
            }
            popoverNav.push({
                id: 'viewType',
                title: this.initedLabels['dataView'],
                name: this.initedLabels[this.watchedSettings.viewType]
            });
            return popoverNav;
        },
        showTableView() {
            return this.watchedSettings.viewType === VIEW_TYPES.TABLE;
        },
        viewType() {
            if (this.showTableView) {
                return 'TABLE';
            } else {
                return 'CHART';
            }
        },
        showTimePeriodsSetting() {
            return (
                this.watchedSettings.showSettingMaturities &&
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'maturity'
            );
        },
        showViewTypeSetting() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'viewType'
            );
        },
        showYieldsSetting() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'yields'
            );
        },
        showMenubutton() {
            return (
                (this.showWebLayout &&
                    this.watchedSettings.viewType === VIEW_TYPES.CHART) ||
                (this.showWebLayout &&
                    this.watchedSettings.viewType === VIEW_TYPES.TABLE &&
                    this.watchedSettings.showSettingMaturities)
            );
        },
        showDoneButton() {
            return (
                !this.showViewTypeSetting && !!this.settingsPopoverNavSelected
            );
        },
        settingsErrorMessage() {
            if (this.settingYieldsSelected) {
                const selectedYields = this.getSelectedItems(
                    this.settingYieldsSelected
                );
                if (selectedYields.length === 0) {
                    return this.initedLabels.noYieldsSelectedMessage;
                }
            } else if (
                this.showTimePeriodsSetting &&
                this.settingMaturitiesSelected
            ) {
                if (
                    this.getSelectedItems(this.settingMaturitiesSelected)
                        .length === 0
                ) {
                    return this.initedLabels.noMaturitySelectedMessage;
                }
            }
            return '';
        },
        selectedCount() {
            let selectedItems = [],
                allLength = 0;
            if (this.showYieldsSetting) {
                selectedItems = this.getSelectedYields();
                allLength = YIELDS.length;
            } else if (this.showTimePeriodsSetting) {
                selectedItems = this.getSelectedTimePeriods();
                allLength = PERIODS.length;
            }
            return `${selectedItems.length}/${allLength}`;
        },
        settingTimePeriods() {
            const selectedItems = this.getSelectedTimePeriods();
            return PERIODS.map(item => {
                const name = this.initedLabels && this.initedLabels[item];
                return {
                    id: item,
                    name,
                    selected: utils.inArray(selectedItems, item) > -1,
                    visible: true
                };
            });
        },
        settingYields() {
            const selectedYields = this.getSelectedYields();
            return YIELDS.map(item => {
                const name = this.initedLabels[item];
                return {
                    id: item,
                    name,
                    selected: utils.inArray(selectedYields, item) > -1,
                    visible: true
                };
            });
        },
        settingViewType() {
            return Object.values(VIEW_TYPES).map(viewType => {
                return {
                    id: viewType,
                    name: this.initedLabels[viewType],
                    selected: viewType === this.watchedSettings.viewType
                };
            });
        },
        menuButtonlabel() {
            if (this.watchedSettings.viewType === VIEW_TYPES.CHART) {
                return this.initedLabels.yields;
            } else {
                return this.initedLabels.maturity;
            }
        },
        subComponentStyle() {
            const { viewType, autoHeight, charts } = this.watchedSettings;
            if (autoHeight && viewType === VIEW_TYPES.CHART) {
                return `height: ${charts.height}px`;
            }
            return '';
        },
        menuExpanded() {
            return '' + (this.settingsPopoverNavSelected !== null);
        }
    },
    methods: {
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.settingsPopoverTitle = item
                ? item.title
                : this.initedLabels.settingTitle;
            if (item && item.id === 'yields') {
                this.settingYieldsSelected = null;
            }
            this.$refs.treasuryUiSettings.enableTrapFocus();
        },
        clickMenuButton(e) {
            this.toggleSettings(e);
            this.showWebLayoutSettings = true;
            if (this.watchedSettings.viewType === VIEW_TYPES.CHART) {
                this.settingsPopoverNavSelected = {
                    id: 'yields',
                    title: this.initedLabels['yields']
                };
                this.settingsPopoverTitle = this.initedLabels.yields;
            } else {
                this.settingsPopoverNavSelected = {
                    id: 'maturity',
                    title: this.initedLabels['maturity']
                };
                this.settingsPopoverTitle = this.initedLabels.maturity;
            }
        },
        changeSettingTimePeriods(items) {
            this.settingMaturitiesSelected = items;
        },
        changeSettingsViewType(item) {
            this.watchedSettings.viewType = item.id;
            this.settingsPopoverVisible = false;
            this.$emit('settings-changed', this._getStoreSettings());
        },
        changeSettingYields(items) {
            this.settingYieldsSelected = items;
        },

        toggleSettings(e) {
            this.showWebLayoutSettings = false;
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggerId = utils.computeTriggerId(
                e.currentTarget
            );
            this.settingsPopoverTitle = this.initedLabels.settingTitle;
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.settingMaturitiesSelected = null;
            this.settingYieldsSelected = null;
            this.settingsPopoverTitle = this.initedLabels.settingTitle;
            this.showWebLayoutSettings = false;
            this.$emit('settings-popover-hide');
        },
        changeSetting() {
            if (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'maturity' &&
                this.settingMaturitiesSelected
            ) {
                this.watchedSettings.maturities = this.getSelectedItems(
                    this.settingMaturitiesSelected
                );
            } else if (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'yields' &&
                this.settingYieldsSelected
            ) {
                this.watchedSettings.yields = this.getSelectedItems(
                    this.settingYieldsSelected
                );
            }
            if (this.settingMaturitiesSelected || this.settingYieldsSelected) {
                this.$emit('settings-changed', this._getStoreSettings());
            }
            this.hideSettingsPopover();
        },
        getSelectedItems(checkboxMap) {
            return Object.keys(checkboxMap).filter(key => !!checkboxMap[key]);
        },
        getSelectedYields() {
            let selectedYields;
            if (this.settingYieldsSelected) {
                selectedYields = this.getSelectedItems(
                    this.settingYieldsSelected
                );
            } else {
                selectedYields = this.watchedSettings.yields;
            }
            return selectedYields;
        },
        getSelectedTimePeriods() {
            let selectedItems;
            if (this.settingMaturitiesSelected) {
                selectedItems = this.getSelectedItems(
                    this.settingMaturitiesSelected
                );
            } else {
                selectedItems = this.watchedSettings.maturities;
            }
            return selectedItems;
        },
        resetDefault() {
            if (this.showTimePeriodsSetting) {
                this.settingMaturitiesSelected = {};
            } else if (this.showYieldsSetting) {
                this.settingYieldsSelected = {};
            }
        },
        /**
         * return setting string
         */
        _getStoreSettings() {
            const setting = {};
            if (this.watchedSettings.showSettingMaturities) {
                setting.maturities = this.watchedSettings.maturities.join(',');
            }
            setting.yields = this.watchedSettings.yields.join(',');
            setting.viewType = this.watchedSettings.viewType;
            return setting;
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('treasury');
        ['maturities', 'yields'].forEach(key => {
            this.watchedSettings[key] = this.watchedSettings[key]
                ? this.watchedSettings[key].split(',')
                : [];
        });
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-treasury';

.#{$namespace} {
    &.markets-ui-container__fixed-height {
        .#{$namespace}-container {
            height: 100%;
            overflow: auto;
        }
        .#{$namespace}-sub-component {
            height: calc(100% - 40px);
            overflow: auto;
        }
    }

    &-web-layout.markets-ui-container__fixed-height {
        .#{$namespace}-sub-component {
            height: calc(100% - 85px);
        }
    }

    &-navigation {
        &-wrapper {
            height: 45px;
        }
        display: flex;
        padding: $mds-space-1-x;
    }
    &-yields-button {
        margin-left: $mds-space-1-x;
    }
    &-as-of-date {
        color: $mds-text-color-primary;
        height: 20px;
        font-size: 12px;
        padding: 0 3px;
        margin: 10px 5px;
    }
    &-setting-footer {
        font-size: $mds-typography-font-size-s;
    }
    &-setting-footer-message__error {
        color: $mds-feedback-color-error;
    }
    &-setting-footer-content-panel {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }
    &-setting-footer-content-reset {
        font-weight: $mds-typography-font-weight-bold;
        color: $mds-color-neutral-50;
        cursor: pointer;
    }
    &.markets-ui-container__dark-gray {
        .#{$namespace} {
            &-as-of-date {
                color: $mds-text-color-primary-on-dark;
            }
        }
    }
}
</style>
