import { MDS } from './constants';
import mwcMarketsCore from 'mwc-markets-core';

const { utils } = mwcMarketsCore;

const { color } = MDS;

const textColor = MDS['text-color'];

const backgroundColor = MDS['background-color'];

const font = {
    family:
        'Univers, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif',
    size: '14px'
};

const text = {
    default: {
        'font-family': font.family,
        'font-size': font.size,
        fill: textColor.primary,
        background: backgroundColor.white
    },
    dark: {
        'font-family': font.family,
        'font-size': font.size,
        fill: color.neutral['95'],
        background: backgroundColor['dark-gray']
    }
};

const lineStroke = {
    highlight: {
        default: {
            stroke: color.neutral['37'],
            'shape-rendering': 'crispedges',
            'stroke-width': '1px'
        },
        dark: {
            stroke: color.neutral['80'],
            'shape-rendering': 'crispedges',
            'stroke-width': '1px'
        }
    },
    default: {
        stroke: color.neutral['50'],
        'shape-rendering': 'crispedges',
        'stroke-width': '1px'
    }
};

const fillOpacity = '0.6';
const strokeWidth = '2px';

const ohlcStyle = {
    default: {
        positive: {
            stroke: textColor.performance.positive,
            fill: textColor.performance.positive,
            'fill-opacity': fillOpacity
        },
        negative: {
            stroke: textColor.performance.negative,
            fill: textColor.performance.negative,
            'fill-opacity': fillOpacity
        },
        neutral: {
            stroke: textColor.performance.neutral,
            fill: textColor.performance.neutral,
            'fill-opacity': fillOpacity
        }
    },
    dark: {
        positive: {
            stroke: textColor.performance['positive-on-dark'],
            fill: textColor.performance['positive-on-dark'],
            'fill-opacity': fillOpacity
        },
        negative: {
            stroke: textColor.performance['negative-on-dark'],
            fill: textColor.performance['negative-on-dark'],
            'fill-opacity': fillOpacity
        },
        neutral: {
            stroke: textColor.performance['neutral-on-dark'],
            fill: textColor.performance['neutral-on-dark'],
            'fill-opacity': fillOpacity
        }
    }
};

const candlestickStyle = {
    default: {
        'positive-hollow': {
            stroke: textColor.performance.positive,
            fill: backgroundColor.white
        },
        'positive-fill': {
            stroke: textColor.performance.positive,
            fill: textColor.performance.positive,
            'fill-opacity': fillOpacity
        },
        'negative-hollow': {
            stroke: textColor.performance.negative,
            fill: backgroundColor.white
        },
        'negative-fill': {
            stroke: textColor.performance.negative,
            fill: textColor.performance.negative,
            'fill-opacity': fillOpacity
        }
    },
    dark: {
        'positive-hollow': {
            stroke: textColor.performance['positive-on-dark'],
            fill: backgroundColor['dark-gray']
        },
        'positive-fill': {
            stroke: textColor.performance['positive-on-dark'],
            fill: textColor.performance['positive-on-dark'],
            'fill-opacity': fillOpacity
        },
        'negative-hollow': {
            stroke: textColor.performance['negative-on-dark'],
            fill: backgroundColor['dark-gray']
        },
        'negative-fill': {
            stroke: textColor.performance['negative-on-dark'],
            fill: textColor.performance['negative-on-dark'],
            'fill-opacity': fillOpacity
        }
    }
};

const volumeByPrice = {
    default: {
        positive: {
            fill: textColor.performance.positive,
            'fill-opacity': 0.4
        },
        negative: {
            fill: textColor.performance.negative,
            'fill-opacity': 0.4
        }
    },
    dark: {
        positive: {
            fill: textColor.performance.positive,
            'fill-opacity': 0.4
        },
        negative: {
            fill: textColor.performance.negative,
            'fill-opacity': 0.4
        }
    }
};
const secondaryDark = textColor['secondary-on-dark'];
const styles = {
    white: {
        svg: {
            'background-color': backgroundColor.white
        },
        grid: {
            rect: {
                odd: {
                    display: 'none'
                },
                even: {
                    fill: color.neutral['95']
                }
            },
            line: {
                firstY: lineStroke.highlight.default,
                lastY: lineStroke.highlight.default,
                default: lineStroke.default
            }
        },
        axes: {
            default: {
                text: text.default,
                text_abbr: {
                    fill: textColor.secondary
                },
                line: lineStroke.highlight.default
            },
            x: {},
            y: {},
            y1: {},
            y2: {}
        },
        legend: {
            default: {
                text: text.default,
                line: lineStroke.highlight.default
            },
            item: {
                line: {
                    'stroke-width': strokeWidth,
                    fill: 'none'
                }
            },
            button: {
                text: {
                    'text-anchor': 'middle',
                    fill: textColor.secondary,
                    'dominant-baseline': 'middle'
                },
                line: {
                    stroke: textColor.secondary,
                    'fill-opacity': 0
                }
            }
        },
        graph: {
            line: {
                'stroke-width': strokeWidth,
                fill: 'none'
            },
            area: {
                'fill-opacity': 0.4
            },
            ohlc: ohlcStyle.default,
            candlestick: candlestickStyle.default,
            bar: ohlcStyle.default,
            aboveBelow: {
                positive: utils.extend(
                    {
                        'stroke-width': strokeWidth
                    },
                    ohlcStyle.default.positive
                ),
                negative: utils.extend(
                    {
                        'stroke-width': strokeWidth
                    },
                    ohlcStyle.default.negative
                )
            },
            volumeByPrice: volumeByPrice.default,
            event: {
                text: {
                    fill: '#ffffff',
                    'font-weight': 600,
                    'font-size': '12px'
                }
            }
        },
        front: {
            line: utils.extend(
                {
                    'stroke-dasharray': '6'
                },
                lineStroke.highlight.default
            )
        },
        tag: {
            rect: {
                'fill-opacity': 0.6
            },
            text: {
                fill: '#ffffff',
                'font-family': font.family,
                'font-size': font.size,
                'user-select': 'none'
            }
        },
        draw: {
            fill: 'none',
            stroke: '#7d256f',
            'stroke-width': 1,
            font: {
                fill: '#1e1e1e'
            },
            close: {
                stroke: '#5e5e5e'
            },
            callOut: {
                active: {
                    fill: '#1e1e1e',
                    stroke: '#1e1e1e',
                    'fill-opacity': 0.8
                },
                default: {
                    fill: '#5e5e5e',
                    stroke: '#5e5e5e',
                    'fill-opacity': 0.85
                },
                hover: {
                    fill: '#5e5e5e',
                    stroke: '#5e5e5e',
                    'fill-opacity': 0.9
                }
            }
        }
    },

    'dark-gray': {
        svg: {
            'background-color': backgroundColor['dark-gray']
        },
        grid: {
            rect: {
                odd: {
                    display: 'none'
                },
                even: {
                    fill: color.neutral['20']
                }
            },
            line: {
                firstY: lineStroke.highlight.dark,
                lastY: lineStroke.highlight.dark,
                default: lineStroke.default
            }
        },
        axes: {
            default: {
                text: text.dark,
                text_abbr: {
                    fill: color.neutral['80']
                },
                line: lineStroke.highlight.dark
            },
            x: {},
            y: {},
            y1: {},
            y2: {}
        },
        legend: {
            default: {
                text: text.dark,
                line: lineStroke.highlight.dark
            },
            item: {
                line: {
                    'stroke-width': strokeWidth,
                    fill: 'none'
                }
            },
            button: {
                text: {
                    'text-anchor': 'middle',
                    fill: secondaryDark,
                    'dominant-baseline': 'middle'
                },
                line: {
                    stroke: secondaryDark,
                    'fill-opacity': 0
                }
            }
        },
        graph: {
            line: {
                'stroke-width': strokeWidth,
                fill: 'none'
            },
            area: {
                'fill-opacity': 0.4
            },
            ohlc: ohlcStyle.dark,
            candlestick: candlestickStyle.dark,
            bar: ohlcStyle.dark,
            aboveBelow: {
                positive: utils.extend(
                    {
                        'stroke-width': strokeWidth
                    },
                    ohlcStyle.dark.positive
                ),
                negative: utils.extend(
                    {
                        'stroke-width': strokeWidth
                    },
                    ohlcStyle.dark.negative
                )
            },
            volumeByPrice: volumeByPrice.default,
            event: {
                text: {
                    fill: '#ffffff',
                    'font-weight': 600,
                    'font-size': '12px'
                }
            }
        },
        front: {
            line: utils.extend(
                {
                    'stroke-dasharray': '6'
                },
                lineStroke.highlight.dark
            )
        },
        tag: {
            rect: {
                'fill-opacity': 0.6
            },
            text: {
                fill: '#ffffff',
                'font-family': font.family,
                'font-size': font.size
            }
        },
        draw: {
            fill: 'none',
            stroke: '#c026d2',
            'stroke-width': 1,
            font: {
                fill: '#fff'
            },
            close: {
                stroke: '#fff'
            },
            callOut: {
                active: {
                    fill: '#1e1e1e',
                    stroke: '#f2f2f2',
                    'fill-opacity': 0.8
                },
                default: {
                    fill: '#5e5e5e',
                    stroke: '#f2f2f2',
                    'fill-opacity': 0.8
                },
                hover: {
                    fill: '#5e5e5e',
                    stroke: '#f2f2f2',
                    'fill-opacity': 0.9
                }
            }
        }
    }
};

export default styles;
