import Mixin from './mixin';
import {
    Classes,
    EVENTS,
    FIRST_CHILD,
    GraphTypes,
    ZOOM_EVENT
} from './constants';
import d3 from 'd3';
import mwcMarketsCore from 'mwc-markets-core';
import Tooltip from './tooltip';
import { calculateTickSize } from './common';
import { barPath } from './graph/bar';
import Draw from './drawing';
const { utils } = mwcMarketsCore;
export default class Front extends Mixin {
    constructor(selection, option) {
        super();

        this._el = selection;
        this.option(option);
        this.watch('skin', value => {
            if (this._option.tooltip.show) {
                this.get('toolTip').set('skin', value);
            }
            const highLight = this.get('highlight');
            if (highLight) {
                highLight.style(
                    utils.extend({}, this._option.styles.front.line)
                );
            }
            this.get('drawTool').set('styles', this._option.styles);
            this.get('drawTool').update({
                scales: this.get('scales')
            });
        });
        if (option.tooltip.show) {
            this.__Tooltip(option.tooltip);
            //add skin change handler
            if (!this._validateClick) {
                this._validateClick = this.getValidateClickFunc();
                document.addEventListener('click', this._validateClick);
            }
        }
        this._drawTool = new Draw(this._el, {
            scales: this.get('scales'),
            styles: this._option.styles,
            drawings: this._option.drawings,
            tag: this._option.tag,
            y1axis: this._option.y1axis,
            skin: this._option.skin,
            findNearestPoints: position => {
                return this.findNearestPoints(position);
            }
        });
        this._drawTool.on(EVENTS.DrawEnd, drawings => {
            this.trigger(EVENTS.DrawEnd, drawings);
            this.enablePanAndZoom();
        });
        this._drawTool.on(EVENTS.Mousemove, selected => {
            if (selected) {
                this.disablePanAndZoom();
            } else {
                this.enablePanAndZoom();
            }
        });
        this._rect = this._el
            .append('rect')
            .attr('opacity', 0)
            .classed(`${Classes.FRONT}-rect`, true)
            .on('mousemove', () => {
                this.__mouseMove();
            })
            .on('touchstart', () => {
                this.__touchStart();
            })
            .on('touchmove', () => {
                this.__touchMove();
            })
            .on('mousedown', () => {
                this.__mousedown();
            })
            .on('mouseleave', () => {
                this.__mouseLeave();
            })
            .on('touchend', () => {
                this.__touchEnd();
            })
            .on('click', () => {
                this.__click();
            });
    }
    option(option) {
        option.tooltip = option.tooltip || {};
        option.tooltip.skin = option.skin;
        this._option = option;
        this._skin = option.skin;
    }
    update() {
        this.__removeHighlight();
        this.__creatFront();
    }
    __removeHighlight() {
        ['highLightVertical', 'highLightHorizontal'].forEach(key => {
            if (this.get(key)) {
                this.get(key).remove();
                this.set(key, null);
            }
        });
        this.get('el')
            .selectAll(`.${Classes.FRONT_TRACKBALL}`)
            .remove();
        if (this.get('toolTip')) {
            this.get('toolTip').hide();
        }
    }

    zoom(type) {
        const xScale = this.get('scales').scale('x');
        const zoom = this.get('zoom');
        const width = xScale.range()[1] - xScale.range()[0];
        const domain = xScale.domain();
        this._zoomEl.call(zoom);
        this.__clearBrush();
        if (type === 'panLeft') {
            zoom.translate([width / 10, 0]);
            zoom.event(this.get('rect'));
        } else if (type === 'panRight') {
            zoom.translate([-width / 10, 0]);
            zoom.event(this.get('rect'));
        } else if (type === 'zoomOut') {
            let range = (domain[1] - domain[0]) * 1.1;
            range = Math.max(1, range);
            const newDomain = [domain[1] - range, domain[1]];
            this.changeDomain(newDomain, ZOOM_EVENT.ZOOM_OUT);
        } else if (type === 'zoomIn') {
            const range = (domain[1] - domain[0]) * 0.9;
            const newDomain = [domain[1] - Math.floor(range), domain[1]];
            this.changeDomain(newDomain, ZOOM_EVENT.ZOOM_IN);
        } else if (type === 'zoomBrush') {
            this.zoomBrush();
        }
    }
    __clearBrush() {
        if (this._brushEl) {
            this._brushEl.remove();
        }
    }
    zoomBrush() {
        this._rect.on('.zoom', null);
        const xScale = this.get('scales').scale('x');
        const width = xScale.range()[1] - xScale.range()[0];
        const height = this.get('scales')
            .scale('y1')
            .range()[0];
        const y = this._top ? this._top : 0;
        this.__clearBrush();
        this._brushEl = this._el
            .append('g')
            .attr('x', xScale.range()[0])
            .attr('y', y)
            .attr('width', width)
            .attr('height', height)
            .classed(Classes.ZOOM_BRUSH, true)
            .on('mousemove', () => {
                this.__mouseMove();
            })
            .on('mouseleave', () => {
                this.__mouseLeave();
            })
            .on('touchend', () => {
                this.__mouseMove();
            });
        let startPosition = [],
            endPosition = [];
        this._brush = d3.svg
            .brush()
            .x(xScale)
            .on('brushstart', () => {
                startPosition = d3.mouse(this._el.node());
            })
            .on('brush', () => {
                endPosition = d3.mouse(this._el.node());
                if (endPosition[1] - startPosition[1] > 0) {
                    this._brushEl
                        .select('.extent')
                        .attr('height', endPosition[1] - startPosition[1])
                        .attr('y', startPosition[1])
                        .style({
                            'pointer-events': 'none'
                        });
                }
            })
            .on('brushend', () => {
                const extent = this._brush.extent();
                // Remove the brush instead of clear it, client need click button again for a new brush
                // this._brush.clear();
                // this._brushEl.call(this._brush);
                this._brushEl.remove();
                this.changeDomain(extent, ZOOM_EVENT.ZOOM_IN);
            });

        this._brushEl.call(this._brush);
        this._brushEl
            .selectAll('rect')
            .attr('y', 0)
            .attr('height', height);
    }

    changeDomain(domain, event) {
        if (domain[1] - domain[0] > 0) {
            this.trigger(EVENTS.DomainChanged, { domain, event });
        }
    }
    disableZoom() {
        if (this._zoomEl) {
            this._zoomEl.on('wheel.zoom', null);
        }
    }

    disablePanAndZoom() {
        if (this._zoomParentEl) {
            this._zoomParentEl.style({
                display: 'none'
            });
        }
    }

    enablePanAndZoom() {
        if (this._zoomParentEl) {
            this._zoomParentEl.style({
                display: ''
            });
        }
    }
    changeDrawingsType(id) {
        if (
            id !== 'noTool' &&
            id !== 'close' &&
            id !== 'trash' &&
            id !== 'lock' &&
            id !== 'unLock'
        ) {
            this.disablePanAndZoom();
        }
        this._drawTool.changeDrawingsType(id);
    }
    __creatFront() {
        const scales = this.get('scales');
        const height = scales.scale('y1').range()[0];
        const xScale = this.get('scales').scale('x');
        const width = xScale.range()[1] - xScale.range()[0];
        const y = this._top ? this._top : 0;
        this._rect
            .attr('x', xScale.range()[0])
            .attr('y', y)
            .attr('width', width)
            .attr('height', height);

        this.__createZoom();
        const dataManager = this.get('dataManager');
        if (dataManager) {
            this._drawTool.set('dataManager', dataManager);
        }
        this._drawTool.update({
            scales
        });
    }
    __getZoomEvent() {
        const xScale = this.get('scales').scale('x');
        const newDomain = xScale.domain();
        const dValue =
            newDomain[1] -
            newDomain[0] -
            (this.startDomain[1] - this.startDomain[0]);

        if (Math.abs(dValue) < 0.1) {
            return ZOOM_EVENT.PAN;
        } else if (dValue > 0) {
            return ZOOM_EVENT.ZOOM_OUT;
        } else {
            return ZOOM_EVENT.ZOOM_IN;
        }
    }

    __createZoom() {
        const zoomOption = this._option.zoom;
        const scales = this.get('scales');
        if (zoomOption.enablePan || zoomOption.enableZoom) {
            const xScale = scales.scale('x');
            this._zoom = d3.behavior
                .zoom()
                .scaleExtent([0.5, 50])
                .on('zoomstart', () => {
                    this.__mouseLeave();
                    const xScale = this.get('scales').scale('x');
                    this.startDomain = xScale.domain();
                    this._isZooming = true;
                })
                .on('zoom', () => {
                    const zoomEvent = this.__getZoomEvent();
                    if (zoomEvent === ZOOM_EVENT.PAN && zoomOption.enablePan) {
                        this.get('el').style({
                            cursor: 'grab'
                        });
                    } else if (
                        zoomEvent === ZOOM_EVENT.ZOOM_IN &&
                        zoomOption.enableZoom
                    ) {
                        this.get('el').style({
                            cursor: 'zoomIn'
                        });
                    } else if (
                        zoomEvent === ZOOM_EVENT.ZOOM_OUT &&
                        zoomOption.enableZoom
                    ) {
                        this.get('el').style({
                            cursor: 'zoomOut'
                        });
                    }
                })
                .on('zoomend', () => {
                    const zoomEvent = this.__getZoomEvent();
                    const xScale = this.get('scales').scale('x');
                    this.get('el').style({
                        cursor: 'default'
                    });
                    if (
                        (zoomEvent === ZOOM_EVENT.PAN &&
                            zoomOption.enablePan) ||
                        (zoomEvent !== ZOOM_EVENT.PAN && zoomOption.enableZoom)
                    ) {
                        this.changeDomain(xScale.domain(), zoomEvent);
                    } else {
                        this.get('scales')
                            .scale('x')
                            .domain(this.startDomain);
                    }
                    this._isZooming = false;
                });
            this._zoom.center([xScale.range()[1], 0]).x(xScale);
            if (!this._zoomEl) {
                this._zoomParentEl = this._el
                    .append('g')
                    .classed(Classes.ZOOM, true);

                this._zoomEl = this._zoomParentEl
                    .append('rect')
                    .on('mousemove', () => {
                        this.__mouseMove();
                    })
                    .on('touchstart', () => {
                        this.__touchStart();
                    })
                    .on('touchmove', () => {
                        this.__touchMove();
                    })
                    .on('mousedown', () => {
                        this.__mousedown();
                    })
                    .on('mouseleave', () => {
                        this.__mouseLeave();
                    })
                    .on('touchend', () => {
                        this.__touchEnd();
                    })
                    .on('click', () => {
                        this.__click();
                    });
            }
            const width = xScale.range()[1] - xScale.range()[0];
            const y = this._top ? this._top : 0;
            const height = scales.scale('y1').range()[0];
            this._zoomEl
                .attr('x', xScale.range()[0])
                .attr('y', y)
                .attr('width', width)
                .attr('height', height)
                .style({
                    fill: 'none',
                    'pointer-events': 'all'
                });
            this._zoomEl.call(this._zoom);
            if (!zoomOption.enableZoom) {
                this._zoomEl.on('wheel.zoom', null);
            }
        }
    }
    getValidateClickFunc() {
        return e => {
            if (!e.target.isSameNode(this.get('rect')[0][0])) {
                if (this.get('toolTip')) {
                    this.get('toolTip').hide();
                }
            }
        };
    }
    triggerMousemove({ position, external, fromEventGraph }) {
        this.__mouseMove({ position, external, fromEventGraph });
    }
    triggerMouseleave() {
        this.__removeHighlight();
    }
    __touchStart() {
        const position = d3.mouse(this._rect[0][0]);
        this._drawTool.touchStart({
            position
        });
        if (this._option.zoom.enablePan || this._option.zoom.enableZoom) {
            d3.event.preventDefault();
        }
    }

    __touchMove() {
        const position = d3.mouse(this._rect[0][0]);
        const nearestPoints = this.findNearestPoints(position);
        if (!nearestPoints.length) {
            return;
        }
        if (this._validPosition(position)) {
            this._drawTool.touchMove({
                dataPoint: nearestPoints[0],
                position
            });
            this.__mouseMove();
        } else {
            this.__mouseLeave();
        }
    }

    __touchEnd() {
        const position = d3.mouse(this._rect[0][0]);
        const nearestPoints = this.findNearestPoints(position);
        if (!nearestPoints.length) {
            return;
        }
        if (this._validPosition(position)) {
            this._drawTool.touchEnd({
                dataPoint: nearestPoints[0],
                position
            });
            this.__mouseMove();
            this.__click();
        }
        d3.event.preventDefault();
    }
    __mousedown() {
        const position = d3.mouse(this._rect[0][0]);
        this._drawTool.mousedown({
            position
        });
    }

    __click() {
        const position = d3.mouse(this._rect[0][0]);
        const nearestPoints = this.findNearestPoints(position);
        if (!nearestPoints.length) {
            return;
        }
        const nearest = nearestPoints[0];
        this._drawTool.click({
            dataPoint: nearest,
            position
        });
    }

    _validPosition(position) {
        const scales = this.get('scales');
        const xScale = scales.scale('x');
        const y1Scale = scales.scale('y1');
        const yRange = y1Scale.range();
        const xRange = xScale.range();
        if (
            position.length === 0 ||
            position[0] < xRange[0] ||
            position[1] > xRange[1] ||
            position[1] < yRange[1] ||
            position[1] > yRange[0]
        ) {
            return false;
        }

        return true;
    }
    //external: true  userCase: when mouse over on main chart, need trigger mouse over event to indicators chart manually
    //fromEvensGraph: true  userCase: when mouse over on events, triggered mouse over from event graph instead of front
    /* eslint max-statements: 0 */
    /* eslint complexity: 0 */
    __mouseMove({ position, external = false, fromEventGraph = false } = {}) {
        const scales = this.get('scales');
        const xScale = scales.scale('x');
        const y1Scale = scales.scale('y1');
        if (!position) {
            position = d3.mouse(this._rect[0][0]);
            if (!this._validPosition(position)) {
                this.__mouseLeave();
                return;
            }
        } else if (position.length === 0) {
            this.__mouseLeave();
            return;
        }
        if (!external && !fromEventGraph && this._option.highLightHoveredLine) {
            this.__highlightHoveredLine(position);
        }

        const nearestPoints = this.findNearestPoints(position, fromEventGraph);
        const nearest = nearestPoints[0];
        this._drawTool.mousemove({
            dataPoint: nearest,
            position
            // drawType: this.drawingsType
        });

        const toolTipData = [];
        const trackBallData = [];
        let tooltipPosition;

        // find the nearest data point of mouse
        for (let i = 0; i < nearestPoints.length; i++) {
            const nearest = nearestPoints[i];
            const rPosition = [xScale(nearest.index), position[1]];
            const yScale = scales.scale(nearest.yAxis);
            if (i === 0) {
                if (this._option.highlight.alignCrossAndTrackBall) {
                    if (fromEventGraph) {
                        this.__removeHighlight();
                    } else {
                        if (!fromEventGraph) {
                            this.__highlightVertical(position[0]);
                        }
                        if (!external) {
                            this.__highLightHorizontal(position[1]);
                        }
                    }
                } else {
                    if (!external && !fromEventGraph) {
                        this.__highLightHorizontal(position[1]);
                    }
                    tooltipPosition = rPosition;
                }
            }
            if (!isNaN(yScale(nearest.value))) {
                trackBallData.push({
                    x: rPosition[0],
                    y: yScale(nearest.value),
                    color: nearest.color,
                    data: nearest
                });
            }
            toolTipData.push(nearest);
        }
        if (
            this._option.highlight.showTrackBall &&
            !fromEventGraph &&
            !this._isZooming
        ) {
            this.__trackBall(
                trackBallData.filter(d => d.data.graphType !== GraphTypes.EVENT)
            );
        }

        if (!external) {
            //calculate mouse move position relative front group element
            const mousePosition = tooltipPosition
                ? tooltipPosition
                : d3.mouse(this._el.node());
            mousePosition[1] = mousePosition[1] - y1Scale.range()[1];
            mousePosition[0] = mousePosition[0] - xScale.range()[0];
            if (this.get('toolTip')) {
                this.get('toolTip').show(toolTipData, {
                    position: mousePosition
                });
            }
            this.trigger(EVENTS.Mousemove, {
                data: toolTipData,
                e: d3.event,
                fromEventGraph,
                position
            });
        }
    }
    __mouseLeave() {
        this.trigger(EVENTS.Mouseleave);
        this.__removeHighlight();
    }
    __Tooltip(option) {
        let toolTip = this.get('toolTip');
        if (!toolTip) {
            toolTip = new Tooltip(
                utils.extend({ skin: this._skin }, option),
                this.get('el')
            );
        }
        this.set('toolTip', toolTip);
    }
    __highlightHoveredLine(position) {
        const index = this.__findHoveredLineIndex(position);
        this.trigger(EVENTS.HighlightHoveredLine, { index });
    }

    __findHoveredLineIndex(position) {
        const DX = 5; // think it's hovered on the line if distance smaller than 5px;
        const dataManager = this.get('dataManager');
        const xScale = this.get('scales').scale('x');
        const distanceArray = [];
        let gIdx = 0;
        ['y1', 'y2'].forEach(yaxisName => {
            let yDataSet = dataManager.series(yaxisName) || [];
            const yScale = this.get('scales').scale(yaxisName);
            if (yDataSet.length <= 1) {
                gIdx += yDataSet.length;
                return;
            }
            yDataSet.forEach(data => {
                gIdx++;
                if (!data.get('highlightOnHover')) {
                    return;
                }
                const series = data.get('series');
                const dl = series.length;
                const p = {
                    x: position[0],
                    y: position[1]
                };
                const leftX = Math.max(
                    Math.floor(xScale.invert(position[0] - DX)),
                    0
                );
                const rightX = Math.min(
                    Math.ceil(xScale.invert(position[0] + DX)),
                    dl
                );
                const dataPointsArr = []; // collect all possible line segments between leftX and rightX
                for (let n = leftX; n < rightX; n++) {
                    dataPointsArr.push([n, n + 1]);
                }
                dataPointsArr.forEach(segment => {
                    const p0 = {},
                        p1 = {};
                    let distance;
                    segment.forEach((x, index) => {
                        if (index === 1 && isNaN(p0.x)) {
                            return;
                        }
                        x = Math.max(x, 0);
                        x = Math.min(x, dl - 1);
                        let data = series[x];
                        const factor = index === 0 ? -1 : 1;
                        for (
                            x;
                            x >= 0 && x < dl - 1 && isNaN(data && data.value);

                        ) {
                            x += factor;
                            data = series[x];
                        }
                        if (!data || isNaN(data.value)) {
                            return;
                        }
                        if (index === 0) {
                            p0.x = xScale(x);
                            p0.y = yScale(data.value);
                        } else if (index === 1) {
                            p1.x = xScale(x);
                            p1.y = yScale(data.value);
                        }
                    });

                    if (p1.x === p0.x) {
                        // p1 and p0 is the same points, caculate distance of 'mouse point to P1 point'
                        distance = Math.sqrt(
                            Math.pow(p1.x - p.x, 2) + Math.pow(p1.y - p.y, 2)
                        );
                    } else {
                        if (
                            this.__isAcuteAngle(p0, p, p1) &&
                            this.__isAcuteAngle(p1, p, p0)
                        ) {
                            //if ∠pp0p1 and ∠pp1p0 are both acute angle, caculate the distance of 'point to line'
                            const k = (p0.y - p1.y) / (p0.x - p1.x);
                            const b =
                                (p0.y * p1.x - p1.y * p0.x) / (p1.x - p0.x);
                            distance =
                                Math.abs(k * p.x - 1 * p.y + b) /
                                Math.sqrt(1 + k * k);
                        } else {
                            //else caculate the min distance of 'pp0' and 'pp1'
                            distance = Math.min(
                                Math.sqrt(
                                    Math.pow(p1.x - p.x, 2) +
                                        Math.pow(p1.y - p.y, 2)
                                ),
                                Math.sqrt(
                                    Math.pow(p0.x - p.x, 2) +
                                        Math.pow(p0.y - p.y, 2)
                                )
                            );
                        }
                    }
                    if (!isNaN(distance)) {
                        distanceArray.push({
                            distance,
                            index: gIdx - 1,
                            yaxisName
                        });
                    }
                });
            });
        });
        let minIndex = -1,
            minDis;
        for (let i = 0; i < distanceArray.length; i++) {
            if (distanceArray[i].distance <= minDis || minIndex < 0) {
                minDis = distanceArray[i].distance;
                minIndex = distanceArray[i].index;
            }
        }
        return minDis < DX ? minIndex : -1;
    }
    __isAcuteAngle(p1, p2, p3) {
        //p1 is the vertex,
        var radian;
        var P12 = Math.sqrt(
            Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2)
        );
        var P13 = Math.sqrt(
            Math.pow(p1.x - p3.x, 2) + Math.pow(p1.y - p3.y, 2)
        );
        var P23 = Math.sqrt(
            Math.pow(p2.x - p3.x, 2) + Math.pow(p2.y - p3.y, 2)
        );
        if (P12 === 0 || P23 === 0 || P13 === 0) {
            radian = 0;
        } else {
            var cosV =
                (Math.pow(P12, 2) + Math.pow(P13, 2) - Math.pow(P23, 2)) /
                (2 * P12 * P13);
            if (cosV > 1) {
                cosV = 1;
            } else if (cosV < -1) {
                cosV = -1;
            }
            radian = Math.acos(cosV);
        }
        var angle = Math.floor(180 / (Math.PI / radian));
        return angle < 90;
    }
    findNearestPoints(position, fromEventGraph) {
        const dataManager = this.get('dataManager');
        let idx = -1;
        const dataPoints = [];
        if (isNaN(position[0]) || isNaN(position[1])) {
            return [];
        }
        ['y1', 'y2'].forEach(yaxisName => {
            let yDataSet = dataManager.series(yaxisName);
            if (fromEventGraph && yDataSet) {
                yDataSet = yDataSet.filter(
                    s => s.get('graphType') === GraphTypes.EVENT
                );
            }

            if (yDataSet && utils.isArray(yDataSet) && yDataSet.length) {
                idx = this.__findNearestPointIndex(
                    yDataSet,
                    position,
                    fromEventGraph
                );
                const xScale = this.get('scales').scale('x');
                yDataSet.forEach(seriesArr => {
                    if (seriesArr._graphType === GraphTypes.VOLUME_BY_PRICE) {
                        this.__calculateByPricePosition(seriesArr, position);
                        return;
                    }
                    if (idx > -1) {
                        const series = seriesArr.get('series');
                        series[idx].__idx = idx;
                        series[idx].name = seriesArr.get('displayName');
                        series[idx].color = series[idx].color
                            ? series[idx].color
                            : seriesArr.get('color');
                        series[idx].yAxis = yaxisName;
                        series[idx].graphType = seriesArr.get('graphType');
                        series[idx].x =
                            xScale(idx) -
                            xScale.range()[0] +
                            this.get('margin').left;
                        dataPoints.push(series[idx]);
                    }
                });
            }
        });
        return dataPoints;
    }
    __calculateByPricePosition(series, position) {
        const scales = this.get('scales');
        const option = this.get('option');
        const seriesData = series._series;
        const volumeByPriceScale =
            option.graph.volumeByPrice.volumeByPriceScale;
        const xScales = scales.scale('x');
        const xAxis = xScales.range();
        const width = xAxis[1] - xAxis[0];
        const xDrawScale = d3.scale
            .linear()
            .range([0, width * volumeByPriceScale])
            .domain([0, seriesData[2]]);

        const xMouse = position[0] || 0;
        if (xMouse > width * volumeByPriceScale + xAxis[0]) {
            this.trigger(EVENTS.HideTips);
            return;
        }
        const yScale = scales.scale('y1');
        const mousePrice = yScale.invert(position[1]);
        let dataItem;
        for (let i = 0, len = seriesData[0].length; i < len; i++) {
            const { close, high } = seriesData[0][i];
            if (mousePrice > close && mousePrice < high) {
                dataItem = seriesData[0][i];
                break;
            }
        }
        if (
            dataItem &&
            xDrawScale.invert(xMouse - xAxis[0]) < dataItem.volume
        ) {
            const react = utils.outerSizes(this.get('el').node(), true);
            const tipCenter = yScale(
                (dataItem.high - dataItem.close) / 2 + dataItem.close
            );
            // tipHeight is 30
            const top = tipCenter + react.top - 30 / 2;

            const left = xDrawScale(dataItem.volume) + react.left + xAxis[0];
            this.trigger(EVENTS.ShowTips, {
                data: dataItem,
                e: d3.event,
                position: [top, left]
            });
        } else {
            this.trigger(EVENTS.HideTips);
        }
    }

    __findNearestPointIndex(dataSet, position, fromEventGraph) {
        const scales = this.get('scales');
        const xScale = scales.scale('x');
        const eventOptions = this.get('option').graph.event;
        const height = scales.scale('y1').range()[0];
        if (!dataSet || dataSet.length === 0) {
            return -1;
        }
        const oriXMouse = xScale.invert(position[0]);
        const max = xScale.domain()[1];
        let xMouse = Math.round(oriXMouse);
        //handle boundary
        if (xMouse < 0) {
            xMouse = 0;
        }
        const isValid = idx =>
            dataSet.some(d => {
                if (d.get('name') === 'PreCloseLine') {
                    return false;
                }
                if (!this.get('option').mouseMoveOnValidValue) {
                    return true;
                }
                if (d._series && d._series.length) {
                    const validX = !isNaN(
                        d._series[idx] && d._series[idx].value
                    );
                    let validY = true;
                    if (fromEventGraph && d._series[idx]) {
                        const yIndex = d._series[idx] && d._series[idx].yIndex;
                        const r = eventOptions.radius,
                            m = eventOptions.margin;
                        const yRange = [
                            height - (yIndex + 1) * (r * 2 + m),
                            height - yIndex * (r * 2 + m)
                        ];
                        validY =
                            position[1] >= yRange[0] &&
                            position[1] <= yRange[1];
                    } else if (!d._series[idx]) {
                        validY = false;
                    }
                    return validX && validY;
                }
                return false;
            });
        if (!isValid(xMouse)) {
            let factor = -1;
            let loop = 1;
            let leftSearchEnd = false,
                rightSearchEnd = false;
            do {
                factor *= -1;
                xMouse = xMouse + factor * loop++;
                if (xMouse < 0) {
                    leftSearchEnd = true;
                    continue;
                } else if (xMouse >= max) {
                    rightSearchEnd = true;
                    continue;
                }
            } while (!isValid(xMouse) && (!leftSearchEnd || !rightSearchEnd));
        }
        return xMouse;
    }
    __trackBall(trackBallInfo) {
        this.get('el')
            .selectAll(`.${Classes.FRONT_TRACKBALL}`)
            .remove();
        const option = this.get('option');
        trackBallInfo.forEach(b => {
            if (b.data.graphType === GraphTypes.BAR_UPDOWM) {
                const tickSize = calculateTickSize({
                    size: option.graph.ohlc.width,
                    width: this.get('width'),
                    data: this.get('dataManager').series(b.data.yAxis)[0]
                        ._series
                });
                const yScale = this.get('scales').scale(b.data.yAxis);
                const path = barPath({
                    tickSize,
                    data: b.data,
                    xScale: this.get('scales').scale('x'),
                    xValue: d => {
                        return +d.index;
                    },
                    yScale,
                    yValue: this.get('dataManager')
                        .series(b.data.yAxis)[0]
                        .parser('value'),
                    yScaleHeight: yScale
                });
                this.get('el')
                    .insert('g', FIRST_CHILD)
                    .attr('class', `${Classes.FRONT_TRACKBALL}`)
                    .append('path')
                    .attr('d', path)
                    .style({
                        stroke: b.data.color,
                        fill: 'none',
                        'stroke-width': '2px'
                    });
            } else {
                this._el
                    .insert('circle', FIRST_CHILD)
                    .attr({
                        cx: b.x,
                        cy: b.y,
                        r: 5,
                        stroke: b.color,
                        'stroke-width': 2,
                        fill: 'none'
                    })
                    .attr('class', Classes.FRONT_TRACKBALL, true);
            }
        });
    }

    __highlightVertical(x) {
        const option = this.get('option');
        const scales = this.get('scales');
        const bottom = this._top ? this._top : 0;
        const top = scales.scale('y1').range()[0] + bottom;
        const lineStyle = utils.extend(
            {
                'shape-rendering': 'crispedges'
            },
            option.styles.front.line
        );
        if (!this._highLightVertical && option.highlight.showYLine) {
            this._highLightVertical = this._el.insert('line', FIRST_CHILD);
        }
        if (this._highLightVertical) {
            this._highLightVertical
                .attr('class', `${Classes.FRONT_HIGHLIGHT}-line`, true)
                .attr('x1', x)
                .attr('y1', bottom)
                .attr('x2', x)
                .attr('y2', top)
                .attr('opacity', 1)
                .style(lineStyle);
        }
    }

    __highLightHorizontal(y) {
        const option = this.get('option');
        const scales = this.get('scales');
        const offset = this.get('xOffset') ? this.get('xOffset') : 0;
        const left = scales.scale('x').range()[0];
        const right = scales.scale('x').range()[1] + offset;
        const lineStyle = utils.extend(
            {
                'shape-rendering': 'crispedges'
            },
            option.styles.front.line
        );
        if (!this._highLightHorizontal && option.highlight.showXLine) {
            this._highLightHorizontal = this._el.insert('line', FIRST_CHILD);
        }
        if (this._highLightHorizontal) {
            this._highLightHorizontal
                .attr('class', `${Classes.FRONT_HIGHLIGHT}-line`, true)
                .attr('x1', left)
                .attr('y1', y)
                .attr('x2', right)
                .attr('y2', y)
                .style(lineStyle);
        }
    }
    destroy() {
        this.unwatchAll();
        document.removeEventListener('click', this._validateClick);
        this._drawTool.destroy();
        this.get('el')
            .selectAll('*')
            .remove();
    }
}
