import { Classes } from '../constants';
export default function drawDots({
    selection,
    xScale,
    xValue,
    yValue,
    yScale,
    data,
    radius = 4,
    valid,
    className = '',
    styles
} = {}) {
    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_DOT} ${className}`);
    data.forEach(d => {
        if (valid(d)) {
            target
                .append('circle')
                .attr('r', radius)
                .attr('cx', xScale(xValue(d)))
                .attr('cy', yScale(yValue(d)))
                .style(styles);
        }
    });

    return target;
}
