import Mixin from './mixin';
import d3 from 'd3';
import {
    Classes,
    DRAWINGS,
    EVENTS,
    FIRST_CHILD,
    GraphTypes
} from './constants';
import mwcMarketsCore from 'mwc-markets-core';
import drawTag from './graph/tag';
import { getSVGTextWidth, getGreaterSVGTextWidth } from './common/';
const { utils } = mwcMarketsCore;

const FIB = [0, 0.382, 0.5, 0.618, 1];

export default class Draw extends Mixin {
    /**
     * @param {D3 selection} container of the legend,  Required.
     */
    constructor(selection, option) {
        super();
        this._id = 0;
        this.drawings = [];
        this._el = selection;
        this._container = selection
            .append('g')
            .classed(Classes.DRAWING_CONTAINER, true);
        this._skin = option.skin;
        this.watch('skin', () => {
            this.__setStyles();
        });
        this._scales = option.scales;
        this._styles = option.styles;
        this._isMoving = false;
        this._findNearestPoints = option.findNearestPoints;
        this._option = utils.extend(
            true,
            {
                drawings: {
                    callOut: {
                        checkWidth: 30,
                        width: 80,
                        height: 37,
                        radius: 2,
                        padding: 8
                    },

                    text: {
                        width: 70,
                        height: 30
                    },
                    drawingsType: ''
                }
            },
            option
        );
        this._lock = false;
        if (this._option.drawings.drawingsType) {
            this._drawingType = this._option.drawings.drawingsType;
        }
        this.uniqueId = utils.guid();
    }

    update(option) {
        this._scales = option.scales;
        this._xScale = this._scales.scale('x');
        this._y1Scale = this._scales.scale('y1');
        this._width = this._xScale.range()[1] - this._xScale.range()[0];
        this._height = this._y1Scale.range()[0] - this._y1Scale.range()[1];
        this.__reDraw();
        if (this._lock) {
            this.lock();
        }
    }
    __generateId(id) {
        const countId = this._id++;
        return id || `default-${countId}`;
    }
    setDrawings(drawings) {
        const dataManager = this.get('dataManager');
        if (dataManager) {
            const currentDrawings = dataManager.drawings();
            let needRedraw = false;
            let needTriggerDrawEnd = false;
            for (let i = 0; i < drawings.length; i++) {
                const drawObj = drawings[i];
                if (!drawObj.id) {
                    needTriggerDrawEnd = true;
                }
                drawObj.id = this.__generateId(drawObj.id);
                const index = utils.findIndex(
                    currentDrawings,
                    item => item.id === drawObj.id
                );
                if (index < 0) {
                    needRedraw = true;
                }
            }
            if (needRedraw) {
                dataManager.drawings(drawings);
                this.__reDraw();
            }
            if (needTriggerDrawEnd) {
                this.end();
            }
        }
    }
    getVisibleDrawings() {
        return this.drawings;
    }
    start(
        { dataPoint, position, drawType, id, text, editText, edit },
        redraw = false
    ) {
        this._isDrawing = true;
        const xScale = this._xScale;
        const x = xScale(dataPoint.index);
        const y = position[1];
        this._drawId = this.__generateId(id);
        const yScale = this._scales.scale(dataPoint.yAxis);
        const drawObj = {
            drawType,
            id: this._drawId,
            editText,
            edit,
            text,
            start: {
                x,
                y,
                index: dataPoint.index,
                yAxis: dataPoint.yAxis,
                date: dataPoint.date,
                yValue: isNaN(dataPoint.yValue)
                    ? yScale.invert(y)
                    : dataPoint.yValue
            }
        };
        this.drawings.push(drawObj);
        let group = this._container
            .append('g')
            .attr('draw-id', `draw-${this._drawId}`)
            .classed(Classes.DRAWING_HIGHLIGHT, true);
        drawObj.dom = group;

        switch (drawType) {
            case DRAWINGS.TREND_LINE: {
                group.classed(Classes.TREND_LINE, true);
                this.__createStartEnd({ x, y, dom: group });
                group
                    .append('line')
                    .classed(Classes.DRAWING_MASK, true)
                    .style(this._styles.draw)
                    .attr('mask', `url(#mask-${this._drawId})`);
                break;
            }
            case DRAWINGS.LEVEL_LINE: {
                group.classed(Classes.LEVEL_LINE, true);
                group
                    .append('rect')
                    .classed(Classes.DRAWING_MASK, true)
                    .classed(Classes.DRAWING_RECT_LINE, true)
                    .style({
                        fill: this._styles.draw.stroke,
                        stroke: this._styles.draw.stroke,
                        'stroke-width': 0
                    });

                this.drawing({ dataPoint, position, id: this._drawId });
                if (!redraw) {
                    this.end();
                }
                break;
            }
            case DRAWINGS.RECTANGLE: {
                group.classed(Classes.RECTANGLE, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                group
                    .append('rect')
                    .classed(Classes.DRAWING_MASK, true)
                    .style({
                        fill: this._styles.draw.stroke,
                        'fill-opacity': 0.2,
                        stroke: this._styles.draw.stroke
                    })
                    .attr('mask', `url(#mask-${this._drawId})`);
                this.__appendClose(group);
                break;
            }
            case DRAWINGS.ELLIPSE: {
                group.classed(Classes.ELLIPSE, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                group
                    .append('ellipse')
                    .classed(Classes.DRAWING_MASK, true)
                    .style({
                        fill: this._styles.draw.stroke,
                        'fill-opacity': 0.2,
                        stroke: this._styles.draw.stroke
                    })
                    .attr('mask', `url(#mask-${this._drawId})`);
                this.__appendClose(group);
                break;
            }
            case DRAWINGS.FIB_TIMEZONE: {
                group.classed(Classes.FIB_TIMEZONE, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                group
                    .append('line')
                    .classed(Classes.DRAWING_MASK, true)
                    .style(this._styles.draw)
                    .attr('mask', `url(#mask-${this._drawId})`);
                this.__appendClose(group);
                break;
            }
            case DRAWINGS.FIB_LEVEL: {
                group.classed(Classes.FIB_LEVEL, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                group
                    .append('line')
                    .classed(Classes.DRAWING_MASK, true)
                    .style(
                        utils.extend(true, {}, this._styles.draw, {
                            'stroke-dasharray': 8,
                            stroke: '#ababab'
                        })
                    )
                    .attr('mask', `url(#mask-${this._drawId})`);
                FIB.forEach((f, index) => {
                    const number = f * 100;
                    const rect = group
                        .append('rect')
                        .style({
                            fill: this._styles.draw.stroke,
                            stroke: this._styles.draw.stroke,
                            'stroke-width': 0
                        })
                        .attr({
                            height: index === 0 || index === 4 ? 2 : 1,
                            class: `${Classes.FIB_LEVEL}-line ${Classes.FIB_LEVEL}-line-${index}`
                        });
                    if (index === 0 || index === 4) {
                        rect.attr('mask', `url(#mask-${this._drawId})`).classed(
                            Classes.DRAWING_MASK,
                            true
                        );
                    }
                    group
                        .append('text')
                        .style({ fill: this._styles.draw.stroke })
                        .classed(
                            `${Classes.FIB_LEVEL}-text-${index}--left`,
                            true
                        )
                        .text(`${this._option.drawings.tagFormat(number)}%`);
                    group
                        .append('text')
                        .classed(
                            `${Classes.FIB_LEVEL}-text-${index}--right`,
                            true
                        )
                        .text(`${this._option.drawings.tagFormat(number)}%`)
                        .style({ fill: this._styles.draw.stroke });
                });
                this.__appendClose(group);
                break;
            }
            case DRAWINGS.FIB_FANS: {
                group.classed(Classes.FIB_FANS, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                this.__appendClose(group);
                break;
            }
            case DRAWINGS.FIB_ARCS: {
                group.classed(Classes.FIB_ARCS, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                group
                    .append('line')
                    .classed(Classes.DRAWING_MASK, true)
                    .style(this._styles.draw)
                    .attr('mask', `url(#mask-${this._drawId})`);
                group
                    .append('clipPath')
                    .attr({
                        id: `${Classes.FIB_ARCS}-clip`
                    })
                    .append('rect')
                    .attr({
                        x: xScale.range()[0],
                        y: 0,
                        width: this._width,
                        height: this._height
                    });
                this.__appendClose(group);
                group.attr({ 'clip-path': `url(#${Classes.FIB_ARCS}-clip)` });
                break;
            }
            case DRAWINGS.TEXT:
                group.classed(Classes.DRAWING_TEXT, true);
                group
                    .append('rect')
                    .classed(`${Classes.DRAWING_TEXT}-rect`, true)
                    .style(this._styles.draw)
                    .style({
                        visibility: 'hidden'
                    });
                group.append('text');
                this.__appendClose(group);
                this.drawing({ dataPoint, position, id: this._drawId }, redraw);
                break;
            case DRAWINGS.CALL_OUT:
                group.classed(Classes.DRAWING_CALL_OUT, true);
                this.__createStartEnd({ x, y, dom: group, drawType });
                this.__appendClose(group, {
                    fill: '#fff',
                    stroke: '#fff'
                });
                // this.drawing({ dataPoint, position, id: this._drawId });
                break;
        }
    }

    drawing({ dataPoint, position, id }, redraw) {
        const xScale = this._scales.scale('x');
        const yScale = this._scales.scale(dataPoint.yAxis);
        const x = xScale(dataPoint.index);
        if (!id) {
            id = this._drawId;
        }
        const current = utils.find(this.drawings, item => item.id === id);
        if (current) {
            current.end = {
                x,
                y: position[1],
                date: dataPoint.date,
                index: dataPoint.index,
                yAxis: dataPoint.yAxis,
                yValue: isNaN(dataPoint.yValue)
                    ? yScale.invert(position[1])
                    : dataPoint.yValue
            };
            this.__drawing(current, redraw);
        }
    }

    end(external = true) {
        if (external) {
            const savedDrawings = this.__getSavedDrawings();
            this.trigger(EVENTS.DrawEnd, savedDrawings);
            const dataManager = this.get('dataManager');
            dataManager.drawings(savedDrawings);
            this._drawingType = '';
        }
        if (this._lock) {
            this.__hideStartEndPoints();
        }
        this._isDrawing = false;
        this._editType = '';
    }
    changeDrawingsType(id) {
        if (this._isDrawing && this._drawId) {
            if (
                this._drawingType === DRAWINGS.TEXT ||
                this._drawingType === DRAWINGS.CALL_OUT ||
                this._editType === DRAWINGS.TEXT ||
                this._editType === DRAWINGS.CALL_OUT
            ) {
                const container = document.querySelector(
                    `.text-container-${this._drawId}`
                );
                if (container) {
                    const closeButton = container.querySelector(
                        `.${Classes.DRAWING}-close-button`
                    );
                    if (closeButton) {
                        closeButton.click();
                    }
                } else {
                    const idx = utils.findIndex(
                        this.drawings,
                        d => d.id === this._drawId
                    );
                    if (idx > -1) {
                        this.drawings.splice(idx, 1);
                        this._el
                            .select(`g[draw-id="draw-${this._drawId}"]`)
                            .remove();
                    }
                    this.end(false);
                }
            } else {
                const idx = utils.findIndex(
                    this.drawings,
                    d => d.id === this._drawId
                );
                if (idx > -1) {
                    this.drawings.splice(idx, 1);
                    this._el
                        .select(`g[draw-id="draw-${this._drawId}"]`)
                        .remove();
                }
                this.end(false);
            }
        }
        if (
            id === 'noTool' ||
            id === 'close' ||
            id === 'trash' ||
            id === 'lock' ||
            id === 'unLock'
        ) {
            this._drawingType = '';
            if (id === 'lock') {
                this.lock();
            } else if (id === 'unLock') {
                this.unLock();
            } else if (id === 'trash') {
                this.remove();
            }
        } else {
            this._drawingType = id;
        }
    }
    __exchangeStartEnd(draw) {
        const start = utils.extend(true, {}, draw.start);
        const end = utils.extend(true, {}, draw.end);
        if (draw.start.x > draw.end.x) {
            draw.end = start;
            draw.start = end;
        }
    }

    __appendClose(dom, style) {
        const close = dom
            .append('svg')
            .style(utils.extend(true, {}, this._styles.draw.close, style))
            .attr({
                focusable: 'false',
                xmlns: 'http://www.w3.org/2000/svg',
                viewBox: '0 0 15 15',
                width: 15,
                height: 15,
                class: Classes.CLOSE
            });
        close.append('path').attr({
            d: 'M3 3l9 9m0-9l-9 9'
        });
        close.style({
            display: 'none'
        });
        return close;
    }

    __checkOnClose(position, remove = false) {
        if (this._lock) {
            return false;
        }
        for (let i = 0; i < this.drawings.length; i++) {
            const draw = this.drawings[i];
            const closeEl = draw.dom.select(`.${Classes.CLOSE}`);
            if (!closeEl[0][0]) {
                continue;
            }
            const [x, y] = [closeEl.attr('x'), closeEl.attr('y')];
            if (
                position[0] - x <= 15 &&
                position[0] - x >= 0 &&
                position[1] - y <= 15 &&
                position[1] - y >= 0
            ) {
                if (remove) {
                    this.drawings.splice(i, 1);
                    draw.dom.remove();
                    const allDrawings = this.get('dataManager').drawings();
                    const idx = utils.findIndex(
                        allDrawings,
                        item => item.id === draw.id
                    );
                    if (idx > -1) {
                        allDrawings.splice(idx, 1);
                        this.get('dataManager').drawings(allDrawings);
                    }
                    this.end();
                }
                return true;
            }
        }
    }

    __clickWhenMoving({ position }) {
        const target = utils.find(
            this.drawings,
            item => item.id === this._movingDrawId
        );
        this._isMoving = false;
        this.get('el').style({
            cursor: 'default'
        });
        if (target && target.movingInfo) {
            if (
                target.drawType === DRAWINGS.TEXT ||
                target.drawType === DRAWINGS.CALL_OUT
            ) {
                const startPosition = target.movingInfo.startPosition;
                delete target.movingInfo;
                this._movingDrawId = null;
                if (
                    Math.abs(startPosition[0] - position[0]) <= 1 &&
                    Math.abs(startPosition[1] - position[1]) <= 1
                ) {
                    this._isDrawing = true;
                    this._drawId = target.id;
                    this._editType = target.drawType;
                    if (target.drawType === DRAWINGS.TEXT) {
                        this.__drawText(target, false);
                    } else if (target.drawType === DRAWINGS.CALL_OUT) {
                        target.edit = true;
                        this.__drawCallOut(target, false);
                    }
                    return;
                }
            }
        }
        this.end();
    }

    click({ dataPoint, position }) {
        if (this._isDrawing || this._isMoving) {
            if (this._isDrawing) {
                const target = utils.find(
                    this.drawings,
                    item => item.id === this._drawId
                );
                if (target.drawType === DRAWINGS.TEXT) {
                    return;
                } else if (!target.end) {
                    this.end();
                    this.__hideStartEndPoints();
                } else if (target.drawType === DRAWINGS.CALL_OUT) {
                    target.edit = true;
                    this.__drawCallOut(target, false);
                } else {
                    this.end();
                }
            } else {
                this.__clickWhenMoving({ position });
            }
        } else {
            const isClose = this.__checkOnClose(position, true);
            if (isClose) {
                return;
            }
            if (this._drawingType) {
                this.start({
                    dataPoint,
                    position,
                    drawType: this._drawingType
                });
            }
        }
    }
    mousedown({ position }) {
        if (this._isDrawing) {
            return;
        }
        this.mousemove({ position, triggeredByMousedown: true });
    }

    touchStart({ position }) {
        this.touchStartPosition = [position[0], position[1]];
        this.mousedown({ position });
        if (this._drawingType || this._isMoving || this._isDrawing) {
            d3.event.preventDefault();
        }
    }

    touchMove({ position, dataPoint }) {
        if (
            Math.abs(this.touchStartPosition[0] - position[0]) > 2 ||
            Math.abs(this.touchStartPosition[1] - position[1]) > 2
        ) {
            if (this._isMoving) {
                this.__move({ position, dataPoint });
            }
        }
    }

    touchEnd({ dataPoint, position }) {
        if (
            Math.abs(this.touchStartPosition[0] - position[0]) > 2 ||
            Math.abs(this.touchStartPosition[1] - position[1]) > 2
        ) {
            if (this._isMoving) {
                this.__move({ position, dataPoint });
                this.click({ position, dataPoint });
            }
        }
    }

    lock() {
        this._lock = true;
        this._el.selectAll(`.${Classes.CLOSE}`).style({ display: 'none' });
    }

    unLock() {
        this._lock = false;
        this._el.selectAll(`.${Classes.CLOSE}`).style({ display: '' });
    }
    clearDrawings() {
        const dataManager = this.get('dataManager');
        dataManager.drawings([]);
        this.drawings = [];
        this.__clear();
    }
    remove() {
        const dataManager = this.get('dataManager');
        const allDrawings = dataManager.drawings();
        for (let i = 0; i < allDrawings.length; i++) {
            const idx = utils.inArray(
                this.drawings,
                item => item.id === allDrawings[i].id
            );
            if (idx > -1) {
                allDrawings.splice(i, 1);
                i--;
            }
        }
        dataManager.drawings(allDrawings);
        this.__clear();
        this.end();
    }
    __hideStartEndPoints() {
        this._el
            .selectAll(`.${Classes.DRAWING_HIGHLIGHT}`)
            .classed(Classes.DRAWING_HIGHLIGHT, false);
        this._el.selectAll('.drawing-point').style({ display: 'none' });
        this._el.selectAll(`.${Classes.DRAWING_MASK}`).attr('mask', null);
        this._el
            .selectAll(`.${Classes.TREND_LINE} .${Classes.DRAWING_TAG}`)
            .attr('transform', 'translate(-5, 0)');
        this._el
            .selectAll(`.${Classes.DRAWING_HIGHLIGHT}`)
            .classed(Classes.DRAWING_HIGHLIGHT, false);
    }

    __getSavedDrawings() {
        const current = [];
        this.drawings.forEach(d => {
            //  const yScale = this._scales.scale(d.start.yAxis);
            const isText = d.start && d.drawType === DRAWINGS.TEXT && d.text;
            const isCallOut =
                d.start && d.end && d.drawType === DRAWINGS.CALL_OUT && d.text;
            if (
                (d.drawType !== DRAWINGS.CALL_OUT &&
                    d.start &&
                    d.end &&
                    d.drawType) ||
                isCallOut ||
                isText
            ) {
                const obj = {
                    start: {
                        date: d.start.date,
                        yValue: d.start.yValue
                    },
                    end: {
                        date: isText ? d.start.date : d.end.date,
                        yValue: isText ? d.start.yValue : d.end.yValue
                    },
                    id: d.id,
                    drawType: d.drawType
                };
                if (isText || isCallOut) {
                    obj.text = d.text;
                }
                current.push(obj);
            }
        });
        const dataManager = this.get('dataManager');
        const allDrawings = dataManager.drawings();
        current.forEach(obj => {
            const idx = utils.findIndex(
                allDrawings,
                item => item.id === obj.id
            );
            if (idx > -1) {
                allDrawings[idx] = obj;
            } else if (obj.start && obj.end) {
                allDrawings.push(obj);
            }
        });
        return allDrawings;
    }
    // date could be '0' means start , '-1' means end, or index,  or date string like '2021-02-21'
    __findDataPointByDateOrIndex(param) {
        const dataManager = this.get('dataManager');
        if (!dataManager) {
            return null;
        }
        const dataPoints = [];
        ['y1', 'y2'].forEach(yAxisName => {
            const dataSet = dataManager.series(yAxisName);
            if (dataSet) {
                let yDataSet = dataSet.filter(
                    s => s.get('graphType') !== GraphTypes.EVENT
                );
                const series = yDataSet[0].get('series');
                if (!isNaN(param)) {
                    if (param === -1) {
                        param = series.length - 1;
                    }
                    param = Math.max(param, 0);
                    param = Math.min(param, series.length - 1);
                    const dp = series[param];
                    dp.yAxis = yAxisName;
                    dataPoints.push(dp);
                } else {
                    for (let i = 0; i < series.length; i++) {
                        if (series[i].date === param) {
                            series[i].yAxis = yAxisName;
                            dataPoints.push(series[i]);
                            return;
                        }
                    }
                }
            }
        });
        return dataPoints[0];
    }
    __clear() {
        this.drawings = [];
        if (this._drawingsContainer) {
            this._drawingsContainer.remove();
        }
        this._drawingsContainer = null;
        this.get('el')
            .select(`.${Classes.DRAWING_CONTAINER}`)
            .selectAll('*')
            .remove();
    }
    __reDraw() {
        const xScale = this._scales.scale('x');
        const drawings = this.__generateDrawings();
        const oldDrawings = utils.extend(true, [], this.drawings);
        this.__clear();
        const isDrawing = this._isDrawing;
        const drawingsId = this._drawId;
        drawings.forEach(drawObj => {
            const isDrawingObj = isDrawing && drawingsId === drawObj.id;

            this.start(
                {
                    dataPoint: drawObj.start,
                    position: [xScale(drawObj.start.index), drawObj.start.y],
                    drawType: drawObj.drawType,
                    id: drawObj.id,
                    text: drawObj.text,
                    editText: drawObj.editText,
                    edit:
                        isDrawingObj &&
                        drawObj.drawType === DRAWINGS.CALL_OUT &&
                        drawObj.edit
                },
                true
            );
            this.drawing(
                {
                    dataPoint: drawObj.end,
                    position: [xScale(drawObj.end.index), drawObj.end.y],
                    drawType: drawObj.drawType,
                    id: drawObj.id,
                    text: drawObj.text,
                    editText: drawObj.editText
                },
                isDrawingObj &&
                    (drawObj.drawType === DRAWINGS.TEXT ||
                        drawObj.drawType === DRAWINGS.CALL_OUT)
                    ? false
                    : true
            );
            if (!isDrawingObj) {
                this.end(false);
                this.__hideStartEndPoints();
            }
        });
        if (!drawings.length) {
            this._isDrawing = false;
            this._drawId = '';
        }
        if (this._isMoving) {
            const moveDraw = utils.find(oldDrawings, d => d.movingInfo);
            if (moveDraw) {
                const target = utils.find(
                    this.drawings,
                    d => moveDraw.id === d.id
                );
                if (target) {
                    target.movingInfo = moveDraw.movingInfo;
                    target.dom.classed(Classes.DRAWING_HIGHLIGHT, true);
                    if (target.drawType === DRAWINGS.TEXT) {
                        target.dom.select('rect').style({
                            visibility: 'visible'
                        });
                    } else if (target.drawType === DRAWINGS.callOut) {
                        target.dom
                            .select(`.${Classes.CALL_OUT}-path`)
                            .style(this._styles.draw.callOut.hover);
                    }
                }
            }
        }
    }
    __generateDrawings() {
        const dataManager = this.get('dataManager');
        if (!dataManager) {
            return [];
        }
        const drawings = dataManager.drawings();
        if (this._isDrawing && this._drawId) {
            const drawObj = utils.find(
                this.drawings,
                d => d.id === this._drawId
            );
            const idx = utils.findIndex(drawings, d => d.id === this._drawId);
            if (idx > -1) {
                drawings.splice(idx, 1, drawObj);
            } else {
                drawings.push(drawObj);
            }
        }
        const data = [];
        drawings.forEach(d => {
            const isMovingObj = this._isMoving && this._movingDrawId === d.id;
            if (isMovingObj) {
                const moveDraw = utils.find(
                    this.drawings,
                    item => item.id === this._movingDrawId
                );
                if (d && moveDraw) {
                    d.start = moveDraw.start;
                    d.end = moveDraw.end;
                }
            }
            if (d && d.start && d.end) {
                const startDataPoint = this.__findDataPointByDateOrIndex(
                    d.drawType === DRAWINGS.LEVEL_LINE ? '0' : d.start.date
                );
                const endDataPoint = this.__findDataPointByDateOrIndex(
                    d.drawType === DRAWINGS.LEVEL_LINE ? '-1' : d.end.date
                );
                if (startDataPoint && endDataPoint) {
                    const yScale = this._scales.scale(startDataPoint.yAxis);
                    const startY = yScale(d.start.yValue);
                    const endY = yScale(d.end.yValue);
                    const maxY = yScale.range()[0];
                    const minY = yScale.range()[1];
                    if (
                        startY >= minY &&
                        startY <= maxY &&
                        endY >= minY &&
                        endY <= maxY
                    ) {
                        data.push({
                            id: d.id,
                            drawType: d.drawType,
                            text: d.text,
                            editText: d.editText,
                            edit: d.edit,
                            start: {
                                date: startDataPoint.date,
                                index: startDataPoint.index,
                                yAxis: startDataPoint.yAxis,
                                y: startY,
                                yValue: d.start.yValue
                            },
                            end: {
                                date: endDataPoint.date,
                                index: endDataPoint.index,
                                yAxis: endDataPoint.yAxis,
                                yValue: d.end.yValue,
                                y: endY
                            }
                        });
                    } else if (this._isDrawing && this._drawId === d.id) {
                        this._isDrawing = false;
                        this._drawId = '';
                    }
                }
            }
        });
        return data;
    }
    __drawing(drawObj, redraw) {
        switch (drawObj.drawType) {
            case DRAWINGS.TREND_LINE: {
                this.__drawTrendLine(drawObj);
                break;
            }
            case DRAWINGS.LEVEL_LINE: {
                this.__drawLevelLine(drawObj);
                break;
            }
            case DRAWINGS.RECTANGLE: {
                this.__drawRectangle(drawObj);
                break;
            }
            case DRAWINGS.ELLIPSE: {
                this.__drawEllipse(drawObj);
                break;
            }
            case DRAWINGS.FIB_TIMEZONE: {
                this.__drawFibTimezone(drawObj);
                break;
            }
            case DRAWINGS.FIB_LEVEL: {
                this.__drawFibLevel(drawObj);
                break;
            }
            case DRAWINGS.FIB_FANS: {
                this.__drawFibFans(drawObj);
                break;
            }
            case DRAWINGS.FIB_ARCS:
                this.__drawFibArcs(drawObj);
                break;
            case DRAWINGS.TEXT:
                this.__drawText(drawObj, redraw);
                break;
            case DRAWINGS.CALL_OUT:
                this.__drawCallOut(drawObj, redraw);
                break;
        }
    }
    __drawTag({ dom, data, format, yScale, offset, position }) {
        let tagDom = dom.selectAll(`.${Classes.DRAWING_TAG}`);
        if (tagDom) {
            tagDom.remove();
        }
        tagDom = dom.append('g').classed(`${Classes.DRAWING_TAG}`, true);
        const tagArgs = {
            selection: tagDom,
            xScale: this._xScale,
            xValue: d => d.index,
            yValue: d => d.yValue,
            yScaleHeight: yScale,
            yScale,
            styles: utils.extend(true, {}, this._styles.tag, {
                rect: {
                    fill: this._styles.draw.stroke
                }
            }),
            data
        };
        tagArgs.options = utils.extend(true, {}, this._option.tag, {
            offset,
            format,
            position,
            yaxisOrient: this._option.y1axis.orient,
            showClose: true
        });
        return drawTag(tagArgs);
    }

    __drawLine(drawObj) {
        const dom = drawObj.dom;
        let rect = dom.selectAll(`.${Classes.DRAWING_RECT_LINE}`);
        const line = dom.select(`line.${Classes.DRAWING_MASK}`);
        const x1 = drawObj.start.x;
        const y1 = drawObj.start.y;
        const x2 = drawObj.end.x;
        const y2 = drawObj.end.y;
        if (x1 === x2 || y1 === y2) {
            line.style({
                display: 'none'
            });
            if (!rect[0].length) {
                rect = dom
                    .append('rect')
                    .classed(Classes.DRAWING_MASK, true)
                    .classed(Classes.DRAWING_RECT_LINE, true)
                    .style({
                        fill: this._styles.draw.stroke,
                        stroke: this._styles.draw.stroke,
                        'stroke-width': 0
                    })
                    .attr('mask', `url(#mask-${drawObj.id})`);
            }
            rect.style({
                display: ''
            });
            if (drawObj.start.x === drawObj.end.x) {
                rect.attr({
                    x: x1,
                    y: Math.min(y1, y2),
                    width: 1,
                    height: Math.abs(y2 - y1)
                });
            } else {
                rect.attr({
                    x: Math.min(x1, x2),
                    y: y1,
                    height: 1,
                    width: Math.abs(x2 - x1)
                });
            }
        } else {
            if (rect) {
                rect.style({
                    display: 'none'
                });
            }

            line.style({
                display: ''
            });
            line.attr({
                x1: x1,
                y1: y1,
                x2: x2,
                y2: y2
            });
        }
        dom.selectAll('.drawing-point--start').attr({
            cx: x1,
            cy: y1
        });
        dom.selectAll('.drawing-point--end').attr({
            cx: x2,
            cy: y2
        });
    }

    __drawTrendLine(drawObj) {
        const dom = drawObj.dom;
        const yScale = this._scales.scale(drawObj.start.yAxis);
        let y1, y2;
        if (drawObj.start.x <= drawObj.end.x) {
            y1 = drawObj.start.yValue;
            y2 = drawObj.end.yValue;
        } else {
            y1 = drawObj.end.yValue;
            y2 = drawObj.start.yValue;
        }
        this.__drawLine(drawObj);
        this.__drawTag({
            dom,
            yScale,
            offset: 5,
            data: [
                {
                    index: drawObj.end.index,
                    date: drawObj.end.date,
                    value: ((y2 - y1) / y1) * 100,
                    yValue: drawObj.end.yValue
                }
            ],
            format: value => {
                return `${this._option.drawings.tagFormat(value)}%`;
            }
        });
        if (this._lock) {
            dom.select(`.${Classes.CLOSE}`).style({ display: 'none' });
        }
    }

    __drawLevelLine(drawObj) {
        const dom = drawObj.dom;
        const yScale = this._scales.scale(drawObj.start.yAxis);
        const startX = this._xScale.range()[0];
        const endX = this._xScale.range()[1];
        dom.select(`.${Classes.DRAWING_RECT_LINE}`)
            .attr('x', startX)
            .attr('y', drawObj.start.y)
            .attr('height', 1)
            .attr('width', endX - startX);
        this.__drawTag({
            dom,
            yScale,
            offset: -70,
            position: 'top-left',
            data: [
                {
                    index: this._xScale.domain()[1],
                    value: drawObj.start.yValue,
                    yValue: drawObj.start.yValue
                }
            ],
            format: value => {
                return `${this._option.drawings.tagFormat(value)}`;
            }
        });
        if (this._lock) {
            dom.select(`.${Classes.CLOSE}`).style({ display: 'none' });
        }
    }

    __drawRectangle(drawObj) {
        const dom = drawObj.dom;
        const rect = dom.select(`rect.${Classes.DRAWING_MASK}`);
        const x = Math.min(drawObj.start.x, drawObj.end.x);
        const y = Math.min(drawObj.start.y, drawObj.end.y);
        rect.attr({
            x: x,
            y: y,
            width: Math.abs(drawObj.end.x - drawObj.start.x),
            height: Math.abs(drawObj.end.y - drawObj.start.y)
        });

        dom.selectAll('.drawing-point--start').attr({
            cx: drawObj.start.x,
            cy: drawObj.start.y
        });
        dom.selectAll('.drawing-point--end').attr({
            cx: drawObj.end.x,
            cy: drawObj.end.y
        });

        dom.selectAll('.drawing-point--resize-left').attr({
            cx: drawObj.start.x,
            cy: drawObj.end.y
        });
        dom.selectAll('.drawing-point--resize-right').attr({
            cx: drawObj.end.x,
            cy: drawObj.start.y
        });
        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: Math.max(drawObj.start.x, drawObj.end.x) - 20,
                y: Math.min(drawObj.start.y, drawObj.end.y) + 5
            })
            .style({ display: this._lock ? 'none' : '' });
    }

    __drawEllipse(drawObj) {
        const dom = drawObj.dom;
        const ellipse = dom.select(`ellipse.${Classes.DRAWING_MASK}`);
        const x = Math.min(drawObj.start.x, drawObj.end.x);
        const y = Math.min(drawObj.start.y, drawObj.end.y);
        const rx = Math.abs(drawObj.end.x - drawObj.start.x) / 2;
        const ry = Math.abs(drawObj.end.y - drawObj.start.y) / 2;
        const cx = rx + x;
        const cy = ry + y;
        ellipse.attr({
            cx,
            cy,
            rx,
            ry
        });

        dom.selectAll('.drawing-point--start').attr({
            cx: rx + x,
            cy: y
        });
        dom.selectAll('.drawing-point--end').attr({
            cx: rx + x,
            cy: Math.max(drawObj.start.y, drawObj.end.y)
        });

        dom.selectAll('.drawing-point--resize-left').attr({
            cx: x,
            cy: ry + y
        });
        dom.selectAll('.drawing-point--resize-right').attr({
            cx: Math.max(drawObj.start.x, drawObj.end.x),
            cy: ry + y
        });
        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: cx + rx - 20,
                y: cy - 5
            })
            .style({ display: this._lock ? 'none' : '' });
    }

    __drawFibTimezone(drawObj) {
        const dom = drawObj.dom;
        const yScale = this._scales.scale(drawObj.start.yAxis);
        const width = this._xScale.range()[1] - this._xScale.range()[0];
        this.__drawLine(drawObj);
        const timeZoneLines = this.__getFibonacciTimeZoneX(
            drawObj.start.x,
            drawObj.end.x,
            width
        );
        const height = yScale.range()[0] - yScale.range()[1];
        dom.selectAll(`.${Classes.FIB_TIMEZONE_LINES}`).remove();
        dom.selectAll('text').remove();
        timeZoneLines.forEach((line, index) => {
            const rect = dom
                .append('rect')
                .attr({
                    x: line.x,
                    y: 0,
                    width: 1,
                    height,
                    class: Classes.FIB_TIMEZONE_LINES
                })
                .style({
                    fill: this._styles.draw.stroke,
                    stroke: this._styles.draw.stroke,
                    'stroke-width': 0
                });
            if (index === 0 || index === 1) {
                rect.classed(Classes.DRAWING_MASK, true).attr(
                    'mask',
                    `url(#mask-${drawObj.id})`
                );
            }
            dom.append('text')
                .text(line.n)
                .style({
                    fill: this._styles.draw.stroke
                })
                .attr({
                    x: line.x + 5,
                    y: 15
                });
        });

        let closeX = timeZoneLines[timeZoneLines.length - 1].x + 16;
        let closeY = 2;
        const maxX = this._xScale.range()[1];
        if (closeX + 15 > maxX) {
            closeX = maxX - 15;
            closeY = 15;
        }

        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: closeX,
                y: closeY
            })
            .style({ display: this._lock ? 'none' : '' });
    }

    __drawFibLevel(drawObj) {
        const dom = drawObj.dom;
        const height = Math.abs(drawObj.end.y - drawObj.start.y);
        this.__drawLine(drawObj);
        const x1 = Math.min(drawObj.end.x, drawObj.start.x);
        const y1 = Math.min(drawObj.end.y, drawObj.start.y);
        const y2 = Math.max(drawObj.end.y, drawObj.start.y);
        const x2 = Math.max(drawObj.end.x, drawObj.start.x);
        const yValue1 = Math.min(drawObj.end.yValue, drawObj.start.yValue);
        const yValue2 = Math.max(drawObj.end.yValue, drawObj.start.yValue);
        const tagFormat = this._option.drawings.tagFormat;

        FIB.forEach((number, index) => {
            dom.select(`.${Classes.FIB_LEVEL}-line-${index}`).attr({
                x: x1,
                y:
                    drawObj.start.y < drawObj.end.y
                        ? y2 - height * number
                        : y2 - height * FIB[FIB.length - 1 - index],
                width: x2 - x1
            });

            let textSpace = 15;
            if (index === 0) {
                textSpace = -5;
            } else if (index === 4) {
                textSpace = 20;
            }
            dom.select(`.${Classes.FIB_LEVEL}-text-${index}--left`)
                .attr({
                    x: x1 + 5,
                    y:
                        drawObj.start.y < drawObj.end.y
                            ? y2 - height * number + textSpace
                            : y2 -
                              height * FIB[FIB.length - 1 - index] +
                              textSpace
                })
                .text(`${tagFormat(number * 100)}%`);

            const text = tagFormat(yValue1 + (yValue2 - yValue1) * number);
            const textWidth = getSVGTextWidth(dom, text);
            dom.select(`.${Classes.FIB_LEVEL}-text-${index}--right`)
                .attr({
                    x: x2 - textWidth - 8,
                    y: y2 - height * number + textSpace
                })
                .text(text);
        });
        const closeX = Math.min(x2, this._xScale.range()[1] - 10);
        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: closeX,
                y: y1 + 5
            })
            .style({ display: this._lock ? 'none' : '' });
    }

    __getFibFansLines(drawObj) {
        const x1 = drawObj.start.x,
            y1 = drawObj.start.y,
            x2 = drawObj.end.x,
            y2 = drawObj.end.y;
        let k;
        if (x1 === x2 || y1 === y2) {
            k = 0;
        } else {
            k = (y2 - y1) / (x2 - x1);
        }
        const yScale = this._scales.scale(drawObj.start.yAxis);
        const [maxY, minY] = yScale.range();
        const [minX, maxX] = this._xScale.range();
        const height = maxY - minY;
        return FIB.map(number => {
            if (k === 0) {
                return {
                    x: x2,
                    y: y2 < y1 ? minY : maxY
                };
            }
            const slop = k * (1 - number);
            let d = y2 > y1 ? height - y1 : minY - y1;
            if (slop === 0) {
                return {
                    x: x2 < x1 ? minX : maxX,
                    y: y1
                };
            }
            let x = d / slop + x1;
            if (x1 < x2) {
                x = Math.min(x, maxX);
            } else {
                x = Math.max(x, minX);
            }
            d = x2 > x1 ? maxX - x1 : minX - x1;
            let y = slop * d + y1;
            if (y2 > y1) {
                y = Math.min(y, maxY);
            } else {
                y = Math.max(y, minY);
            }
            return {
                x,
                y
            };
        });
    }
    __drawFibFans(drawObj) {
        const dom = drawObj.dom;
        const lines = this.__getFibFansLines(drawObj);
        const x1 = drawObj.start.x,
            y1 = drawObj.start.y,
            y2 = drawObj.end.y,
            x2 = drawObj.end.x;

        dom.selectAll(`.${Classes.FIB_FANS}-line`).remove();
        lines.forEach((line, index) => {
            if (x1 === line.x || y1 === line.y) {
                let height, width;
                if (x1 === line.x) {
                    height = Math.abs(line.y - y1);
                    width = 1;
                } else {
                    height = 1;
                    width = Math.abs(line.x - x1);
                }
                dom.append('rect')
                    .attr({
                        class: `${Classes.DRAWING_MASK} ${Classes.FIB_FANS}-line ${Classes.FIB_FANS}-line-${index}`,
                        x: Math.min(x1, line.x),
                        y: Math.min(y1, line.y),
                        width,
                        height,
                        mask: `url(#mask-${drawObj.id})`
                    })
                    .style({
                        fill: this._styles.draw.stroke,
                        stroke: this._styles.draw.stroke,
                        'stroke-width': 0
                    });
            } else {
                dom.append('line')
                    .attr({
                        class: `${Classes.DRAWING_MASK} ${Classes.FIB_FANS}-line ${Classes.FIB_FANS}-line-${index}`,
                        x1,
                        y1,
                        mask: `url(#mask-${drawObj.id})`,
                        x2: line.x,
                        y2: line.y
                    })
                    .style(this._styles.draw);
            }
        });
        dom.selectAll('.drawing-point--start').attr({
            cx: drawObj.start.x,
            cy: drawObj.start.y
        });
        dom.selectAll('.drawing-point--end').attr({
            cx: drawObj.end.x,
            cy: drawObj.end.y
        });
        const yScale = this._scales.scale(drawObj.start.yAxis);
        const maxY = yScale.range()[0];
        const [minX, maxX] = this._xScale.range();
        let closeX,
            closeY = y1 + 5;
        if (x1 < x2) {
            closeX = Math.max(x1 - 15, minX);
            if ((y1 < y2 && y1 > 20) || (y1 > y2 && y1 + 20 > maxY)) {
                closeY = y1 - 20;
            }
        } else {
            closeX = Math.min(x1, maxX - 15);
            if ((y1 < y2 && y1 > 20) || (y1 > y2 && y1 + 20 > maxY)) {
                closeY = y1 - 20;
            }
        }
        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: closeX,
                y: closeY
            })
            .style({ display: this._lock ? 'none' : '' });
    }
    __drawFibArcs(drawObj) {
        const dom = drawObj.dom;
        dom.selectAll(
            `.${Classes.FIB_ARCS}-line,.${Classes.FIB_ARCS}-text`
        ).remove();
        this.__drawLine(drawObj);
        const x1 = drawObj.start.x,
            y1 = drawObj.start.y,
            y2 = drawObj.end.y,
            x2 = drawObj.end.x;
        const upDown = y2 >= y1 ? 'up' : 'down';
        const radius = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
        const tagFormat = this._option.drawings.tagFormat;
        FIB.forEach(number => {
            if (number === 0) {
                return;
            }
            const r = radius * number;
            const startX = x2 - r,
                startY = y2,
                endX = x2 + r,
                endY = y2;
            const sf = upDown === 'up' ? 1 : 0;
            const path = [
                'M',
                startX,
                startY,
                'A',
                r,
                r,
                0,
                0,
                sf,
                endX,
                endY
            ].join(' ');
            const pathEl = dom
                .append('path')
                .attr({
                    class: `${Classes.FIB_ARCS}-line`,
                    d: path
                })
                .style(this._styles.draw);
            const text = `${tagFormat(number * 100)}%`;
            const textWidth = getSVGTextWidth(dom, text);
            dom.append('text')
                .attr({
                    class: `${Classes.FIB_ARCS}-text`,
                    x: x2 - textWidth / 2,
                    y: upDown === 'up' ? y2 - r - 10 : y2 + r - 10
                })
                .text(text)
                .style({ fill: this._styles.draw.stroke });

            if (number === 1) {
                pathEl
                    .attr('mask', `url(#mask-${drawObj.id})`)
                    .classed(Classes.DRAWING_MASK, true);
            }
        });
        const yScale = this._scales.scale(drawObj.start.yAxis);
        const [maxY, minY] = yScale.range();
        const [minX, maxX] = this._xScale.range();
        let closeX, closeY;
        if (x2 < x1) {
            if (x2 - 20 < minX) {
                closeX = x2;
                closeY = y2 > y1 ? y2 + 7 : y2 - 20;
            } else {
                closeX = x2 - 20;
                closeY = y2 - 7;
            }
        } else {
            if (x2 + 20 > maxX) {
                closeX = maxX - 15;
                closeY = y2 > y1 ? y2 - 20 : y2 + 7;
            } else {
                closeX = x2 + 5;
                closeY = y2 - 7;
            }
        }
        if (closeY + 15 > maxY) {
            closeY = maxY - 15;
        } else if (closeY < minY) {
            closeY = minY;
        }
        dom.select(`.${Classes.CLOSE}`)
            .attr({
                x: closeX,
                y: closeY
            })
            .style({ display: this._lock ? 'none' : '' });
    }

    __getTextSize({
        dom,
        text = '',
        extraWidth = 30,
        padding = 12,
        minWidth = 40,
        minHeight = 30
    }) {
        const textArr = text.split('\n').map(t => {
            return {
                text: t
            };
        });
        const textWidth = getGreaterSVGTextWidth(dom, textArr);

        const width = Math.max(textWidth, minWidth) + extraWidth;
        const height = Math.max(textArr.length * 18 + padding, minHeight);
        return {
            width,
            textWidth,
            height
        };
    }

    __adjustTextPosition({
        x,
        y,
        width,
        height,
        yAxis = 'y1',
        onlyText = true
    }) {
        if (!onlyText) {
            // add check button and close button width
            width += 50;
        } else {
            // add padding and border
            width += 9;
        }
        const yScale = this._scales.scale(yAxis);
        const [maxY, minY] = yScale.range();
        const [minX, maxX] = this._xScale.range();
        if (y + height > maxY) {
            y = maxY - height;
        } else if (y < minY) {
            y = minY;
        }
        if (x + width > maxX) {
            x = maxX - width;
        } else if (x < minX) {
            x = minX;
        }
        return { x, y };
    }

    __drawText(drawObj, redraw = false) {
        const config = this._option.drawings.text;
        const dom = drawObj.dom;
        const rect = dom.select(`.${Classes.DRAWING_TEXT}-rect`);
        dom.selectAll('text').remove();
        const container = document.body.querySelector(
            `.${Classes.DRAWING_TEXT}-container-${drawObj.id}`
        );
        if (container) {
            container.remove();
        }
        drawObj.style = this._styles.draw.font;
        let x = drawObj.start.x,
            y = drawObj.start.y;
        let width = config.width,
            height = config.height;
        if (drawObj.text) {
            ({ width, height } = this.__getTextSize({
                dom,
                minWidth: 0,
                extraWidth: 25,
                text: drawObj.text
            }));
        }
        const onlyText = !!(redraw || (drawObj.movingInfo && this._isMoving));
        ({ x, y } = this.__adjustTextPosition({
            x,
            y,
            width,
            height,
            onlyText
        }));
        if (onlyText) {
            this.__appendText(
                utils.extend(true, {}, drawObj, {
                    start: {
                        x,
                        y
                    }
                })
            );
            return;
        }
        rect.attr({
            x: x,
            y: y,
            width,
            height
        }).style({
            visibility: 'hidden'
        });
        let { left, top } = utils.innerSizes(rect[0][0]);

        const closeDom = dom.select(`.${Classes.CLOSE}`);
        const color = this._styles.draw.stroke;
        const textContainer = this.__appendTextContainer({
            width: width,
            height: height,
            classes: `${Classes.DRAWING_TEXT}-container ${Classes.DRAWING_TEXT}-container-${drawObj.id}`,
            backgroundColor: color,
            borderColor: color,
            position: [left, top]
        });
        closeDom.style({
            display: 'none'
        });
        drawObj.edit = true;
        const textarea = textContainer.querySelector('textarea');
        const checkButton = textContainer.querySelector(
            `.${Classes.DRAWING}-check-button`
        );
        checkButton.ariaLabel = 'apply';
        const closeButton = textContainer.querySelector(
            `.${Classes.DRAWING}-close-button`
        );
        closeButton.ariaLabel = 'close';
        const adjustSize = text => {
            ({ width, height } = this.__getTextSize({
                dom,
                text: text
            }));
            textarea.style.width = `${width}px`;
            textarea.style.height = `${height}px`;
            textContainer.style.height = `${height}px`;
            ({ x, y } = this.__adjustTextPosition({
                x: drawObj.start.x,
                y: drawObj.start.y,
                width: width,
                height: height,
                onlyText: false
            }));
            rect.attr({
                x: x,
                y: y,
                width,
                height
            });
            ({ left, top } = utils.innerSizes(rect[0][0]));
            textContainer.style.top = `${top}px`;
            textContainer.style.left = `${left}px`;
        };
        const text = drawObj.editText || drawObj.text || '';
        if (text) {
            textarea.value = text;
            adjustSize(text);
        }
        textarea.addEventListener('input', e => {
            const target = e.target;
            drawObj.editText = target.value;
            adjustSize(target.value);
        });
        checkButton.addEventListener('click', e => {
            if (!utils.trim(textarea.value)) {
                return;
            }
            checkButton.style.display = 'none';
            if (!this._lock) {
                closeDom.style({
                    display: ''
                });
            }
            drawObj.text = textarea.value;
            drawObj.editText = '';
            ({ width, height } = this.__getTextSize({
                dom,
                minWidth: 0,
                extraWidth: 25,
                text: drawObj.text
            }));
            ({ x, y } = this.__adjustTextPosition({
                x: drawObj.start.x,
                y: drawObj.start.y,
                width,
                height,
                onlyText: true
            }));
            this.__appendText(
                utils.extend(true, {}, drawObj, {
                    start: {
                        x,
                        y
                    }
                })
            );
            textContainer.remove();
            this.end();
        });

        closeButton.addEventListener('click', e => {
            textContainer.remove();
            const idx = utils.findIndex(
                this.drawings,
                d => d.id === drawObj.id
            );
            if (!this.drawings[idx].text) {
                this.drawings.splice(idx, 1);
                dom.remove();
            } else {
                this.__appendText(drawObj);
                textContainer.remove();
            }
            drawObj.editText = '';
            this.end();
        });

        closeDom
            .attr({
                x: drawObj.start.x + width + 5,
                y: drawObj.start.y
            })
            .style({ display: this._lock ? 'none' : '' });
    }
    destroy() {
        this.__clear();
    }
    __appendTextContainer({
        classes,
        position,
        height,
        width,
        backgroundColor,
        borderColor
    }) {
        if (!this._drawingsContainer) {
            const el = document.createElement('div');
            document.body.appendChild(el);
            this._drawingsContainer = el;
        }
        const textContainer = document.createElement('div');
        textContainer.setAttribute(
            'class',
            `${classes} text-container-${this._drawId}`
        );
        textContainer.style = `left:${position[0]}px;top:${position[1]}px;position:absolute;height:${height}px`;
        let style = `width: ${width}px;`;
        if (borderColor) {
            style = `${style} border:solid 1px ${borderColor}`;
        }
        const closeColor = this._styles.draw.close.stroke;
        textContainer.innerHTML = `<div class="${Classes.DRAWING}-text-area-wrapper"><textarea style="${style}"></textarea>
        <button class="${Classes.DRAWING}-check-button" style="background:${backgroundColor}">
        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" >
        <path fill="none" vector-effect="non-scaling-stroke" stroke-miterlimit="10" d="M13 2L6 12 2 8" fill="#fff" stroke="#fff"></path>
        </svg></button></div><button class="${Classes.DRAWING}-close-button">
        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width="15" height="15">
        <path d="M3 3l9 9m0-9l-9 9" stroke="${closeColor}"></path></svg>
        </button>`;
        this._drawingsContainer.appendChild(textContainer);
        textContainer.querySelector('textarea').focus();
        return textContainer;
    }

    __appendText({ text = '', dom, start, style = {}, adjustClose = true }) {
        dom.selectAll('text').remove();
        text.split('\n').forEach((t, index) => {
            const el = dom
                .append('text')
                .attr({
                    x: start.x + 8,
                    y: start.y + index * 16 + 18
                })
                .style(
                    utils.extend(
                        true,
                        {
                            'user-select': 'none'
                        },
                        style
                    )
                );
            el[0][0].innerHTML = t.replace(/\s/g, '&nbsp;');
        });

        const { width, textWidth, height } = this.__getTextSize({
            dom,
            text,
            minWidth: 0,
            extraWidth: 25
        });
        dom.select(`.${Classes.DRAWING_TEXT}-rect`).attr({
            x: start.x,
            y: start.y,
            width: width + 8,
            height: height
        });
        if (adjustClose) {
            const closeDom = dom.select(`.${Classes.CLOSE}`);
            closeDom.attr({
                x: start.x + textWidth + 15,
                y: start.y + 5
            });
            closeDom.style({
                display: this._lock ? 'none' : ''
            });
        }
    }

    __getCalloutSize({ dom, text }) {
        const config = this._option.drawings.callOut;
        const dWidth = config.checkWidth + config.padding + config.radius;
        let callOutWidth, callOutHeight, width, height;
        if (text) {
            ({ width, height } = this.__getTextSize({
                dom,
                text: text,
                extraWidth: 10,
                minWidth: config.width - dWidth - 10,
                minHeight: config.height - config.padding * 2,
                padding: 0
            }));
            callOutWidth = width + dWidth;
            callOutHeight = height + config.padding * 2;
        } else {
            callOutWidth = config.width;
            callOutHeight = config.height;
            width = config.width - dWidth;
        }
        return {
            callOutWidth,
            callOutHeight,
            textareaWidth: width
        };
    }
    __getDataPointPosition(x, y, dom) {
        const circle = dom
            .append('circle')
            .attr({
                cx: x,
                cy: y,
                r: 1
            })
            .style({
                visibility: 'hidden'
            });
        const { left, top } = utils.innerSizes(circle[0][0]);
        circle.remove();
        return { left, top };
    }
    __adjustCallOutEndPoint({
        x2,
        y2,
        callOutWidth,
        callOutHeight,
        yAxis = 'y1'
    }) {
        const yScale = this._scales.scale(yAxis);
        const [maxY, minY] = yScale.range();
        const [minX, maxX] = this._xScale.range();
        if (y2 + callOutHeight / 2 > maxY) {
            y2 = maxY - callOutHeight / 2;
        } else if (y2 - callOutHeight / 2 < minY) {
            y2 = minY + callOutHeight / 2;
        }

        if (x2 + callOutWidth / 2 > maxX) {
            x2 = maxX - callOutWidth / 2;
        } else if (x2 - callOutWidth / 2 < minX) {
            x2 = minX + callOutWidth / 2;
        }
        return { x2, y2 };
    }
    __drawCallOut(drawObj, redraw = false) {
        const dom = drawObj.dom;
        dom.selectAll(`.${Classes.CALL_OUT}-path,text`).remove();
        const container = document.body.querySelector(
            `.${Classes.DRAWING_CALL_OUT}-container-${drawObj.id}`
        );
        if (container) {
            container.remove();
        }

        const isRedraw = redraw || (drawObj.movingInfo && this._isMoving);
        let drewPath = false;

        const x1 = drawObj.start.x,
            y1 = drawObj.start.y;
        let y2 = drawObj.end.y,
            x2 = drawObj.end.x;

        let {
            callOutWidth,
            callOutHeight,
            textareaWidth
        } = this.__getCalloutSize(drawObj);
        ({ x2, y2 } = this.__adjustCallOutEndPoint({
            x2,
            y2,
            callOutWidth,
            callOutHeight
        }));
        const radius = this._option.drawings.callOut.radius;
        const closeDom = dom.select(`.${Classes.CLOSE}`);
        const drawPath = ({ w, h, styles }) => {
            let path = this.__getCalloutPath({
                w,
                h,
                r: radius,
                x1,
                y1,
                x2,
                y2
            });
            dom.insert('path', FIRST_CHILD)
                .attr({
                    class: `${Classes.CALL_OUT}-path`,
                    d: path
                })
                .style(styles ? styles : this._styles.draw.callOut.active);

            const closeX = x2 + w / 2 - 20;
            const closeY = y2 - h / 2 + 5;
            dom.select(`.${Classes.CLOSE}`).attr({
                x: closeX,
                y: closeY
            });
        };
        if (isRedraw) {
            if (!this._lock) {
                closeDom.style({
                    display: ''
                });
            }
            this.__appendText({
                dom: drawObj.dom,
                text: drawObj.text,
                start: {
                    x: x2 - callOutWidth / 2 + radius,
                    y: y2 - callOutHeight / 2 + radius
                },
                style: {
                    fill: '#fff'
                },
                adjustClose: false
            });
        } else if (drawObj.edit) {
            const { left, top } = this.__getDataPointPosition(x2, y2, dom);
            const textContainer = this.__appendTextContainer({
                classes: `${Classes.DRAWING_CALL_OUT}-container ${Classes.DRAWING_CALL_OUT}-container-${drawObj.id}`,
                position: [left - callOutWidth / 2, top - callOutHeight / 2],
                height: callOutHeight,
                width: textareaWidth,
                backgroundColor: 'transparent'
            });

            const textarea = textContainer.querySelector('textarea');
            const checkButton = textContainer.querySelector(
                `.${Classes.DRAWING}-check-button`
            );
            const closeButton = textContainer.querySelector(
                `.${Classes.DRAWING}-close-button`
            );
            const adjustSize = text => {
                ({
                    callOutWidth,
                    callOutHeight,
                    textareaWidth
                } = this.__getCalloutSize({
                    dom,
                    text
                }));
                ({ x2, y2 } = this.__adjustCallOutEndPoint({
                    x2,
                    y2,
                    callOutWidth,
                    callOutHeight
                }));
                const { left, top } = this.__getDataPointPosition(x2, y2, dom);
                textarea.style.width = `${textareaWidth}px`;
                textContainer.style.height = `${callOutHeight}px`;
                textContainer.style.left = `${left -
                    callOutWidth / 2 +
                    radius}px`;
                textContainer.style.top = `${top - callOutHeight / 2}px`;

                dom.select(`.${Classes.CALL_OUT}-path`).remove();
                drawPath({
                    w: callOutWidth,
                    h: callOutHeight
                });
            };
            const text = drawObj.editText || drawObj.text || '';
            if (text) {
                adjustSize(text);
                drewPath = true;
            }
            if (drawObj.edit) {
                closeDom.style({
                    display: 'none'
                });
                textarea.value = text;
            }
            textarea.addEventListener('input', e => {
                const target = e.target;
                drawObj.editText = target.value;
                adjustSize(target.value);
            });
            const drawEnd = isCheck => {
                if (isCheck) {
                    checkButton.style.display = 'none';
                    drawObj.text = textarea.value;
                } else {
                    adjustSize(drawObj.text);
                }
                if (!this._lock) {
                    closeDom.style({
                        display: ''
                    });
                }
                this.__appendText({
                    dom: drawObj.dom,
                    text: drawObj.text,
                    start: {
                        x: x2 - callOutWidth / 2 + radius,
                        y: y2 - callOutHeight / 2 + radius
                    },
                    style: {
                        fill: '#fff'
                    },
                    adjustClose: false
                });
                textContainer.remove();
                delete drawObj.secondClick;
                delete drawObj.edit;
                this.end();
            };
            checkButton.addEventListener('click', () => {
                if (!utils.trim(textarea.value)) {
                    return;
                }
                drawEnd(true);
            });
            closeButton.addEventListener('click', e => {
                const idx = utils.findIndex(
                    this.drawings,
                    d => d.id === drawObj.id
                );
                if (!this.drawings[idx].text) {
                    this.drawings.splice(idx, 1);
                    textContainer.remove();
                    dom.remove();
                    this.end();
                } else {
                    drawEnd(false);
                }
                drawObj.editText = '';
            });
        }
        let styles = this._styles.draw.callOut.active;
        if (redraw) {
            styles = this._styles.draw.callOut.default;
        }
        if (!drewPath) {
            drawPath({ w: callOutWidth, h: callOutHeight, styles });
        }
        dom.selectAll('.drawing-point--start').attr({
            cx: drawObj.start.x,
            cy: drawObj.start.y
        });
    }

    /* eslint complexity: 0 */
    __getCalloutPath({ w, h, x1, x2, y1, y2, r, arrW = 8 }) {
        let path = '';
        if (
            x1 <= x2 + w / 2 &&
            x1 >= x2 - w / 2 &&
            y1 >= y2 - h / 2 &&
            y1 <= y2 + h / 2
        ) {
            // inside;
            path = `M${x2 - w / 2} ${y2} v${h / 2 -
                r} a${r} ${r} 0 0 1 ${r} ${r} h${w -
                2 * r} a${r} ${r} 0 0 1 ${r} -${r} v-${h -
                2 * r} a${r} ${r} 0 0 1 ${-r} -${r} h-${w -
                2 * r} a${r} ${r} 0 0 1 ${-r} ${r} v${h / 2 - r}`;
        } else if (x1 < x2 - w / 2 && y1 > y2 + h / 2) {
            // right-top
            path = `M${x1} ${y1} L${x2 - w / 2} ${y2 + h / 2 - arrW} v-${h -
                r -
                arrW} a${r} ${r} 0 0 1 ${r} -${r} h${w -
                2 * r} a${r} ${r} 0 0 1 ${r} ${r} v${h -
                2 * r} a${r} ${r} 0 0 1 -${r} ${r} h-${w - r - arrW} Z`;
        } else if (x1 >= x2 - w / 2 && x1 <= x2 + w / 2 && y1 > y2) {
            // top-center
            arrW = Math.min(14, w / 10);
            arrW = Math.max(8, w / 10);
            path = `M${x1} ${y1} L${x2 - arrW / 2} ${y2 + h / 2} h${-(
                w / 2 -
                r -
                arrW / 2
            )} a${r} ${r} 0 0 1 -${r} -${r} v -${h -
                2 * r}  a${r} ${r} 0 0 1 ${r} -${r} h ${w -
                2 * r} a${r} ${r} 0 0 1 ${r} ${r} v ${h -
                2 * r} a${r} ${r} 0 0 1 ${-r} ${r} h ${-(
                w / 2 -
                r -
                arrW / 2
            )} Z`;
        } else if (x1 > x2 + w / 2 && y1 > y2) {
            // top-left
            path = `M${x1} ${y1} L${x2 + w / 2 - arrW} ${y2 + h / 2} h-${w -
                r -
                arrW} a${r} ${r} 0 0 1 ${-r} ${-r} v-${h -
                2 * r} a${r} ${r} 0 0 1 ${r} ${-r} h${w -
                2 * r} a${r} ${r} 0 0 1 ${r} ${r} v${h - r - arrW} Z`;
        } else if (x1 > x2 + w / 2 && y1 > y2 - h / 2) {
            // left-center
            arrW = Math.min(20, h / 10);
            arrW = Math.max(8, h / 10);
            path = `M${x1} ${y1} L${x2 + w / 2} ${y2 - arrW / 2} v -${h / 2 -
                r -
                arrW / 2}  a${r} ${r} 0 0 0 ${-r} ${-r} h-${w -
                2 * r} a${r} ${r} 0 0 0 ${-r} ${r} v${h -
                2 * r}  a${r} ${r} 0 0 0 ${r} ${r} h${w -
                2 * r}  a${r} ${r} 0 0 0 ${r} ${-r} v-${h / 2 -
                r -
                arrW / 2} Z`;
        } else if (x1 > x2 + w / 2 && y1 <= y2 - h / 2) {
            // left-bottom
            path = `M${x1} ${y1} L${x2 + w / 2 - arrW} ${y2 - h / 2} h-${w -
                r -
                arrW} a${r} ${r} 0 0 0 ${-r} ${r} v${h -
                2 * r} a${r} ${r} 0 0 0 ${r} ${r} h${w -
                2 * r} a${r} ${r} 0 0 0 ${r} ${-r} v-${h - r - arrW} Z`;
        } else if (x1 <= x2 + w / 2 && x1 >= x2 - w / 2 && y1 <= y2 - h / 2) {
            // bottom-center
            arrW = Math.min(20, w / 10);
            arrW = Math.max(8, w / 10);
            path = `M${x1} ${y1} L${x2 - arrW / 2} ${y2 - h / 2} h-${w / 2 -
                arrW / 2 -
                r} a${r} ${r} 0 0 0 ${-r} ${r} v ${h -
                2 * r} a${r} ${r} 0 0 0 ${r} ${r} h${w -
                2 * r} a${r} ${r} 0 0 0 ${r} ${-r} v-${h -
                2 * r} a${r} ${r} 0 0 0 ${-r} ${-r} h-${w / 2 -
                r -
                arrW / 2} Z`;
        } else if (x1 < x2 - w / 2 && y1 <= y2 - h / 2) {
            // right-bottom
            path = `M${x1} ${y1} L${x2 - w / 2} ${y2 - h / 2 + arrW} v${h -
                arrW -
                r} a${r} ${r} 0 0 0 ${r} ${r} h${w -
                2 * r}  a${r} ${r} 0 0 0 ${r} ${-r} v-${h -
                2 * r}  a${r} ${r} 0 0 0 ${-r} ${-r}  h-${w - r - arrW} Z`;
        } else {
            // right-center
            arrW = Math.min(20, h / 10);
            arrW = Math.max(8, h / 10);
            path = `M${x1} ${y1} L${x2 - w / 2} ${y2 - arrW / 2} v-${h / 2 -
                arrW / 2 -
                r}  a${r} ${r} 0 0 1 ${r} ${-r} h${w -
                2 * r} a${r} ${r} 0 0 1 ${r} ${r} v${h -
                2 * r} a${r} ${r} 0 0 1 ${-r} ${r} h-${w -
                2 * r}  a${r} ${r} 0 0 1 ${-r} ${-r} v-${h / 2 -
                r -
                arrW / 2} Z`;
        }
        return path;
    }
    /* eslint max-statements: 0 */
    /* eslint complexity: 0 */
    __move({ position, dataPoint }) {
        const target = utils.find(
            this.drawings,
            item => item.id === this._movingDrawId
        );
        const x = this._xScale(dataPoint.index);
        const y = position[1];
        const yScale = this._scales.scale(dataPoint.yAxis);
        const maxX = this._xScale.range()[1];
        const minX = this._xScale.range()[0];
        const maxY = yScale.range()[0];
        const minY = yScale.range()[1];
        let newStartX, newStartY, newEndX, newEndY;
        if (!target.movingInfo) {
            this._isMoving = false;
            this._movingDrawId = null;
            return;
        }
        const selectedType = target.movingInfo.selectedType;
        if (selectedType === 'start') {
            if (target.drawType === DRAWINGS.ELLIPSE) {
                newStartX = target.movingInfo.start.x;
                newStartY = y;
            } else {
                newStartX = x;
                newStartY = y;
            }
        } else if (selectedType === 'end') {
            if (target.drawType === DRAWINGS.ELLIPSE) {
                newEndX = target.movingInfo.end.x;
                newEndY = y;
            } else {
                newEndX = x;
                newEndY = y;
            }
        } else if (selectedType === 'left') {
            if (target.drawType === DRAWINGS.ELLIPSE) {
                newStartX = x;
                newStartY = target.movingInfo.start.y;
            } else {
                newStartX = x;
                newStartY = target.movingInfo.start.y;
                newEndX = target.movingInfo.end.x;
                newEndY = y;
            }
        } else if (selectedType === 'right') {
            if (target.drawType === DRAWINGS.ELLIPSE) {
                newEndX = x;
                newEndY = target.movingInfo.end.y;
            } else {
                newStartX = target.movingInfo.start.x;
                newStartY = y;
                newEndX = x;
                newEndY = target.movingInfo.end.y;
            }
        } else {
            const preSelected = target.movingInfo.beforeStatus;
            const moveX = x - preSelected.x;
            const moveY = y - preSelected.y;

            newStartX =
                target.drawType !== DRAWINGS.LEVEL_LINE
                    ? preSelected.start.x + moveX
                    : preSelected.start.x;
            newStartY = preSelected.start.y + moveY;
            newEndX =
                target.drawType !== DRAWINGS.LEVEL_LINE
                    ? preSelected.end.x + moveX
                    : preSelected.end.x;
            newEndY = preSelected.end.y + moveY;

            if (newStartX < minX) {
                newEndX += minX - newStartX;
                newStartX = minX;
            } else if (newStartX > maxX) {
                newEndX -= newStartX - maxX;
                newStartX = maxX;
            }
            if (newStartY < minY) {
                newEndY += minY - newStartY;
                newStartY = minY;
            } else if (newStartY > maxY) {
                newEndY -= newStartY - maxY;
                newStartY = maxY;
            }

            if (newEndX > maxX) {
                newStartX -= newEndX - maxX;
                newEndX = maxX;
            } else if (newEndX < minX) {
                newStartX += minX - newEndX;
                newEndX = minX;
            }
            if (newEndY > maxY) {
                newStartY -= newEndY - maxY;
                newEndY = maxY;
            } else if (newEndY < minY) {
                newStartY += minY - newEndY;
                newEndY = minY;
            }
        }

        const newStartDataPoints = this._findNearestPoints([
            newStartX,
            newStartY
        ]);
        let moved = false;

        if (newStartDataPoints.length) {
            target.start.x = this._xScale(newStartDataPoints[0].index);
            target.start.y = newStartY;
            target.start.date = newStartDataPoints[0].date;
            target.start.index = newStartDataPoints[0].index;
            target.start.yValue = yScale.invert(newStartY);
            moved = true;
        }
        let newEndDataPoint = [];
        if (target.movingInfo.selectedType === 'body' && moved) {
            let oldSpan =
                target.movingInfo.beforeStatus.end.index -
                target.movingInfo.beforeStatus.start.index;

            newEndDataPoint = [
                this.__findDataPointByDateOrIndex(target.start.index + oldSpan)
            ];
        } else {
            newEndDataPoint = this._findNearestPoints([newEndX, newEndY]);
        }

        if (newEndDataPoint.length) {
            target.end.x = this._xScale(newEndDataPoint[0].index);
            target.end.y = newEndY;
            target.end.date = newEndDataPoint[0].date;
            target.end.index = newEndDataPoint[0].index;
            target.end.yValue = yScale.invert(newEndY);
            moved = true;
        }

        this.__handleCursor({
            drawType: target.drawType,
            start: target.start,
            end: target.end,
            selectedType: target.movingInfo.selectedType
        });
        this.__drawing(target, true);
    }

    __handleCursor({ drawType, start, end, selectedType }) {
        let cursorType = 'default';
        if (selectedType === 'body') {
            cursorType = 'move';
        } else if (drawType === DRAWINGS.RECTANGLE) {
            const k =
                end.x - start.x ? -(end.y - start.y) / (end.x - start.x) : 0;
            if (selectedType === 'start' || selectedType === 'end') {
                cursorType = k < 0 ? 'se-resize' : 'sw-resize';
            } else if (selectedType === 'left' || selectedType === 'right') {
                cursorType = k < 0 ? 'sw-resize' : 'se-resize';
            }
        } else if (drawType === DRAWINGS.ELLIPSE) {
            if (selectedType === 'start' || selectedType === 'end') {
                cursorType = 'ns-resize';
            } else if (selectedType === 'left' || selectedType === 'right') {
                cursorType = 'ew-resize';
            }
        }
        this.get('el').style({
            cursor: cursorType
        });
    }

    __isHover(position, drawing) {
        let isHover = false;
        switch (drawing.drawType) {
            case DRAWINGS.TREND_LINE:
                isHover = this.__mouseOverLine(position, drawing);
                break;
            case DRAWINGS.LEVEL_LINE:
                isHover = Math.abs(drawing.start.y - position[1]) <= 3;
                break;
            case DRAWINGS.RECTANGLE:
                isHover = this.__mouseOverRect(position, drawing);
                break;
            case DRAWINGS.ELLIPSE:
                isHover = this.__mouseOverEllipse(position, drawing);
                break;
            case DRAWINGS.FIB_TIMEZONE:
                isHover = this.__mouseOverTimezoneLines(position, drawing);
                break;
            case DRAWINGS.FIB_LEVEL:
                isHover = this.__mouseOverFibLevel(position, drawing);
                break;
            case DRAWINGS.FIB_FANS:
                isHover = this.__mouseOverFibFans(position, drawing);
                break;
            case DRAWINGS.FIB_ARCS:
                isHover = this.__mouseOverFibArcs(position, drawing);
                break;
            case DRAWINGS.TEXT:
                isHover = this.__mouseOverText(position, drawing);
                break;
            case DRAWINGS.CALL_OUT:
                isHover = this.__mouseOverCallOut(position, drawing);
                break;
        }
        return isHover;
    }

    __checkHoverOnDrawings({ position, triggeredByMousedown }) {
        let selectedType;
        for (let i = 0; i < this.drawings.length; i++) {
            const drawing = this.drawings[i];
            if (!drawing.start || !drawing.end) {
                continue;
            }
            let isHover = this.__isHover(position, drawing);
            if (drawing.drawType === DRAWINGS.TEXT) {
                if (isHover && !this._isDrawing) {
                    drawing.dom.select('rect').style({
                        visibility: 'visible'
                    });
                } else {
                    drawing.dom.select('rect').style({
                        visibility: 'hidden'
                    });
                }
            }
            const selectedDataPoint = this.__findSelectedDataPoint(
                position,
                drawing
            );
            const maskEl = drawing.dom.selectAll(`.${Classes.DRAWING_MASK}`);

            if (selectedDataPoint) {
                selectedType = selectedDataPoint;
            } else if (isHover) {
                selectedType = 'body';
            }
            if (selectedDataPoint) {
                this.__handleCursor({
                    selectedType,
                    start: drawing.start,
                    end: drawing.end,
                    drawType: drawing.drawType
                });
            } else {
                this.get('el').style({
                    cursor: 'default'
                });
            }
            if (drawing.drawType === DRAWINGS.CALL_OUT) {
                if (selectedType && !this._isDrawing && !drawing.edit) {
                    drawing.dom
                        .select(`.${Classes.CALL_OUT}-path`)
                        .style(this._styles.draw.callOut.hover);
                } else if (!this._isDrawing && !drawing.edit) {
                    drawing.dom
                        .select(`.${Classes.CALL_OUT}-path`)
                        .style(this._styles.draw.callOut.default);
                }
            }

            if (selectedType) {
                drawing.dom.selectAll('.drawing-point').style({ display: '' });
                drawing.dom.classed(Classes.DRAWING_HIGHLIGHT, true);
                drawing.dom
                    .selectAll(`.${Classes.DRAWING_TAG}`)
                    .attr('transform', '');
                if (drawing.drawType !== DRAWINGS.LEVEL_LINE) {
                    maskEl.attr('mask', `url(#mask-${drawing.id})`);
                }
                if (triggeredByMousedown) {
                    if (this.__checkOnClose(position)) {
                        return;
                    }
                    this._isMoving = true;
                    this._movingDrawId = drawing.id;
                    drawing.movingInfo = {
                        selectedType,
                        beforeStatus: {
                            start: utils.extend(true, {}, drawing.start),
                            end: utils.extend(true, {}, drawing.end),
                            x: position[0],
                            y: position[1]
                        },
                        startPosition: position,
                        start: utils.extend(true, {}, drawing.start),
                        end: utils.extend(true, {}, drawing.end)
                    };
                }
                break;
            } else {
                drawing.dom.selectAll('.drawing-point').style({
                    display: 'none'
                });
            }
        }
    }
    mousemove({ position, dataPoint, triggeredByMousedown = false }) {
        if (this._isDrawing && this._drawingType) {
            let skipMouseMove = false;
            if (this._drawingType === DRAWINGS.CALL_OUT) {
                const target = utils.find(
                    this.drawings,
                    d => d.id === this._drawId
                );
                if (target.edit) {
                    skipMouseMove = true;
                }
            } else if (this._drawingType === DRAWINGS.TEXT) {
                skipMouseMove = true;
            }
            if (!skipMouseMove) {
                this.drawing({
                    dataPoint,
                    position,
                    drawType: this._drawingType
                });
            }
            return;
        }
        if (this.get('lock')) {
            return;
        }
        if (this._isMoving && !triggeredByMousedown) {
            this.__move({ position, dataPoint });
            return;
        }
        this.__hideStartEndPoints();
        this.__checkHoverOnDrawings({ position, triggeredByMousedown });
    }

    __findSelectedDataPoint(position, drawing) {
        let checkDataPoints = [];
        if (drawing.drawType === DRAWINGS.ELLIPSE) {
            const rx = Math.abs(drawing.end.x - drawing.start.x) / 2;
            const ry = Math.abs(drawing.end.y - drawing.start.y) / 2;
            const minX = Math.min(drawing.start.x, drawing.end.x);
            const minY = Math.min(drawing.start.y, drawing.end.y);
            checkDataPoints = [
                {
                    dataPoint: 'start',
                    selector: '.drawing-point.drawing-point--start',
                    x: minX + rx,
                    y: minY
                },
                {
                    dataPoint: 'end',
                    selector: '.drawing-point.drawing-point--end',
                    x: minX + rx,
                    y: minY + 2 * ry
                },
                {
                    dataPoint: 'left',
                    selector: '.drawing-point--resize-left',
                    x: minX,
                    y: minY + ry
                },
                {
                    dataPoint: 'right',
                    selector: '.drawing-point--resize-right',
                    x: minX + 2 * rx,
                    y: minY + ry
                }
            ];
        } else {
            checkDataPoints = [
                {
                    dataPoint: 'start',
                    selector: '.drawing-point.drawing-point--start',
                    x: drawing.start.x,
                    y: drawing.start.y
                },
                {
                    dataPoint: 'end',
                    selector: '.drawing-point.drawing-point--end',
                    x: drawing.end.x,
                    y: drawing.end.y
                }
            ];

            if (drawing.drawType === DRAWINGS.RECTANGLE) {
                checkDataPoints = [
                    ...checkDataPoints,
                    {
                        dataPoint: 'left',
                        selector: '.drawing-point--resize-left',
                        x: drawing.start.x,
                        y: drawing.end.y
                    },
                    {
                        dataPoint: 'right',
                        selector: '.drawing-point--resize-right',
                        x: drawing.end.x,
                        y: drawing.start.y
                    }
                ];
            }
        }

        for (let i = 0; i < checkDataPoints.length; i++) {
            const checkObj = checkDataPoints[i];
            const mouseOver = this.__pointInsideCircle(position, {
                cx: checkObj.x,
                cy: checkObj.y,
                r: 7
            });
            const dataPointEl = drawing.dom.select(checkObj.selector);
            if (mouseOver) {
                dataPointEl.classed(Classes.CIRCLE_HIGHLIGHT, true);
            } else {
                dataPointEl.classed(Classes.CIRCLE_HIGHLIGHT, false);
            }
            if (mouseOver) {
                return checkObj.dataPoint;
            }
        }
    }

    __createStartEnd({ x, y, dom, drawType }) {
        dom.append('circle')
            .attr({
                class: 'drawing-point drawing-point--start',
                cx: x,
                cy: y,
                r: 7
            })
            .style(this._styles.draw);
        if (drawType !== DRAWINGS.CALL_OUT) {
            dom.append('circle')
                .attr({
                    class: 'drawing-point drawing-point--end',
                    cx: x,
                    cy: y,
                    r: 7
                })
                .style(this._styles.draw);
        }

        if (drawType === DRAWINGS.RECTANGLE || drawType === DRAWINGS.ELLIPSE) {
            dom.append('circle')
                .attr({
                    class: 'drawing-point drawing-point--resize-left',
                    cx: x,
                    cy: y,
                    r: 7
                })
                .style(this._styles.draw);
            dom.append('circle')
                .attr({
                    class: 'drawing-point drawing-point--resize-right',
                    cx: x,
                    cy: y,
                    r: 7
                })
                .style(this._styles.draw);
        }
        const mask = dom
            .append('defs')
            .append('mask')
            .attr({
                id: `mask-${this._drawId}`
            });
        mask.append('rect').attr({
            x: this._xScale.range()[0],
            width: this._width,
            height: this._height,
            fill: 'white'
        });
        mask.append('circle').attr({
            class: 'drawing-point--start',
            cx: x,
            cy: y,
            r: 7,
            fill: 'black'
        });
        if (drawType !== DRAWINGS.CALL_OUT) {
            mask.append('circle').attr({
                class: 'drawing-point--end',
                cx: x,
                cy: y,
                r: 7,
                fill: 'black'
            });
        }

        if (drawType === DRAWINGS.RECTANGLE || drawType === DRAWINGS.ELLIPSE) {
            mask.append('circle').attr({
                class: 'drawing-point--resize-left',
                cx: x,
                cy: y,
                r: 7,
                fill: 'black'
            });

            mask.append('circle').attr({
                class: 'drawing-point--resize-right',
                cx: x,
                cy: y,
                r: 7,
                fill: 'black'
            });
        }
    }

    __mouseOverLine(position, { start, end }) {
        const [x, y] = position;
        const minX = Math.min(start.x, end.x);
        const maxX = Math.max(start.x, end.x);
        const minY = Math.min(start.y, end.y);
        const maxY = Math.max(start.y, end.y);
        if (
            !(
                Math.round(position[0]) > minX - 2 &&
                Math.round(position[0]) < maxX + 2 &&
                Math.round(position[1]) > minY - 2 &&
                Math.round(position[1]) < maxY + 2
            )
        ) {
            return false;
        }
        const d = this.__pointToSegDist(x, y, start.x, start.y, end.x, end.y);
        if (d <= 5) {
            return true;
        }
        return false;
    }

    __mouseOverRect([x, y], drawings) {
        const minX = Math.min(drawings.start.x, drawings.end.x);
        const maxX = Math.max(drawings.start.x, drawings.end.x);
        const minY = Math.min(drawings.start.y, drawings.end.y);
        const maxY = Math.max(drawings.start.y, drawings.end.y);
        if (x >= minX - 2 && x <= maxX + 2 && y >= minY - 2 && y <= maxY + 2) {
            return true;
        }
        return false;
    }

    __mouseOverText([x, y], drawings) {
        const { width, height } = this.__getTextSize({
            dom: drawings.dom,
            text: drawings.text,
            minWidth: 0,
            extraWidth: 25
        });
        const start = this.__adjustTextPosition({
            x: drawings.start.x,
            y: drawings.start.y,
            width,
            height
        });
        const minX = start.x;
        const maxX = start.x + width;
        const minY = start.y;
        const maxY = start.y + height;
        if (x >= minX - 2 && x <= maxX + 2 && y >= minY - 2 && y <= maxY + 2) {
            return true;
        }
        return false;
    }
    __mouseOverCallOut([x, y], drawings) {
        const { callOutWidth, callOutHeight } = this.__getCalloutSize(drawings);
        const minX = drawings.end.x - callOutWidth / 2;
        const maxX = drawings.end.x + callOutWidth / 2;
        const minY = drawings.end.y - callOutHeight / 2;
        const maxY = drawings.end.y + callOutHeight / 2;
        if (x >= minX - 2 && x <= maxX + 2 && y >= minY - 2 && y <= maxY + 2) {
            return true;
        }
        return false;
    }

    __mouseOverEllipse([x, y], drawObj) {
        const rx = Math.abs(drawObj.end.x - drawObj.start.x) / 2;
        const ry = Math.abs(drawObj.end.y - drawObj.start.y) / 2;
        const minX = Math.min(drawObj.start.x, drawObj.end.x);
        const minY = Math.min(drawObj.start.y, drawObj.end.y);
        const h = minX + rx;
        const k = minY + ry;
        const d =
            Math.pow(x - h, 2) / Math.pow(rx, 2) +
            Math.pow(y - k, 2) / Math.pow(ry, 2);

        if (d <= 1) {
            return true;
        }
        return false;
    }

    __mouseOverTimezoneLines([x, y], drawObj) {
        const width = this._xScale.range()[1] - this._xScale.range()[0];
        let timeZoneLines = this.__getFibonacciTimeZoneX(
            drawObj.start.x,
            drawObj.end.x,
            width
        );
        for (let i = 0; i < timeZoneLines.length; i++) {
            const line = timeZoneLines[i];
            if (Math.abs(line.x - x) <= 3) {
                return true;
            }
        }
        return this.__mouseOverLine([x, y], drawObj);
    }

    __mouseOverFibLevel([x, y], drawObj) {
        const height = Math.abs(drawObj.end.y - drawObj.start.y);
        const x1 = Math.min(drawObj.start.x, drawObj.end.x);
        const x2 = Math.max(drawObj.start.x, drawObj.end.x);
        const y1 = Math.min(drawObj.start.y, drawObj.end.y);
        for (let i = 0; i < FIB.length; i++) {
            const number = FIB[i];
            const lineY = y1 + number * height;
            if (x >= x1 && x <= x2 && Math.abs(lineY - y) <= 3) {
                return true;
            }
        }
        return this.__mouseOverLine([x, y], drawObj);
    }
    __mouseOverFibFans([x, y], drawObj) {
        const lines = this.__getFibFansLines(drawObj);
        for (let i = 0; i < lines.length; i++) {
            const mouseOver = this.__mouseOverLine([x, y], {
                start: drawObj.start,
                end: lines[i]
            });
            if (mouseOver) {
                return true;
            }
        }
        return false;
    }

    __mouseOverFibArcs([x, y], drawObj) {
        const x1 = drawObj.start.x,
            y1 = drawObj.start.y,
            y2 = drawObj.end.y,
            x2 = drawObj.end.x;
        const upDown = y2 >= y1 ? 'up' : 'down';
        const radius = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
        for (let i = 0; i < FIB.length; i++) {
            const number = FIB[i];
            if (number === 0) {
                continue;
            }
            const r = radius * number;
            const distance = Math.sqrt(
                Math.pow(x - x2, 2) + Math.pow(y - y2, 2)
            );
            let mouseOver = false;
            if (upDown === 'up') {
                mouseOver = y <= y2 && Math.abs(distance - r) < 3;
            } else {
                mouseOver = y >= y2 && Math.abs(distance - r) < 3;
            }
            if (mouseOver) {
                return true;
            }
        }
        return this.__mouseOverLine([x, y], drawObj);
    }

    __pointInsideCircle(position, { cx, cy, r }) {
        if (r === 0) {
            return false;
        }
        r = r + 2;
        var dx = cx - position[0];
        var dy = cy - position[1];
        return dx * dx + dy * dy <= r * r;
    }
    __pointToSegDist(x, y, x1, y1, x2, y2) {
        let cross = (x2 - x1) * (x - x1) + (y2 - y1) * (y - y1);
        if (cross <= 0) {
            return Math.sqrt((x - x1) * (x - x1) + (y - y1) * (y - y1));
        }

        let d2 = (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
        if (cross >= d2) {
            return Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2));
        }

        let r = cross / d2;
        let px = x1 + (x2 - x1) * r;
        let py = y1 + (y2 - y1) * r;
        return Math.sqrt((x - px) * (x - px) + (py - y) * (py - y));
    }

    __getFibonacciTimeZoneX(startX, endX, width) {
        const scan = endX - startX;
        const diff = Math.abs(scan);
        const xs = [
            {
                n: 0,
                x: startX
            },
            {
                n: 1,
                x: endX
            }
        ];
        if (diff > 0) {
            let first = 0,
                second = 1,
                next = 0,
                sum = 0;
            while (true) {
                if (scan > 0) {
                    //draw right
                    next = first + second;
                    sum += next;
                    if (endX + sum * diff > width) {
                        break;
                    }
                    first = second;
                    second = next;
                    xs.push({
                        n: next,
                        x: endX + sum * diff
                    });
                } else {
                    next = first + second;
                    sum += next;
                    if (endX - sum * diff < 0) {
                        break;
                    }
                    first = second;
                    second = next;
                    xs.push({
                        n: next,
                        x: endX - sum * diff
                    });
                }
            }
        }
        return xs;
    }
}
