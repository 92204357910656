import d3 from 'd3';
import { Classes } from '../constants';
import { getHalfTickSizeAndXPoint } from '../common';
export default function drawCandlestick({
    selection,
    xScale,
    xValue,
    yValue,
    yOValue,
    yHValue,
    yLValue,
    yCValue,
    yScale,
    yPValue,
    data,
    className = '',
    tickSize = 4,
    styles
} = {}) {
    const candlestickPath = data => {
        const { halfTickSize, xPoint, fullTickSize } = getHalfTickSizeAndXPoint(
            tickSize,
            xScale,
            xValue,
            data
        );

        const x = xPoint - halfTickSize;
        const open = yScale(yOValue(data));
        const close = yScale(yCValue(data));

        const path = [];

        // top
        path.push(
            'M',
            xPoint,
            yScale(yHValue(data)),
            'L',
            xPoint,
            Math.min(open, close)
        );

        // body rect
        path.push('M', x, open, 'l', fullTickSize, 0);

        if (open !== close) {
            path.push(
                'L',
                x + fullTickSize,
                close,
                'l',
                -fullTickSize,
                0,
                'L',
                x,
                open
            );
        } else {
            path.push('M', x, open, 'l', fullTickSize, 0);
        }

        // bottom
        path.push(
            'M',
            xPoint,
            Math.max(open, close),
            'L',
            xPoint,
            yScale(yLValue(data))
        );

        return path.join(' ');
    };

    const candlestick = data => {
        return data.map(d => candlestickPath(d)).join(' ');
    };
    const target = selection
        .append('g')
        .attr('class', `${Classes.GRAPH_CANDLESTICK} ${className}`);
    const groupData = d3
        .nest()
        .key(function(d) {
            const close = yCValue(d);
            const open = yOValue(d);
            const previousClose = yPValue(d);
            let str;
            if (!close || !open || !previousClose) {
                return 'invalid';
            } else if (d.isPullData && d.status === 'Pre-Open') {
                return 'dot';
            } else {
                //color
                if (close >= previousClose) {
                    str = 'positive';
                } else {
                    str = 'negative';
                }
                // hollow fill
                if (close >= open) {
                    str += '-hollow';
                } else {
                    str += '-fill';
                }
                return str;
            }
        })
        .entries(data);
    groupData.forEach(g => {
        if (g.key === 'invalid') {
            return;
        } else if (g.key === 'dot') {
            const gValue = g.values[0];
            target
                .append('circle')
                .attr('r', 4)
                .attr('cx', xScale(xValue(gValue)))
                .attr('cy', yScale(yValue(gValue)))
                .style(styles);
        } else {
            target
                .append('g')
                .attr('class', `${Classes.GRAPH_CANDLESTICK}__${g.key}`)
                .append('path')
                .attr('d', candlestick(g.values))
                .style(styles[g.key]);
        }
    });

    return target;
}
