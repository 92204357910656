<template>
    <TREASURYUI
        ref="UI"
        :showLoading="showLoading"
        :skin="skin"
        :dataModel="dataModel"
        :errorCode="error.code"
        :settings="settings"
        :labels="mergeLabels(defaultConfig.labels)"
        :formatter="formatter"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
    >
    </TREASURYUI>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import TREASURYUI from './treasury-ui';
import { DATAPOINTS, PERIODS } from './metadata/datapoint';
import { VIEW_TYPES } from './metadata/view-types';
import labels from './assets/labels.json';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-treasury',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: {
        TREASURYUI
    },
    methods: {
        initialize() {
            this.initialized = true;
            this.loadTreasuryBond();
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility && !this.initialized) {
                this.initialize();
            }
        },
        /***
         * load the treasury bond data from servies
         */
        loadTreasuryBond() {
            const param = {
                timePeriods: DATAPOINTS.filter(item => item.param).map(
                    item => item.param
                )
            };
            return this.mktdata
                .treasuryBond(param)
                .then(securities => {
                    if (securities.length > 0) {
                        this._fillDataModel(securities);
                        this.renderCompleted = true;
                    } else {
                        this._setError(this.ERROR_TYPES.NODATA);
                    }
                })
                .catch(e => {
                    this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                    this.showLoading = false;
                });
        },
        /**
         * fill the treasury bond data to the data model
         */
        _fillDataModel(securities = []) {
            let asOfDate = '';
            securities.forEach(element => {
                const periods = utils.find(
                    DATAPOINTS,
                    p => p.param === element.name
                );
                if (periods) {
                    const key = periods.id;
                    Object.keys(element.value).forEach(property => {
                        const value = element.value[property];
                        if (property === 'asOfDate') {
                            if (asOfDate < value) {
                                asOfDate = value;
                            }
                        }
                        let dataItem = utils.find(
                            this.dataModel.data,
                            item => item.id === property
                        );
                        if (!dataItem) {
                            dataItem = {
                                id: property
                            };
                            this.dataModel.data.push(dataItem);
                        }
                        this.$set(dataItem, key, value);
                    });
                }
            });

            this.dataModel.asOfDate = asOfDate;
        },
        _setError(err) {
            this.error = err;
            this.dataModel = {
                asOfDate: '',
                data: []
            };
            this.showLoading = false;
        }
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    showLoading: false,
                    layout: '',
                    showSettingMaturities: false,
                    maturities: PERIODS.join(','),
                    languageId: 'en-US',
                    yields: 'lastYield,yield6MonthAgo,yield1YearAgo',
                    viewType: VIEW_TYPES.CHART,
                    charts: {
                        height: 400 // Must to has chart height when autoHeight is true
                    },
                    frozenColumn: 0,
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 500,
                            maxWidth: 750
                        }
                    ]
                },
                labels: labels,
                format: {
                    date: {
                        dataType: 'date',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    }
                },
                intlNamespace: 'mwc-markets-treasury'
            },
            dataModel: {
                asOfDate: '',
                data: []
            },
            showLoading: false
        };
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
    },
    created() {
        // init
        this.initialized = false;
        this.namespace = utils.namespace('treasury');
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    }
};
</script>
