import { Classes } from '../constants';
export default function drawEvents({
    selection,
    xScale,
    xValue,
    options,
    yScaleHeight,
    data,
    valid,
    className = ''
} = {}) {
    const { radius, margin, text, textStyle } = options;
    let height = yScaleHeight.range()[0];
    height -= radius + margin;
    const target = selection
        .append('g')
        .attr('class', `${Classes.EVENTS} ${className}`);

    data.forEach(d => {
        if (valid(d)) {
            const translateX = xScale(xValue(d));
            const translateY =
                height - d.yIndex * radius * 2 - margin * d.yIndex;
            target
                .append('circle')
                .attr('r', radius)
                .attr('cx', translateX)
                .attr('cy', translateY)
                .style({
                    fill: d.color
                });
            target
                .append('text')
                .attr('x', translateX - 3)
                .attr('y', translateY + 4)
                .text(text)
                .style(textStyle);
        }
    });

    return target;
}
